// @ts-strict
import { FileAttachment, User } from 'models'
import { FileUploadResponse } from 'schema'

export class FileUpload {
  user?: User
  id?: string
  name?: string
  createdAt?: string
  updatedAt?: string
  category?: string
  fileAttachment?: FileAttachment
  access?: string

  constructor(attributes: FileUpload) {
    Object.assign(this, attributes)
  }

  static create(response: Potential<FileUploadResponse>): Potential<FileUpload> {
    if (!response) {
      return
    }
    const fileAttachment =
      response?.fileAttachment && FileAttachment.create(response.fileAttachment)
    const user = User.create(response.user)

    return new FileUpload({ ...response, fileAttachment, user })
  }
}
