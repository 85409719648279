import { getPplReferralsUrl } from 'services/getUrl'
import { patchRequest } from 'utils'

export const updateAgentPayPerLeadReferral = async (
  agentId: string | number,
  method: string,
  notes: string,
  referralId: string | number | undefined,
  providerType?: string
) => {
  const url = getPplReferralsUrl({
    pathParams: {
      provider_type: providerType || 'Agent',
      provider_id: agentId
    },
    id: referralId
  })

  await patchRequest(url, { method, notes })
}
