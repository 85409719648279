// @ts-strict
import { Table, TableResults } from 'components'
import { TableHeaderCell as TH, TableHeaderRow as TR } from 'components'
import { TableCell as Td, TableRow as Tr } from 'components'
import { AgentPayPerLeadSetting } from 'models'
import { capitalize, formatDollarsAndCents } from 'utils'
import { IS_ZIP_CODE } from '../constants'
import styles from './LocationSettings.module.scss'

type LocationSettingsTableProps = {
  locationSettings: AgentPayPerLeadSetting[]
}

type LocationSettingsRowProps = {
  locationSetting: AgentPayPerLeadSetting
}

const slugToCityState = (slug: string) => {
  const parts = slug.split('-')
  const state = parts.pop()?.toUpperCase()
  const city = parts.map(p => p.charAt(0).toUpperCase() + p.slice(1)).join(' ')

  return `${city}, ${state}`
}

export const LocationSettingsTableReadOnly = ({ locationSettings }: LocationSettingsTableProps) => {
  return (
    <Table wrapperClassName={styles.table} data-testid="location-settings-table">
      <TR>
        <TH>Location</TH>
        <TH>User type</TH>
        <TH>Price</TH>
      </TR>
      <TableResults type="Pay-Per-Lead Location Settings" colSpan={2}>
        {locationSettings.map((locationSetting: AgentPayPerLeadSetting, index: number) => (
          <LocationSettingsRow key={index} locationSetting={locationSetting} />
        ))}
      </TableResults>
    </Table>
  )
}

const LocationSettingsRow = ({ locationSetting }: LocationSettingsRowProps) => {
  const locationLabel = IS_ZIP_CODE(locationSetting.location!)
    ? locationSetting.location
    : slugToCityState(locationSetting.location!)

  return (
    <Tr data-testid="location-setting-replace-me">
      <Td>{locationLabel}</Td>
      <Td>{capitalize(locationSetting.userType || '')}</Td>
      <Td>{formatDollarsAndCents(Number(locationSetting.price) / 100)}</Td>
    </Tr>
  )
}
