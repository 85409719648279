const TYPES = {
  departing_property_seller_agent: 'agent'
}

export const getIncludes = (
  columns: { fields: { [key: string]: string | string[] } }[],
  topEntity: string
) => {
  const entities = new Set<string>()

  for (const col of columns) {
    Object.keys(col.fields).forEach(key => entities.add(key))
  }

  entities.delete(topEntity)

  return Array.from(entities)
}

export const getFields = (
  columns: { fields: { [key: string]: string | string[] } }[],
  topEntity: string
) => {
  const fields: { [key: string]: Set<string> } = {
    [topEntity]: new Set<string>(['id'])
  }

  for (const col of columns) {
    Object.entries(col.fields).forEach(([type, field]) => {
      if (type.includes('.')) {
        let [topLevelType, secondaryType] = type.split('.')

        if (topLevelType in TYPES) {
          topLevelType = TYPES[topLevelType]
        }

        if (secondaryType in TYPES) {
          secondaryType = TYPES[secondaryType]
        }

        type = secondaryType

        if (!(topLevelType in fields)) {
          fields[topLevelType] = new Set(['id'])
        }

        fields[topLevelType].add(secondaryType)
      } else {
        fields[topEntity].add(type)
      }

      if (type in TYPES) {
        type = TYPES[type]
      }

      if (!(type in fields)) {
        fields[type] = new Set(['id'])
      }

      if (Array.isArray(field)) {
        field.forEach(value => fields[type].add(value))
      } else {
        fields[type].add(field)
      }
    })
  }

  fields[topEntity].delete(topEntity)

  return Object.entries(fields).reduce((prev, curr) => {
    const [type, fields] = curr

    prev[`fields[${type}]`] = Array.from(fields)

    return prev
  }, {})
}
