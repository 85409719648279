import { Link } from 'react-router-dom'
import { Block } from 'components/Block'
import { TableCell as TD, TableRow as TR } from 'components/Table'
import { useTransactionTeams } from 'lookups'
import { TransactionTeam } from 'models'
import { format } from 'utils'

type TProps = { team?: TransactionTeam }

export const TeamsListRow = ({ team }: TProps) => {
  const { findLeadTeamRole } = useTransactionTeams()

  if (!team) {
    return null
  }

  return (
    <TR>
      <TD>
        <Block
          title={<Link to={`/teams/${team.id}`}>{team.name}</Link>}
          subtitle={team.description}
        />
      </TD>
      <TD>
        {team.teamMemberships &&
          team.teamMemberships
            .sort((a, b) => a.user?.name.localeCompare(b.user?.name))
            .map(member => {
              const role = findLeadTeamRole(member.role)
              return (
                <div key={member.id}>{`${member.user?.name} ${
                  role ? `(${role.name})` : '(deprecated role)'
                }`}</div>
              )
            })}
      </TD>
      <TD>{format.boolean(team.enabled)}</TD>
    </TR>
  )
}
