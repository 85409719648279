// @ts-strict
import { FileInput, FormField, Select, TextInput } from 'components'
import { isCCCashOfferLead, Lead } from 'models'
import { Icon, Switch } from '../../../@foundation/components'
import { useCurrentUserController } from '../../../controllers'
import { useFeatureFlags } from '../../../hooks'
import styles from './LeadFileUploadsButtonFields.module.scss'
import { TLeadFileUploadsPayload } from './LeadFileUploadsButtonForm'

type TLeadFileUploadsButtonFieldsProps = {
  lead?: Lead
  multiple?: boolean
  onChangeAttribute: <K extends keyof TLeadFileUploadsPayload>(
    key: K,
    value: TLeadFileUploadsPayload[K]
  ) => void
  payload: TLeadFileUploadsPayload
}

const acceptedFileTypes = `.doc,.docx,.pdf`

export const LeadFileUploadsButtonFields = ({
  lead,
  payload,
  onChangeAttribute,
  multiple
}: TLeadFileUploadsButtonFieldsProps) => {
  const incomingResidenceOptions = isCCCashOfferLead(lead)
    ? lead.getCashOfferProviderLeadsOptions()
    : []
  const {
    isSuperUser,
    isLenderRelationshipManager,
    isContractAdvisor,
    isProcessor,
    isTransactionSpecialist,
    isListingOperationsSpecialist
  } = useCurrentUserController()

  const { provideDocumentsToLo } = useFeatureFlags(['provide-documents-to-lo'])

  const showShareExternally =
    provideDocumentsToLo.enabled &&
    (isSuperUser ||
      isLenderRelationshipManager ||
      isContractAdvisor ||
      isProcessor ||
      isTransactionSpecialist ||
      isListingOperationsSpecialist)

  const setIncomingResidence = (value: string) => {
    onChangeAttribute('subAttachableId', value)
    onChangeAttribute('subAttachableType', value ? 'CashOfferLead' : undefined)
  }

  return (
    <div className={styles.wrapper}>
      {!multiple && (
        <FormField title="Name" htmlFor="file-name" required>
          <TextInput
            id="file-name"
            value={payload.name}
            onChange={val => onChangeAttribute('name', val)}
            data-testid="filesNameInput"
          />
        </FormField>
      )}
      <FormField title="Document (pdf, doc, docx)" htmlFor="file" className={styles.label} required>
        <FileInput
          id="file-name"
          data-testid="fileUploadInput"
          accept={acceptedFileTypes}
          value={payload.files}
          onChange={value => {
            onChangeAttribute('files', value)
          }}
          multiple={multiple}
        />
      </FormField>
      {showShareExternally && (
        <div className={styles.shareExternallyWrapper}>
          <FormField className={styles.switchFormField}>
            <Switch
              isOn={payload.shareExternally}
              onClick={() => onChangeAttribute('shareExternally', !payload.shareExternally)}
              label="Share externally"
            />
          </FormField>
          <p>
            External users will be notified and able to access the document through their portal.
          </p>
          {payload.shareExternally && (
            <p className={styles.warning}>
              <Icon data-testid="iconLeft" path="Warning" size="lg" />
              For security we won’t save external documents in the Sales App.
            </p>
          )}
        </div>
      )}
      {isCCCashOfferLead(lead) ? (
        <FormField title="Incoming Residence" htmlFor="incoming-residence">
          <Select
            id="incoming-residence"
            addBlank
            options={incomingResidenceOptions}
            value={payload.subAttachableId}
            onChange={setIncomingResidence}
          />
        </FormField>
      ) : null}
    </div>
  )
}
