// @ts-strict

import { Flex, Switch, Text } from '@foundation/components'
import {
  DateInput,
  DollarsInput,
  FileInput,
  FormField,
  Section,
  Sections,
  Select,
  TextArea,
  TextInput
} from 'components'
import { TaskAttachable } from 'controllers'
import { useFeatureFlags } from 'hooks'
import { bbysLeadWarehouseOptions, useTaskCategories } from 'lookups'
import { isCCCashOfferLead, isCCTradeInLead } from 'models'
import { d, presence } from 'utils'
import { UsersAutocomplete } from '../../../../modules/transaction-operators/components/users-autocomplete/users-autocomplete'
import styles from '../TaskForm.module.scss'
import { TTaskFormPayload } from '../utils'
import { TaskDocuments } from './TaskDocuments'
import { TaskRequiredForStage } from './TaskRequiredForStage'

export type TTaskFormFieldsProps = {
  attachable?: TaskAttachable
  isDocumentUploadTask?: boolean
  isIrContractUploadTask?: boolean
  isSendWireConfirmation?: boolean
  onChangeAttribute: <K extends keyof TTaskFormPayload>(key: K, value: TTaskFormPayload[K]) => void
  payload: TTaskFormPayload
  sendNotification?: boolean
  setSendNotification?: React.Dispatch<React.SetStateAction<boolean>>
}

export const TaskFormFields = ({
  onChangeAttribute,
  payload,
  attachable,
  isDocumentUploadTask,
  isIrContractUploadTask,
  isSendWireConfirmation,
  sendNotification,
  setSendNotification
}: TTaskFormFieldsProps) => {
  const { taskCategoryOptions } = useTaskCategories()
  const { wireConfirmationTaskFastFollows, newTaskFunctionality } = useFeatureFlags([
    'wire-confirmation-task-fast-follows',
    'new-task-functionality'
  ])

  const requiredForStage = (isCCCashOfferLead(attachable) || isCCTradeInLead(attachable)) && (
    <Section>
      <TaskRequiredForStage
        attachable={attachable}
        onChangeAttribute={onChangeAttribute}
        payload={payload}
      />
    </Section>
  )

  const showIncomingResidence = isCCCashOfferLead(attachable)

  const clearUserId = () => {
    onChangeAttribute('assigneeId', '')
    onChangeAttribute('assigneeName', '')
  }

  const setUser = (user: any) => {
    if (user) {
      onChangeAttribute('assigneeId', user.id)
      onChangeAttribute('assigneeName', user.title)
    } else {
      clearUserId()
    }
  }

  const attachedTo = attachable && (
    <FormField title="Attached to">
      {attachable.name} ({d(attachable?.modelName)})
    </FormField>
  )

  return (
    <>
      <Sections>
        <Section>
          {attachedTo}
          <FormField title="Title" htmlFor="title-field" required>
            <TextInput
              id="title-field"
              data-testid="title-field"
              onChange={val => onChangeAttribute('title', val)}
              name="title"
              value={payload?.title}
              disabled={isSendWireConfirmation}
              autoFocus
            />
          </FormField>
          {showIncomingResidence && (
            <FormField title="Incoming Residence" htmlFor="incoming-residence">
              <Select
                addBlank
                id="incoming-residence"
                options={attachable.getCashOfferProviderLeadsOptions()}
                onChange={val => onChangeAttribute('subAttachableId', val)}
                value={payload?.subAttachableId}
              />
            </FormField>
          )}
        </Section>
      </Sections>
      {!isSendWireConfirmation && (
        <Sections>
          <Section>
            <FormField title="Category" htmlFor="category">
              <Select
                id="category"
                data-testid="category-field"
                onChange={val => onChangeAttribute('taskCategoryId', val)}
                value={payload?.taskCategoryId}
                options={taskCategoryOptions}
                placeholder="Select a Category"
                addBlank
              />
            </FormField>
          </Section>
          {requiredForStage}
        </Sections>
      )}

      {isDocumentUploadTask && <TaskDocuments />}
      {isSendWireConfirmation ? (
        <>
          {!wireConfirmationTaskFastFollows.enabled && (
            <Section>
              <FormField title="Confirmation Number" htmlFor="confirmation-number" required>
                <TextInput
                  id="confirmation-number-field"
                  data-testid="confirmation-number-field"
                  onChange={val =>
                    onChangeAttribute('metadata', {
                      ...payload?.metadata,
                      confirmationNumber: val
                    })
                  }
                  name="confirmation-number"
                  value={payload?.metadata?.confirmationNumber}
                  placeholder="Enter confirmation number"
                />
              </FormField>
            </Section>
          )}
          {wireConfirmationTaskFastFollows.enabled && (
            <Section>
              <FormField title="Warehouse facility" required>
                <Select
                  value={payload?.metadata?.warehouseFacility}
                  options={bbysLeadWarehouseOptions}
                  onChange={val =>
                    onChangeAttribute('metadata', {
                      ...payload?.metadata,
                      warehouseFacility: val
                    })
                  }
                  addBlank
                />
              </FormField>
            </Section>
          )}
          <Section>
            <FormField title="IMAD Number" htmlFor="imad-number" required>
              <TextInput
                id="imad-number-field"
                data-testid="imad-number-field"
                onChange={val =>
                  onChangeAttribute('metadata', { ...payload?.metadata, imadNumber: val })
                }
                name="imad-number"
                value={payload?.metadata?.imadNumber}
              />
            </FormField>
          </Section>
          <Section>
            <FormField title="Wire amount" htmlFor="wire-amount" required>
              <DollarsInput
                onChange={(val: string) => {
                  onChangeAttribute('metadata', {
                    ...payload?.metadata,
                    wireAmount: val
                  })
                }}
                value={payload?.metadata?.wireAmount}
                id="wire-amount"
                data-testid="wire-amount-field"
                name="wire-amount"
                placeholder="Wire amount"
              />
            </FormField>
          </Section>
          <Sections>
            <Section>
              <FormField title="Borrower First Name:" htmlFor="borrowerFirstName" required>
                <TextInput
                  id="borrowerFirstName"
                  data-testid="borrower-firstName-field"
                  onChange={val =>
                    onChangeAttribute('metadata', {
                      ...payload?.metadata,
                      borrower: {
                        ...payload?.metadata?.borrower,
                        firstName: val
                      }
                    })
                  }
                  value={payload?.metadata?.borrower?.firstName}
                />
              </FormField>
            </Section>
            <Section>
              <FormField title="Borrower Last Name:" htmlFor="borrowerLastName" required>
                <TextInput
                  id="borrowerLastName"
                  data-testid="borrower-lastName-field"
                  onChange={val =>
                    onChangeAttribute('metadata', {
                      ...payload?.metadata,
                      borrower: {
                        ...payload?.metadata?.borrower,
                        lastName: val
                      }
                    })
                  }
                  value={payload?.metadata?.borrower?.lastName}
                />
              </FormField>
            </Section>
          </Sections>
        </>
      ) : (
        <Sections>
          <Section>
            <FormField title="Assignee" htmlFor="assignee">
              <UsersAutocomplete
                value={payload.assigneeName}
                onSelect={setUser}
                onClear={clearUserId}
                data-testid="assignee-field"
                id="assignee"
              />
            </FormField>
          </Section>
          <Section>
            <FormField title="Date Due" htmlFor="date-due">
              <DateInput
                id="date-due"
                value={presence(payload?.dueAt)}
                onChange={val => onChangeAttribute('dueAt', val)}
                includeTime
              />
            </FormField>
          </Section>
        </Sections>
      )}
      {newTaskFunctionality.enabled && isIrContractUploadTask && (
        <Sections>
          <Section>
            <FormField title="Incoming Residence Contract" required>
              <FileInput
                id="file-name"
                data-testid="fileUploadInput"
                accept=".pdf"
                value={payload.files}
                onChange={value => {
                  onChangeAttribute('files', value)
                }}
                multiple={true}
              />
            </FormField>
          </Section>
        </Sections>
      )}
      <Sections>
        {(isDocumentUploadTask || isSendWireConfirmation) && setSendNotification ? (
          <Flex flexDirection="column" gap="$3">
            <Switch
              type="button"
              isOn={!!sendNotification}
              label="Send notification"
              onClick={() => setSendNotification(prev => !prev)}
            />
            <Text size="xs" color="decorative5">
              We’ll send an email notification to the loan officer sharing these details
            </Text>
          </Flex>
        ) : (
          <FormField title="Note" htmlFor="note">
            <TextArea
              id="note"
              value={payload?.note}
              name="note"
              data-testid="note-field"
              onChange={val => onChangeAttribute('note', val)}
              className={styles.note}
            />
          </FormField>
        )}
      </Sections>
    </>
  )
}
