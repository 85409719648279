import React from 'react'
import {
  AutoComplete,
  AutoCompleteProps,
  TEmployeesAutoCompleteOption
} from '../../../../components'
import { getUsers2Url } from '../../../../services/getUrl'
import { getRequest } from '../../../../utils'

type TProps = {
  id?: string
  onClear?: () => void
  onSelect?: AutoCompleteProps['onSelect']
  value?: string
}

export type UserAutoCompleteResponse = {
  attributes: { email: string; name: string }
  id: string
}

export const UsersAutocomplete = ({ onSelect = () => {}, value, ...props }: TProps) => {
  const formatUser = (user: UserAutoCompleteResponse): TEmployeesAutoCompleteOption => ({
    id: user.id,
    title: user.attributes.name,
    subTitle: user.attributes.email
  })
  const formatUsers = users => users.map(formatUser)

  const getUsers = async (searchTerm: string) => {
    const searchParams = {
      'filter[_search]': searchTerm,
      autocomplete: true,
      'page[size]': 10
    }
    const response = await getRequest(getUsers2Url({ searchParams }))
    return formatUsers(response.data.data).slice(0, 10)
  }

  return (
    <AutoComplete
      getData={getUsers}
      onSelect={onSelect}
      value={value}
      placeholder="Enter user name or email..."
      {...props}
    />
  )
}
