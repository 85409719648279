//@ts-strict
import { useState } from 'react'
import { Box, Text } from '@foundation/components'
import { ErrorMessage } from '@foundation/components/Select/styles'
import { styled } from '@foundation/themes/styles'
import {
  Button,
  Divider,
  Form,
  FormActions,
  InlineEditMoneyInput,
  InlineEditSelect,
  PrimaryButton,
  Section,
  Sections
} from 'components'
import { pplBillingFreq } from 'lookups'
import { formatMoney } from 'utils'
import { MINIMUM_PPL_MONTHLY_CAP } from '../constants'
import styles from './MonthlyCap.module.scss'
import { monthlyCapInfo } from './MonthlyCapMessages'
import { PaymentOptions } from './PaymentOptions'
import { MonthlyCapFormProps } from './types'

const StyledForm = styled(Form)`
  padding: 2.4rem;
  width: 100%;
  min-width: 60rem;
  max-width: 80rem;

  &.confirmation {
    min-width: 40rem;
    max-width: 60rem;
  }

  @media (max-width: 414px) {
    min-width: 30rem;
  }
`

const monthlyCapErrorMessage = (currentMonthlyCap: number) => {
  if (currentMonthlyCap < MINIMUM_PPL_MONTHLY_CAP) {
    return `Monthly cap is below $${MINIMUM_PPL_MONTHLY_CAP}`
  }

  return ''
}

/*
 * In this component coexists 2 types of monthly caps. `monthlyCap` represents
 * the persisted value, in example the monthly cap the agent previously set.
 *
 * Meanwhile, currentMonthlyCap represents the input monthly cap that can be either
 * added or modified by the sales representative.
 */
export const MonthlyCapForm = ({
  setupMode = true,
  cancelText = 'Cancel',
  buyerMonthlyCap = 0,
  monthlyCap = 0,
  billingFreq = '',
  onCancel,
  onSubmit,
  submitText = 'Save'
}: MonthlyCapFormProps) => {
  const [currentMonthlyCap, setCurrentMonthlyCap] = useState(monthlyCap || 0)
  const [currentBuyerMonthlyCap, setCurrentBuyerMonthlyCap] = useState(buyerMonthlyCap || 0)
  const [currentBillingFreq, setCurrentBillingFreq] = useState(billingFreq)
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<'card' | 'link'>('card')
  const [isLoading, setIsLoading] = useState(false)

  const formattedMonthlyCap = formatMoney(buyerMonthlyCap + monthlyCap)
  const isUpdatingMonthlyCap = monthlyCap > 0
  const isLessThanMinimumCap = currentMonthlyCap + currentBuyerMonthlyCap < 200
  const isBillingFreqMissing = ['month', 'week'].includes(currentBillingFreq) === false

  const subTitle = isUpdatingMonthlyCap
    ? `HomeLight will place a hold of ${formattedMonthlyCap} at the start of every billing cycle that will expire in 7 days in order to verify payment method. 
       The agent will be charged at the end of each billing cycle based off of the leads and pricing they agreed to below not to exceed ${formattedMonthlyCap}.`
    : `Please input the monthly cap value. Once value has been set, select the payment method the agent would like to use. Billing will be monthly.`

  return (
    <StyledForm
      onSubmit={() => {
        setIsLoading(true)
        onSubmit(
          currentMonthlyCap,
          currentBuyerMonthlyCap,
          currentPaymentMethod,
          currentBillingFreq
        )
      }}
    >
      <Box marginBottom="$4">
        <Text weight="bold">Set the seller/buyer cap and select payment method</Text>
      </Box>

      <Text size="sm" as="span">
        {subTitle}
      </Text>
      <Divider className={styles.divider} />

      <Sections>
        <Section>
          <Box marginBottom="$2">
            <Text>Buyer Cap</Text>
          </Box>

          <InlineEditMoneyInput
            className={styles.input}
            value={String(currentBuyerMonthlyCap)}
            onChange={buyerCap =>
              setCurrentBuyerMonthlyCap(Number(buyerCap.replace(/[^\d.]/g, '')))
            }
          />

          <Box marginBottom="$2" marginTop="$2">
            <Text>Seller Cap</Text>
          </Box>

          <InlineEditMoneyInput
            className={styles.input}
            value={String(currentMonthlyCap)}
            onChange={cap => setCurrentMonthlyCap(Number(cap.replace(/[^\d.]/g, '')))}
          />

          <ErrorMessage hasError={isLessThanMinimumCap}>
            <Box marginTop="$3">
              <Text color="danger5" size="sm">
                {monthlyCapErrorMessage(currentMonthlyCap + currentBuyerMonthlyCap)}
              </Text>
            </Box>
          </ErrorMessage>

          {setupMode && (
            <>
              <Box marginBottom="$2" marginTop="$5">
                <Text>Billing Frequency</Text>
              </Box>

              <InlineEditSelect
                prompt="Select Billing Frequency"
                className={styles.select}
                value={currentBillingFreq}
                onChange={freq => setCurrentBillingFreq(String(freq))}
                options={pplBillingFreq}
              />

              <ErrorMessage hasError={isBillingFreqMissing}>
                <Box marginTop="$3" marginBottom="$5">
                  <Text color="danger5" size="xs">
                    Billing frequency is required.
                  </Text>
                </Box>
              </ErrorMessage>
            </>
          )}

          <Box marginTop="$8">
            <PaymentOptions
              hide={isUpdatingMonthlyCap}
              paymentMethod={currentPaymentMethod}
              onSelectPaymentType={setCurrentPaymentMethod}
            />
          </Box>
        </Section>

        <Section className={styles.bullets}>
          <ul className={styles.list}>
            {monthlyCapInfo(monthlyCap, currentMonthlyCap + currentBuyerMonthlyCap).map(
              (info, idx) => (
                <li key={`monthly-cap-message-${idx}`}>{info}</li>
              )
            )}
          </ul>
        </Section>
      </Sections>

      <FormActions>
        <Button type="button" disabled={isLoading} onClick={onCancel}>
          {cancelText}
        </Button>
        <PrimaryButton type="submit" disabled={isLessThanMinimumCap} isLoading={isLoading}>
          {submitText}
        </PrimaryButton>
      </FormActions>
    </StyledForm>
  )
}
