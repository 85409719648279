// @ts-strict
import { useQuery } from 'react-query'
import { useQueryClient } from 'hooks'
import { fetchPayPerLeadPaymentInformation } from 'services'

export const usePayPerLeadPaymentInformationController = (
  providerId: string,
  providerType: string
) => {
  const queryCache = useQueryClient()
  const clearCaches = () => {
    queryCache.invalidateQueries(['ppl-payment-information', providerId])
  }

  const payPerLeadPaymentInformationQuery = useQuery(
    ['ppl-payment-information', providerId],
    () => fetchPayPerLeadPaymentInformation(providerId, providerType),
    { refetchOnWindowFocus: false }
  )

  return { payPerLeadPaymentInformationQuery, clearCaches }
}
