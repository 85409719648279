// @ts-strict
import { TableHeaderActions, TableHeaderRow, TableHeaderColumn as TH } from 'components'
import { useListContext, useTasksContext } from 'contexts'
import styles from './Tasks.module.scss'

export const TaskTableHeader = () => {
  const { attachable } = useTasksContext()
  const { showColumn } = useListContext()

  return (
    <TableHeaderRow>
      {showColumn('status') && <TH name="Status" className={styles.status} />}
      {showColumn('categoryStage') && <TH name="Category/Stage" className={styles.categoryStage} />}
      {showColumn('title') && <TH name="Title" />}
      {showColumn('attachedTo') && !attachable && <TH name="Attached to" />}
      {showColumn('ir') && <TH name="IR" className={styles.ir} />}
      {showColumn('assigneeAssigner') && (
        <TH name="Assignee/Assigner" className={styles.assignee} />
      )}
      {showColumn('dueReady') && (
        <TH
          sort={[
            { name: 'Due', slug: 'Due' },
            { name: 'Ready', slug: 'Ready' }
          ]}
        />
      )}
      {showColumn('irCoeDate') && (
        <TH name="IR COE Date" sort={[{ name: 'IR COE Date', slug: 'irCoeDate' }]} />
      )}
      {showColumn('sourcePartner') && <TH name="Partner Source" />}
      {showColumn('assignedCreated') && (
        <TH
          sort={[
            { name: 'Assigned', slug: 'Assigned' },
            { name: 'Created', slug: 'Created' }
          ]}
        />
      )}
      <TableHeaderActions />
    </TableHeaderRow>
  )
}
