import { Option } from 'classes'
import { BuyerLead, SellerLead } from 'models'
import { DISPOSITION_KEYS, DispositionsByStage } from './types'

export const PRE_INTRO_DISPOSITION_STAGES = [
  { value: 'leave_voicemail', name: 'Auto Voicemail Drop' },
  { value: 'called_no_msg', name: 'Called No Message' },
  { value: 'interested', name: 'Interested Follow-up' },
  { value: 'interested_via_text', name: 'Interested via Text' },
  { value: 'human_voicemail_blind', name: 'Personal Voicemail' },
  { value: 'vm_not_set_up', name: 'Voicemail Not Setup' }
].map(option => new Option(option))

export const OTHER_DISPOSITION_STAGES = [
  { value: 'bad_number', name: 'Bad Number' },
  { value: 'duplicate', name: 'Duplicate' },
  { value: 'fake', name: 'Fake' },
  { value: 'looking_for_specific_agent', name: 'Looking for Specific Agent' },
  { value: 'spam', name: 'Marketing' },
  { value: 'not_interested', name: 'Not Interested' },
  { value: 'not_transacting', name: 'Not Transacting' },
  { value: 'not_transacting_agent', name: 'Real Estate Agent' },
  { value: 'not_transacting_rental', name: 'Renter' },
  { value: 'curious_valuation', name: 'Valuation - Just Curious' },
  { value: 'tax_valuation', name: 'Valuation - Taxes' },
  { value: 'refinance_valuation', name: 'Valuation - Refinance' },
  { value: 'unqualified', name: 'Unqualified' },
  { value: 'unsubscribe', name: 'Unsubscribe' }
].map(option => new Option(option))

export const NORMAL_DISPOSITION_STAGES = [
  { value: 'normal_intro', name: 'Normal Intro' },
  { value: 'offer_submitted', name: 'Offer Submitted' },
  { value: 'blind_intro', name: 'Blind Intro' }
].map(option => new Option(option))

export const BLIND_INTRO_DISPOSITION_STAGES = [
  'leave_voicemail',
  'human_voicemail_blind',
  'vm_not_set_up',
  'blind_intro'
]

export const NEW_OR_CLAIMED_DISPOSITION_STAGES = [
  { value: 'new', name: 'New' },
  { value: 'claimed', name: 'claimed' }
].map(option => new Option(option))

export const FOLLOW_UP_DISPOSITION_STAGES = [
  { value: 'called_left_msg', name: 'Called Left Message' },
  { value: 'called_no_msg', name: 'Called - No Message' },
  { value: 'no_action', name: 'No Action Required' },
  { value: 'not_transacting', name: 'Not Transacting' },
  { value: 'send_to_onboarding', name: 'Send to On-boarding' },
  { value: 'scheduled_call', name: 'Schedule Follow-up Call' },
  { value: 'sent_to_agent_ae', name: 'Wants More Agents/Needs ASx' }
].map(option => new Option(option))

export const INTRO_PENDING_DISPOSITION_STAGES = [
  { value: 'intro_pending', name: 'Intro Pending' }
].map(option => new Option(option))

export const INTRODUCED_DISPOSITION_STAGES = [{ value: 'introduced', name: 'Introduced' }].map(
  option => new Option(option)
)

export const AGENT_LEFT_VM_DISPOSITION_STAGES = [
  { value: 'agent_left_vm', name: 'Agent Left VM' }
].map(option => new Option(option))

export const CONNECTED_DISPOSITION_STAGES = [{ value: 'connected', name: 'Connected' }].map(
  option => new Option(option)
)

export const MEETING_SCHEDULED_DISPOSITION_STAGES = [
  { value: 'meeting_scheduled', name: 'Meeting Scheduled' }
].map(option => new Option(option))

export const MET_IN_PERSON_DISPOSITION_STAGES = [
  { value: 'met_in_person', name: 'Met In Person' }
].map(option => new Option(option))

export const COMING_SOON_DISPOSITION_STAGES = [{ value: 'coming_soon', name: 'Coming Soon' }].map(
  option => new Option(option)
)

export const LISTING_DISPOSITION_STAGES = [{ value: 'listing', name: 'Listing' }].map(
  option => new Option(option)
)

export const MAKING_OFFER_DISPOSITION_STAGES = [
  { value: 'making_offer', name: 'Making Offer' }
].map(option => new Option(option))

export const IN_ESCROW_DISPOSITION_STAGES = [{ value: 'in_escrow', name: 'In Escrow' }].map(
  option => new Option(option)
)

export const CLOSED_DISPOSITION_STAGES = [{ value: 'closed', name: 'Closed' }].map(
  option => new Option(option)
)

export const CLOSED_BOOKED_DISPOSITION_STAGES = [
  { value: 'closed_booked', name: 'Closed Booked' }
].map(option => new Option(option))

export const CLOSED_PAID_DISPOSITION_STAGES = [{ value: 'closed_paid', name: 'Closed Paid' }].map(
  option => new Option(option)
)

export const FAILED_DISPOSITION_STAGES = [{ value: 'failed', name: 'Failed' }].map(
  option => new Option(option)
)

export const REMOVED_DISPOSITION_STAGES = [{ value: 'removed', name: 'Removed' }].map(
  option => new Option(option)
)

export const MISC_DISPOSITION_STAGES = [
  { value: 'awaiting_response', name: 'Awaiting Response' },
  { value: 'called_busy', name: 'Called Busy' },
  { value: 'client_left_vm', name: 'Client Left Voicemail' },
  { value: 'client_connected', name: 'Client Connected' },
  { value: 'in_contract', name: 'In Contract' }
].map(option => new Option(option))

export const DISPOSITIONS_BY_STAGE: DispositionsByStage = [
  {
    nextStages: ['preIntro', 'normalIntro', 'other'],
    options: NEW_OR_CLAIMED_DISPOSITION_STAGES,
    canBeChosen: false,
    key: DISPOSITION_KEYS.newOrClaimed
  },
  {
    nextStages: ['preIntro', 'normalIntro', 'other'],
    options: PRE_INTRO_DISPOSITION_STAGES,
    canBeChosen: true,
    sectionTitle: 'Pre-Intro Dispositions',
    key: DISPOSITION_KEYS.preIntro
  },
  {
    nextStages: ['preIntro', 'normalIntro', 'other', 'followUp'],
    options: NORMAL_DISPOSITION_STAGES,
    canBeChosen: true,
    sectionTitle: 'Intro Disposition',
    key: DISPOSITION_KEYS.normalIntro
  },
  {
    nextStages: ['preIntro', 'normalIntro', 'other'],
    options: OTHER_DISPOSITION_STAGES,
    canBeChosen: true,
    sectionTitle: 'Other Dispositions',
    key: DISPOSITION_KEYS.other
  },
  {
    nextStages: ['preIntro', 'normalIntro', 'other', 'followUp'],
    options: FOLLOW_UP_DISPOSITION_STAGES,
    canBeChosen: true,
    sectionTitle: 'Follow-Up Disposition',
    key: DISPOSITION_KEYS.followUp
  },
  {
    nextStages: ['preIntro', 'normalIntro', 'other', 'followUp'],
    options: INTRO_PENDING_DISPOSITION_STAGES,
    canBeChosen: false,
    sectionTitle: 'Next Stage',
    key: DISPOSITION_KEYS.introPending
  },
  {
    nextStages: ['preIntro', 'normalIntro', 'other', 'followUp'],
    options: INTRODUCED_DISPOSITION_STAGES,
    canBeChosen: false,
    sectionTitle: 'Next Stage',
    key: DISPOSITION_KEYS.introduced
  },
  {
    nextStages: ['preIntro', 'normalIntro', 'other', 'followUp'],
    options: AGENT_LEFT_VM_DISPOSITION_STAGES,
    canBeChosen: false,
    sectionTitle: 'Next Stage',
    key: DISPOSITION_KEYS.agentLeftVM
  },
  {
    nextStages: ['preIntro', 'normalIntro', 'other', 'followUp'],
    options: CONNECTED_DISPOSITION_STAGES,
    canBeChosen: false,
    sectionTitle: 'Next Stage',
    key: DISPOSITION_KEYS.connected
  },
  {
    nextStages: ['preIntro', 'normalIntro', 'other', 'followUp'],
    options: MEETING_SCHEDULED_DISPOSITION_STAGES,
    canBeChosen: false,
    sectionTitle: 'Next Stage',
    key: DISPOSITION_KEYS.meetingScheduled
  },
  {
    nextStages: ['preIntro', 'normalIntro', 'other', 'followUp'],
    options: MET_IN_PERSON_DISPOSITION_STAGES,
    canBeChosen: false,
    sectionTitle: 'Next Stage',
    key: DISPOSITION_KEYS.metInPerson
  },
  {
    nextStages: ['preIntro', 'normalIntro', 'other', 'followUp'],
    options: COMING_SOON_DISPOSITION_STAGES,
    canBeChosen: false,
    sectionTitle: 'Next Stage',
    key: DISPOSITION_KEYS.comingSoon
  },
  {
    nextStages: ['preIntro', 'normalIntro', 'other', 'followUp'],
    options: LISTING_DISPOSITION_STAGES,
    canBeChosen: false,
    sectionTitle: 'Next Stage',
    key: DISPOSITION_KEYS.listing
  },
  {
    nextStages: ['preIntro', 'normalIntro', 'other', 'followUp'],
    options: MAKING_OFFER_DISPOSITION_STAGES,
    canBeChosen: false,
    sectionTitle: 'Next Stage',
    key: DISPOSITION_KEYS.makingOffer
  },
  {
    nextStages: ['preIntro', 'normalIntro', 'other', 'followUp'],
    options: IN_ESCROW_DISPOSITION_STAGES,
    canBeChosen: false,
    sectionTitle: 'Next Stage',
    key: DISPOSITION_KEYS.inEscrow
  },
  {
    nextStages: ['preIntro', 'normalIntro', 'other', 'followUp'],
    options: CLOSED_DISPOSITION_STAGES,
    canBeChosen: false,
    sectionTitle: 'Next Stage',
    key: DISPOSITION_KEYS.closed
  },
  {
    nextStages: ['preIntro', 'normalIntro', 'other', 'followUp'],
    options: CLOSED_BOOKED_DISPOSITION_STAGES,
    canBeChosen: false,
    sectionTitle: 'Next Stage',
    key: DISPOSITION_KEYS.closedBooked
  },
  {
    nextStages: ['preIntro', 'normalIntro', 'other', 'followUp'],
    options: CLOSED_PAID_DISPOSITION_STAGES,
    canBeChosen: false,
    sectionTitle: 'Next Stage',
    key: DISPOSITION_KEYS.closedPaid
  },
  {
    nextStages: [],
    options: FAILED_DISPOSITION_STAGES,
    canBeChosen: false,
    sectionTitle: 'Next Stage',
    key: DISPOSITION_KEYS.failed
  },
  {
    nextStages: [],
    options: REMOVED_DISPOSITION_STAGES,
    canBeChosen: false,
    sectionTitle: 'Next Stage',
    key: DISPOSITION_KEYS.removed
  },
  {
    nextStages: ['preIntro', 'normalIntro', 'other', 'followUp'],
    options: MISC_DISPOSITION_STAGES,
    canBeChosen: false,
    sectionTitle: 'Next Stage',
    key: DISPOSITION_KEYS.miscDispositionStages
  }
]

export const EXCLUDE_STAGES_FOR_BUYERS = [
  'curious_valuation',
  'tax_valuation',
  'refinance_valuation'
]

export const CURRENT_DATE = new Date().toISOString()

export const STAGE_WITH_FOLLOW_UP_DATE = ['scheduled_call', 'interested']

export const canShowFollowUpDate = (stage: string) => STAGE_WITH_FOLLOW_UP_DATE.includes(stage)

export const isNormalIntro = (stage: string) => stage === 'normal_intro'

export const isBlindIntro = (stage: string, lead: BuyerLead | SellerLead) => {
  return BLIND_INTRO_DISPOSITION_STAGES.includes(stage) && lead.blindIntro
}

export const requiredFieldsFromLeadForNormalIntro = (lead: BuyerLead | SellerLead) => {
  const { callReview, alreadyHasAgent } = lead
  const {
    relationship,
    genderPreference,
    languageRequirement,
    previousAgent,
    previousAgentName,
    previousAgentBrokerage,
    availability,
    highTouch,
    autoIntroCount,
    sameAgents,
    buyerProgress,
    preapproved,
    clientInterestedIn,
    homeListed,
    qualificationMethod,
    why,
    interestedInLoan,
    interestedInOtherside,
    askedInvestorQualifyingQuestions,
    pitchedInvestorToClient,
    yearBuilt,
    howLongOwned,
    occupiedBy,
    lotSize,
    garageSize,
    ownershipStatus,
    condition,
    squareFeet,
    bedroomsCount,
    bathroomsCount,
    investorIntroQuantity
  } = callReview

  return {
    relationship,
    genderPreference,
    languageRequirement,
    previousAgent,
    availability,
    highTouch,
    autoIntroCount,
    sameAgents,
    buyerProgress,
    preapproved,
    clientInterestedIn,
    homeListed,
    alreadyHasAgent,
    qualificationMethod,
    why,
    previousAgentName,
    previousAgentBrokerage,
    interestedInLoan,
    interestedInOtherside,
    askedInvestorQualifyingQuestions,
    pitchedInvestorToClient,
    yearBuilt,
    howLongOwned,
    occupiedBy,
    lotSize,
    garageSize,
    ownershipStatus,
    condition,
    squareFeet,
    bedroomsCount,
    bathroomsCount,
    investorIntroQuantity
  }
}

export const isNormalIntroValid = (
  lead: BuyerLead | SellerLead,
  introType?: string,
  investorFieldsActive?: boolean
) => {
  const {
    why,
    buyerProgress,
    preapproved,
    clientInterestedIn,
    homeListed,
    alreadyHasAgent,
    previousAgent,
    previousAgentName,
    previousAgentBrokerage,
    qualificationMethod,
    interestedInLoan,
    interestedInOtherside,
    askedInvestorQualifyingQuestions,
    pitchedInvestorToClient,
    yearBuilt,
    howLongOwned,
    occupiedBy,
    lotSize,
    garageSize,
    ownershipStatus,
    condition,
    squareFeet,
    bedroomsCount,
    bathroomsCount,
    investorIntroQuantity
  } = requiredFieldsFromLeadForNormalIntro(lead)
  const clientScreenFieldsForBuyers = buyerProgress && preapproved && interestedInLoan !== undefined
  const clientScreenFieldsForSellers = clientInterestedIn && homeListed
  const agentIntroInvestorScreenRequiredFields =
    lead.sourcePageType !== 'offers' ||
    lead.userType !== 'seller' ||
    (askedInvestorQualifyingQuestions !== undefined &&
      pitchedInvestorToClient !== undefined &&
      (!investorFieldsActive || introType === 'Agent Intro'))

  const investorIntroInvestorScreenRequiredFields =
    !investorFieldsActive ||
    lead.sourcePageType !== 'offers' ||
    lead.userType !== 'seller' ||
    (introType !== 'Investor Intro' && introType !== 'Both') ||
    (yearBuilt &&
      howLongOwned &&
      occupiedBy &&
      lotSize &&
      garageSize &&
      ownershipStatus &&
      condition &&
      squareFeet &&
      bedroomsCount &&
      bathroomsCount &&
      investorIntroQuantity)

  const clientScreenSectionRequiredFields =
    why &&
    alreadyHasAgent !== undefined &&
    interestedInOtherside !== undefined &&
    ((lead.userType === 'buyer' && clientScreenFieldsForBuyers) ||
      (lead.userType === 'seller' && clientScreenFieldsForSellers))

  const hasPreviousAgentWithTheOtherFields =
    previousAgent === 'Yes'
      ? Boolean(previousAgentName !== undefined && previousAgentBrokerage !== undefined)
      : false

  const hasPreviousAgentButDontNeedOtherFields =
    previousAgent !== 'Yes' && previousAgent !== undefined

  const hasPreviousAgent =
    hasPreviousAgentWithTheOtherFields || hasPreviousAgentButDontNeedOtherFields

  const hasQualificationMethod = qualificationMethod === 'Call' || qualificationMethod === 'Message'

  const isNormalIntroFormValid =
    Boolean(clientScreenSectionRequiredFields) &&
    hasPreviousAgent &&
    hasQualificationMethod &&
    Boolean(agentIntroInvestorScreenRequiredFields) &&
    Boolean(investorIntroInvestorScreenRequiredFields)

  return isNormalIntroFormValid ? [] : ['']
}
