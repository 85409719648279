// @ts-strict
import { Flex } from '@foundation/components'
import { Block, CheckBoxField, Table, TableResults } from 'components'
import { TableHeaderCell as TH, TableHeaderRow as TR } from 'components'
import { TableCell as Td, TableRow as Tr } from 'components'
import { TextInput } from 'components/Form'
import { PayPerLeadProviderSetting } from 'models'
import { capitalize, formatDollarsAndCents } from 'utils'
import { IS_ZIP_CODE, MAXIMUM_PPL_SETTING_PRICE, MINIMUM_PPL_SETTING_PRICE } from '../constants'
import styles from './LocationSettings.module.scss'

type LocationSettingsTableProps = {
  locationSettings: PayPerLeadProviderSetting[]
  setPrice: (index: number, price: number) => void
  toggleActive: (index: number) => void
}

export const LocationSettingsTable = ({
  locationSettings,
  toggleActive,
  setPrice
}: LocationSettingsTableProps) => {
  return (
    <Table data-testid="location-settings-table">
      <TR>
        <TH>Location</TH>
        <TH>User type</TH>
        <TH>Price</TH>
      </TR>
      <TableResults type="Pay-Per-Lead Location Settings" colSpan={2}>
        {locationSettings.map((locationSetting: PayPerLeadProviderSetting, index: number) => (
          <LocationSettingsRow
            key={index}
            locationSetting={locationSetting}
            toggleActive={() => toggleActive(index)}
            setPrice={price => setPrice(index, price)}
          />
        ))}
      </TableResults>
    </Table>
  )
}

type LocationSettingsRowProps = {
  locationSetting: PayPerLeadProviderSetting
  setPrice: (price: number) => void
  toggleActive: () => void
}

const LocationSettingsRow = ({
  locationSetting,
  toggleActive,
  setPrice
}: LocationSettingsRowProps) => {
  const locationLabel = IS_ZIP_CODE(locationSetting.location!)
    ? locationSetting.location
    : slugToCityState(locationSetting.location!)

  const priceInDollars = Number(locationSetting.price || 0) / 100

  const isValidPrice =
    priceInDollars === 0 ||
    (priceInDollars >= MINIMUM_PPL_SETTING_PRICE && priceInDollars <= MAXIMUM_PPL_SETTING_PRICE)

  return (
    <Tr data-testid="location-setting-replace-me">
      <Td>
        <Flex gap="$4">
          <CheckBoxField
            title={locationLabel}
            checked={locationSetting.active}
            onChange={toggleActive}
          />
        </Flex>
      </Td>
      <Td>
        <Block title={capitalize(locationSetting.userType || '')} />
      </Td>
      <Td>
        <TextInput
          className={isValidPrice ? '' : styles.invalidValue}
          value={formatDollarsAndCents(Number(locationSetting.price) / 100)}
          onChange={price => setPrice(Number(price.replace(/[^\d.]/g, '').replace('.', '')))}
        />
      </Td>
    </Tr>
  )
}

const slugToCityState = (slug: string) => {
  const parts = slug.split('-')
  const state = parts.pop()?.toUpperCase()
  const city = parts.map(p => p.charAt(0).toUpperCase() + p.slice(1)).join(' ')

  return `${city}, ${state}`
}
