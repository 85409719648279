import styled from 'styled-components'
import { Section, Sections } from 'components'
import { Agent, AgentPayPerLeadSetting } from 'models'
import { LocationSettingsTableReadOnly } from './LocationSettingsTableReadOnly'
import { LocationSettingsUpdateButtonForm } from './LocationSettingsUpdateButtonForm'

type LocationSettingsCurrentListProps = {
  agent: Agent
  locationSettings: AgentPayPerLeadSetting[]
}

const InactiveSettingsWrapper = styled.div`
  opacity: 0.5;
`

export const LocationSettingsCurrentList = ({
  agent,
  locationSettings
}: LocationSettingsCurrentListProps) => {
  if (!locationSettings || locationSettings.length === 0) {
    return null
  }

  const activeLocations = locationSettings.filter(
    (location: AgentPayPerLeadSetting) => location.active
  )

  const inactiveLocations = locationSettings.filter(
    (location: AgentPayPerLeadSetting) => !location.active
  )

  return (
    <>
      <Section>
        <Sections>
          <b style={{ paddingRight: '8px' }}>Location Settings ({activeLocations.length})</b>
          <LocationSettingsUpdateButtonForm agent={agent} locationSettings={locationSettings} />
        </Sections>
        <LocationSettingsTableReadOnly locationSettings={activeLocations} />
      </Section>

      <Section>
        <Sections>
          <b
            style={{ paddingRight: '8px' }}
          >{`Inactive Locations (${inactiveLocations.length})`}</b>
        </Sections>

        <InactiveSettingsWrapper>
          <LocationSettingsTableReadOnly locationSettings={inactiveLocations.slice(0, 4)} />
        </InactiveSettingsWrapper>
      </Section>
    </>
  )
}
