//@ts-strict
import { useState } from 'react'
import { Text } from '@foundation/components'
import { styled } from '@foundation/themes/styles'
import { Button, Form, FormActions, PrimaryButton } from 'components'
import { ReleaseBillingFrequencyFormProps } from './types'

const StyledForm = styled(Form)`
  padding: 2.4rem;
  width: 100%;
  min-width: 60rem;
  max-width: 80rem;

  &.confirmation {
    min-width: 40rem;
    max-width: 60rem;
  }

  @media (max-width: 414px) {
    min-width: 30rem;
  }
`

export const ReleaseBillingFrequencyForm = ({
  cancelText = 'Cancel',
  onCancel,
  onSubmit,
  submitText = 'Save'
}: ReleaseBillingFrequencyFormProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const subTitle = 'This will cancel any scheduled frequency change'

  return (
    <StyledForm
      onSubmit={() => {
        setIsLoading(true)
        onSubmit()
      }}
    >
      <Text size="sm" as="span">
        {subTitle}
      </Text>

      <FormActions>
        <Button type="button" onClick={onCancel}>
          {cancelText}
        </Button>
        <PrimaryButton type="submit" isLoading={isLoading}>
          {submitText}
        </PrimaryButton>
      </FormActions>
    </StyledForm>
  )
}
