// @ts-strict
import { useMemo } from 'react'
import { useAgentAeAreasController } from 'controllers'
import { useMetroplexes, useUsStates } from 'lookups'
import { AgentAeArea } from 'models'
import { presence } from 'utils'
import {
  getAssignedAreasFromAgentAeAreas,
  getAssignedMetroplexOptions,
  getAssignedStateOptions
} from './utils'

type TProps = {
  assigneeId?: string
}

export const useAssignedAreas = ({ assigneeId }: TProps = {}) => {
  const { agentAeAreasQuery } = useAgentAeAreasController({ perPage: 10000 })
  const { agentAeAreas = [], isLoading } = agentAeAreasQuery
  const { metroplexes } = useMetroplexes()
  const { usStates } = useUsStates()

  const assigneeAreas = useMemo(() => {
    const areas = agentAeAreas.filter((area: AgentAeArea) => area.user?.id === assigneeId)
    return getAssignedAreasFromAgentAeAreas(areas)
  }, [agentAeAreas, assigneeId])

  const assignedAreas = useMemo(() => {
    const hasAssignedAreas = presence(assigneeAreas.metroplexes) || presence(assigneeAreas.usStates)
    return assigneeId && hasAssignedAreas ? assigneeAreas : { metroplexes, usStates }
  }, [assigneeAreas, metroplexes, usStates, assigneeId])

  const usStateAreas = useMemo(() => {
    return getAssignedStateOptions(assignedAreas.metroplexes, assignedAreas.usStates)
  }, [assignedAreas])

  const usStateOptions = useMemo(() => {
    const { allAreas, nonMetroplex } = usStateAreas
    return allAreas.concat(nonMetroplex)
  }, [usStateAreas])

  const metroplexOptions = useMemo(() => {
    return getAssignedMetroplexOptions(assignedAreas.metroplexes)
  }, [assignedAreas])

  const assignedAreaOptions = useMemo(() => {
    return usStateOptions.concat(metroplexOptions)
  }, [metroplexOptions, usStateOptions])

  const getAssignedAreaName = (value?: string) => {
    if (!value) {
      return
    }
    return assignedAreaOptions.find(opt => opt.value === value)?.name
  }

  const metroplexAndNonMetroplexOptions = useMemo(() => {
    const { nonMetroplex } = usStateAreas
    return nonMetroplex.concat(metroplexOptions)
  }, [usStateAreas, metroplexOptions])

  return {
    assignedAreas,
    assignedAreaOptions,
    metroplexOptions,
    usStateOptions,
    getAssignedAreaName,
    isLoading,
    metroplexAndNonMetroplexOptions
  }
}
