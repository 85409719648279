// @ts-strict
import { useState } from 'react'
import _ from 'lodash'
import { Box, Spinner, Text, useToastContext } from '@foundation/components'
import { Flex } from '@foundation/components/Flex/styles'
import { css, styled } from '@foundation/themes/styles'
import { Button, Contents, Divider } from 'components'
import {
  useAgentPayPerLeadPaymentInformationController,
  useAgentPayPerLeadSettingsController
} from 'controllers'
import { environment } from 'environment'
import { Agent } from 'models'
import { formatMoney, formatOrDash } from 'utils'
import { CardIcon } from '../Icons'
import { MonthlyCapForm } from '../MonthlyCap'
import { SettingsModal } from '../PayPerLeadSettings'

type PaymentInformationProps = {
  agent: Agent
}

const MONTH_FORMAT = (month: number) => (month < 10 ? `0${month}` : month)
const YEAR_FORMAT = (year: number) => (String(year).length > 2 ? String(year).substring(2) : year)
const ButtonNavigation = styled.div`
  ${({ theme }) => css`
    display: flex;

    > :last-child {
      margin-left: ${theme.space.$2};
    }
  `}
`

export const PaymentInformation = ({ agent }: PaymentInformationProps) => {
  const [editMonthlyCapVisible, setEditMonthlyCapVisible] = useState(false)
  const { agentPayPerLeadPaymentInformationQuery, updateAgentPayPerLeadMonthlyCap } =
    useAgentPayPerLeadPaymentInformationController(agent.id)
  const { createSetupCheckoutSession, createSetupCheckoutSessionMagicLink } =
    useAgentPayPerLeadSettingsController(agent.id)
  const { data: paymentInformation } = agentPayPerLeadPaymentInformationQuery
  const toast = useToastContext()

  const handleAddNewCard = () => {
    createSetupCheckoutSession.mutate(
      {
        subscriberId: agent.id,
        subscriberType: 'Agent',
        redirectUrl: window.location.href,
        addNewPaymentMethod: true
      },
      {
        onSuccess: response => {
          const newWindow = window.open(response.data.url, '_self', 'noopener,noreferrer')
          if (newWindow) {
            newWindow.opener = null
          }
        }
      }
    )
  }

  const handleOnSubmitLinkAgentDetails = () => {
    createSetupCheckoutSessionMagicLink.mutate(
      {
        subscriberId: agent.id,
        subscriberType: 'Agent',
        priceId: environment.stripeProsPriceId,
        redirectUrl: window.location.href
      },
      {
        onSuccess: () => {
          toast({
            title: 'Payment info email resent successfully.',
            status: 'success'
          })
        },
        onError: () => {
          toast({
            title: 'Unable to complete your request.',
            status: 'error'
          })
        }
      }
    )
  }

  const handleUpdateMonthlyCap = (newMonthlyCap: number, newBuyerMonthlyCap: number) => {
    updateAgentPayPerLeadMonthlyCap.mutate(
      { monthlyCap: newMonthlyCap * 100, buyerMonthlyCap: newBuyerMonthlyCap * 100 },
      {
        onSuccess: () => {
          toast({
            title: 'Agent Monthly Cap has been updated',
            status: 'success'
          })
        }
      }
    )
    setEditMonthlyCapVisible(false)
  }

  if (agentPayPerLeadPaymentInformationQuery.isLoading) {
    return (
      <Box margin="$3">
        <Spinner size="xs" label="Loading Pay-Per-Lead Payment Information..." />
      </Box>
    )
  }

  // At the moment the only way to determine that the agent is in PPL
  // is through this info.
  if (!paymentInformation || _.isEmpty(paymentInformation)) {
    // When the agent has not been introduced to PPL then disable the add card button
    const isPplAgent = agent.pplProviderSettings && agent.pplProviderSettings.length
    const buttonActions = (
      <ButtonNavigation>
        {isPplAgent ? (
          <Button as="primary" onClick={handleOnSubmitLinkAgentDetails}>
            <Text color="white" size="sm">
              Resend Email
            </Text>
          </Button>
        ) : null}
        {isPplAgent ? (
          <Button as="primary" onClick={handleAddNewCard}>
            <Text color="white" size="sm">
              Add card
            </Text>
          </Button>
        ) : null}
      </ButtonNavigation>
    )

    return (
      <Box width="485px">
        <Contents title="Payment Information" actions={buttonActions}>
          <Box
            borderStyle="solid"
            borderColor="neutral5"
            borderWidth="thin"
            padding="$8"
            width="485px"
            borderRadius="xl"
            fontSize="base"
          >
            No payment information available
          </Box>
        </Contents>
      </Box>
    )
  }

  const expMonth = formatOrDash(paymentInformation.expMonth, MONTH_FORMAT)
  const expYear = formatOrDash(paymentInformation.expYear, YEAR_FORMAT)
  const expiresAt = `Expires ${expMonth}/${expYear}`
  const monthlyAmountLeftInCents = paymentInformation.monthlyAmountLeft / 100
  const monthlyCapInCents = paymentInformation.monthlyCap / 100
  const monthlyAmountLeft = formatMoney(monthlyAmountLeftInCents)
  const monthlyCap = formatMoney(monthlyCapInCents)
  const buyerMonthlyAmountLeftInCents = paymentInformation.buyerMonthlyAmountLeft / 100
  const buyerMonthlyCapInCents = paymentInformation.buyerMonthlyCap / 100
  const buyerMonthlyAmountLeft = formatMoney(buyerMonthlyAmountLeftInCents)
  const buyerMonthlyCap = formatMoney(buyerMonthlyCapInCents)

  return (
    <>
      {editMonthlyCapVisible && (
        <SettingsModal title="Edit Monthly Cap" onCancel={() => setEditMonthlyCapVisible(false)}>
          <MonthlyCapForm
            setupMode={false}
            monthlyCap={monthlyCapInCents}
            buyerMonthlyCap={buyerMonthlyCapInCents}
            submitText="Save"
            onCancel={() => setEditMonthlyCapVisible(false)}
            onSubmit={(newMonthlyCap: number, newBuyerMonthlyCap: number) =>
              handleUpdateMonthlyCap(newMonthlyCap, newBuyerMonthlyCap)
            }
          />
        </SettingsModal>
      )}

      <Contents title="Payment Information">
        <Box
          borderStyle="solid"
          borderColor="neutral5"
          borderWidth="thin"
          padding="$8"
          width="485px"
          borderRadius="xl"
          fontSize="base"
        >
          <Flex flexDirection="row">
            <Flex gap="$4" width="70%" alignItems="center">
              <CardIcon variant={paymentInformation.brand} size="medium" />
              <Text size="sm"> - </Text>
              <Text size="sm">{paymentInformation.last4}</Text>
              <Text size="sm">{expiresAt}</Text>
            </Flex>

            <Flex justifyContent="flex-end" width="30%">
              <Button as="action" onClick={handleAddNewCard}>
                <Text color="primary5" size="sm">
                  Add new card
                </Text>
              </Button>
            </Flex>
          </Flex>

          <Box marginTop="$6" marginBottom="$5">
            <Divider />
          </Box>

          <Flex width="100%" justifyContent="flex-end">
            <Button as="underscore" onClick={() => setEditMonthlyCapVisible(true)}>
              <Text size="sm">Edit Caps</Text>
            </Button>
          </Flex>

          <Flex
            borderColor="neutral5"
            borderStyle="solid"
            borderRadius="xl"
            borderWidth="thin"
            marginTop="$2"
            gap="$2"
            padding="$3"
            width="100%"
          >
            <Box>
              <Flex flexDirection="row" gap="$3" marginBottom="$4">
                <Text size="sm" weight="bold">
                  Buyer Cap
                </Text>
              </Flex>

              <Flex
                marginTop="$4"
                backgroundColor="neutral2"
                padding="$4"
                borderRadius="base"
                width="fit-content"
              >
                <Flex gap="$1" flexDirection="column">
                  <Flex flexDirection="row">
                    <Text weight="semibold" size="sm">
                      {buyerMonthlyAmountLeft}
                    </Text>
                  </Flex>
                  <Text size="xxs">REMAINING</Text>
                </Flex>

                <Divider as="vertical" />

                <Flex gap="$1" flexDirection="column">
                  <Flex flexDirection="row">
                    <Text weight="semibold" size="sm">
                      {buyerMonthlyCap}
                    </Text>
                  </Flex>
                  <Flex flexDirection="row">
                    <Text size="xxs">MONTHLY CAP</Text>
                  </Flex>
                </Flex>
              </Flex>
            </Box>

            <Box>
              <Flex flexDirection="row" gap="$3">
                <Text size="sm" weight="bold">
                  Seller Cap
                </Text>
              </Flex>

              <Flex
                marginTop="$4"
                backgroundColor="neutral2"
                padding="$4"
                borderRadius="base"
                width="fit-content"
              >
                <Flex gap="$1" flexDirection="column">
                  <Flex flexDirection="row">
                    <Text weight="semibold" size="sm">
                      {monthlyAmountLeft}
                    </Text>
                  </Flex>
                  <Text size="xxs">REMAINING</Text>
                </Flex>

                <Divider as="vertical" />

                <Flex gap="$1" flexDirection="column">
                  <Flex flexDirection="row">
                    <Text weight="semibold" size="sm">
                      {monthlyCap}
                    </Text>
                  </Flex>
                  <Flex flexDirection="row">
                    <Text size="xxs">MONTHLY CAP</Text>
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Contents>
    </>
  )
}
