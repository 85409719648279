import { Task } from 'models'
import { getHapiRequestParams, TasksSchema } from 'schema'
import { getTasks2Url, getTasksCount } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'
import { getSearchParams, SearchParamsSettings } from './getSearchParams'

const taskResponseShape: TasksSchema = {
  tasks: [
    'title',
    'note',
    'assignedAt',
    'createdAt',
    'completedAt',
    'completionNote',
    'dismissedAt',
    'dueAt',
    'readyAt',
    'templateSlug',
    'requiredForLeadStage',
    'type',
    'metadata',
    { 'template:taskTemplate': ['title', 'priority', 'slug', 'description'] },
    { 'completedBy:user': ['firstName', 'lastName'] },
    {
      'assignee:user': [
        'name',
        'firstName',
        'lastName',
        'email',
        { agent: ['firstName', 'lastName', { agentTeam: ['name'] }] }
      ]
    },
    { 'assigner:user': ['name', 'firstName', 'lastName', 'email'] },
    {
      'attachable:lead': [
        'name',
        'userType',
        'stage',
        {
          'cashOfferProviderLeads:providerLead': [
            { 'providable:cashOfferLead': ['incomingMasterProperty'] }
          ]
        },
        {
          'tradeInProviderLead:providerLead': [
            { 'providable:tradeInLead': ['departingMasterProperty'] }
          ]
        },
        {
          'hlssProviderLead:providerLead': [{ providable: ['departingMasterProperty'] }]
        },
        {
          'bbysProviderLead:providerLead': [
            {
              'providable:bbysLead': [
                { sourcePartner: ['slug'] },
                'incomingResidencedExpectedCloseEscrowDate',
                'departingPropertyFullAddress'
              ]
            },
            { providable: ['departingMasterProperty'] }
          ]
        }
      ]
    },
    { 'subAttachable:cashOfferLead': ['incomingMasterProperty', { providerLead: ['stage'] }] },
    { 'attachable:agent': ['lastName', 'firstName'] },
    { taskCategory: ['name', 'slug'] }
  ]
}

export const countTasks = async (
  settings: SearchParamsSettings,
  allLeadTasksEnabled?: boolean,
  signal?: AbortSignal
) => {
  const searchParams = {
    ...getSearchParams(settings, allLeadTasksEnabled)
  }
  const countParams = Object.fromEntries(
    Object.entries(searchParams).filter(([k, _v]) => k.includes('filter'))
  )
  const url = getTasksCount({ searchParams: countParams })
  const response = await getRequest(url, signal)
  return { tasksCount: response.data.data }
}

export const fetchTasks = async (
  settings: SearchParamsSettings,
  allLeadTasksEnabled?: boolean,
  signal?: AbortSignal
) => {
  const searchParams = {
    ...getSearchParams(settings, allLeadTasksEnabled),
    ...getHapiRequestParams(taskResponseShape)
  }

  const url = getTasks2Url({ searchParams })
  const response = await getRequest(url, signal)

  const tasks: Task[] = await deserializeAsync(response.data, {
    transform: Task.create
  })
  return { tasks }
}
