import merge from 'deepmerge'
import { getLead, Lead } from 'models'
import {
  BBYSLeadAttributes,
  CCBBYSLeadSchema,
  getHapiRequestParams,
  LeadAttributesWithoutId,
  OrderAttributes
} from 'schema'
import { getLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchCCBBYSLead = async (id: string, updatedFlow: boolean): Promise<Lead> => {
  const leadFields: Array<LeadAttributesWithoutId> = [
    'calculatedValue',
    'callReview',
    'createdAt',
    'email',
    'emailAlt',
    'fullAddress',
    'furthestStage',
    'humanTimeline',
    'isDeal',
    'lastCallDate',
    'lastStageUpdate',
    'latitude',
    'longitude',
    'name',
    'notesToAgents',
    'partnerName',
    'phoneAlt',
    'price',
    'propertyType',
    'qualificationTalkedToClient',
    'referringOwnerType',
    'revenueRecognitionDate',
    'salesNotes',
    'source',
    'sourcePageType',
    'stage',
    'stateCode',
    'userType',
    'dialerQueueStatus',
    'leadSlackChannelUrl'
  ]

  const orderFields: Array<keyof OrderAttributes> = [
    'createdAt',
    'ownedBusinessUnit',
    'source',
    'stage',
    'updatedAt'
  ]

  const bbysLeadFields: Array<keyof BBYSLeadAttributes> = [
    // 'actualLoanAmount',
    'additionalClientNameOnTitle',
    'additionalLiensOnProperty',
    'administrationFee',
    'agentEstimatedHomeValue',
    'agentEstimatedHomeValueIntake',
    'agentEstimatedLoanPayoff',
    'agentFeePercentage',
    'agentFeeValue',
    'agreementExpiresDate',
    'departingResidenceTitleCompanyAdditionalEmail',
    'agreementSentDate',
    'approvalNumbersVerifiedDate',
    'approvalType',
    'approvalTypeIntake',
    'approvedCombinedLoanValueRatio',
    'automatedChecks',
    'buyBeforeYouSellLoanAmount',
    'buyBeforeYouSellLoanFundingAmount',
    'buyBeforeYouSellLoanNumber',
    'cancellationFee',
    'channel',
    'combinedLoanToValueRatioIntake',
    'contingencyRemovalOnly',
    'createdAt',
    'hlDrPurchasePrice',
    'hlDrPurchaseFundingAmount',
    'departingPropertyCondition',
    'departingPropertyExternalLoanPayoff',
    'departingPropertyFullAddress',
    'departingPropertyGuaranteedPrice',
    'departingPropertyHoaPresent',
    'departingPropertyListingPrepFee',
    'departingPropertyOwnershipExpenses',
    'departingPropertyRepairCosts',
    'departingPropertySellersAgentCommission',
    'departingPropertySolarLoanBalance',
    'departingPropertySolarPanelsPresent',
    'departingPropertyTargetPurchaseCoeDate',
    'departingPropertyTransactionCosts',
    'departingPropertyUuid',
    'departingPropertyValuation',
    'departingResidenceBackupContractSentDate',
    'departingResidenceBackupContractSignedDate',
    'departingResidenceClientInContractDate',
    'departingResidenceClosedDate',
    'departingResidenceCurrentListPrice',
    'currentDrMlsStatus',
    'departingResidenceExpectedCloseEscrowDate',
    'departingResidenceFinalSalePrice',
    'departingResidenceHeldInTrust',
    'departingResidenceInEscrowDate',
    'departingResidenceListedDate',
    'departingResidenceOriginalListPrice',
    'departingResidenceRequiredRepairsPreFunding',
    'departingResidenceTitleCompany',
    'departingResidenceTitleCompanyEmail',
    'departingResidenceTitleCompanyPhone',
    'departingResidenceYearBuilt',
    'directPayoffRequestedAt',
    'directPayoffSalePrice',
    'disclosuresSentDate',
    'division',
    'documentCenterLink',
    'documentaryAndIntangibleTaxes',
    'dpActualLoanPayoff',
    'dpBuyersAgentCommissionRate',
    'dpEstimatedSalePrice',
    'dpFirstPositionPayoffs',
    'dpGpPercentageOfValuation',
    'dpHelocBalance',
    'dpLoanToValueRatio',
    'dpMaxDownpaymentEquity',
    'dpNonFirstPositionBalances',
    'dpOtherLiensBalance',
    'dpPropertyRepairHoldbackRate',
    'dpSecondMortgageBalance',
    'dpSellersAgentCommissionRate',
    'dpSolarLeasePayoff',
    'dpTargetClientClosingCosts',
    'dpTargetClientClosingCostsRate',
    'dpTargetNewHomePurchaseEquity',
    'dpTargetTotalCosts',
    'dpTargetTradeInFee',
    'dpTargetTradeInFeeRate',
    'dpTotalAgentCommission',
    'dpUpfrontProceedsToClient',
    'equityBoost',
    'equityBoostAmount',
    'equityBoostFunded',
    'equityBoostCheckingSavingsAccount',
    'equityBoostIraBrokerageAccount',
    'equityBoost401k',
    'equityUnlockAvailableClient',
    'equityUnlockCloseSigningDate',
    'equityUnlockCloseSigningLocation',
    'equityUnlockDisclosuresSignedDate',
    'equityUnlockHighEndRangeIntake',
    'equityUnlockLoanFundingAmount',
    'equityUnlockLowEndRangeIntake',
    'equityUnlockTamNumber',
    'estimatedMortagageBalanceIntake',
    'estimatedMortgageBalanceSource',
    'estimatedMortgageBalance',
    'expectedDaysOnMarket',
    'expectedLoanFundingDate',
    'expectedProgramFeeRate',
    'fedexTrackingNumber',
    'feePercentage',
    'feeValue',
    'finalAgreementSentDate',
    'finalAgreementSignedDate',
    'finalApprovalType',
    'floodInsuranceClearedDate',
    'fundingsPlusDate',
    'fundingsPlusType',
    'hlcsAttached',
    'hlPurchasedDepartingResidenceDate',
    'hlPurchaseListedDate',
    'hlPurchaseTitleCompany',
    'hlPurchaseTitleCompanyAdditionalEmail',
    'hlPurchaseTitleCompanyEmail',
    'hlPurchaseTitleCompanyPhone',
    'hlSaleInEscrowDate',
    'hlSoldDepartingResidenceDate',
    'hlValuation',
    'homelightDepartingResidencePurchaseDate',
    'homelightPaidDate',
    'homeOwnersInsuranceClearedDate',
    'houseCanaryAvmValueIntake',
    'id',
    'incomingPropertyFullAddress',
    'incomingPropertyUuid',
    'incomingResidencedExpectedCloseEscrowDate',
    'incomingResidenceFinanceType',
    'incomingResidenceTitleCompany',
    'incomingResidenceTitleCompanyEmail',
    'incomingResidenceTitleCompanyPhone',
    'incomingResidenceUnderContractAtIntake',
    'incomingResidenceWholesaleLender',
    'inspectionCompleteDate',
    'inspectionCost',
    'inspectionOrderedDate',
    'inspectionReviewedDate',
    'inspectionScheduledDate',
    'inspectionsRequired',
    'instantEquityUnlockThresholdIntake',
    'instantRiskAdjustedValueIntake',
    'instantRiskAdjustmentPercentageIntake',
    'internalNotes',
    'investorName',
    'lateListingFee',
    'lenderEstimatedHomeValue',
    'lenderEstimatedHomeValueIntake',
    'lienBalance_1',
    'lienBalance_2',
    'lienBalance_3',
    'lienBalance_4',
    'lienBalance_5',
    'lienType_1',
    'lienType_2',
    'lienType_3',
    'lienType_4',
    'lienType_5',
    'listingCallFollowUpDate',
    'listingOpsNotes',
    'listingPlanCallInviteDate',
    'loanFundingDate',
    'loanPayoffValue',
    'loanPayoffValueThresholdIntake',
    'loManagedAllCash',
    'maintenanceReserve',
    'maintenanceReserveAmount',
    'maximumEquityUnlockAmount',
    'nameOfTrust',
    'notaryFee',
    'notesForHomelightOnIntake',
    'payoffRequestedDate',
    'payoffSentDate',
    'photosRequired',
    'primaryClientNameOnTitle',
    'programExtensionDate',
    'programFee',
    'programFeeDiscount',
    'programFeeSurcharge',
    'programLength',
    'propertyConditionsAndRepairs',
    'propertyListed',
    'realEstatePortalLink',
    'recordingFee',
    'refreshedApprovalDate',
    'riskAdjustedValue',
    'riskAdjustmentPercentage',
    'sourcePartner',
    'targetEquityUnlockAmount',
    'targetEquityUnlockAmountIntake',
    'terminationFee',
    'totalSellingCostsPercentage',
    'totalSellingCostsValue',
    'updatedAt',
    'userValueToPropertyValueMeanIntake',
    'warehouseFacility'
  ]

  const leadResponseShape: CCBBYSLeadSchema = {
    lead: [
      {
        client: [
          {
            user: ['firstName', 'lastName', 'email']
          },
          'softCreditCheckReportLink',
          'softCreditCheckIntelligence'
        ]
      },
      {
        leadUsers: ['role', { user: ['name', 'email', { phoneNumbers: ['number', 'phoneType'] }] }]
      },
      {
        order: [
          ...orderFields,
          {
            leads: [
              {
                'lenderProviderLeads:providerLead': [
                  {
                    'providable:lenderLead': [
                      'eaveLoanApplicationId',
                      'closeOfEscrowOn',
                      {
                        loanOfficer: ['companyName', { phoneNumber: ['number', 'phoneType'] }]
                      },
                      {
                        lender: ['slug']
                      }
                    ]
                  }
                ]
              },
              {
                'escrowOfficeProviderLeads:providerLead': [
                  { 'providable:escrowOfficeLead': ['netEquity', 'orderClosingOn'] }
                ]
              }
            ]
          }
        ]
      },
      { phoneNumbers: ['number', 'phoneType'] },
      {
        'referringOwner:agent': [
          'email1',
          'email2',
          'firstName',
          'lastName',
          'licenseNumber',
          'officeDisplayName',
          { agentTeam: ['name'] },
          { agentTeamMemberships: [{ agentTeam: ['name'] }] },
          { phoneNumbers: ['number', 'phoneType'] }
        ]
      },
      {
        'referringOwner:loanOfficer': [
          'companyName',
          'email',
          'name',
          { phoneNumber: ['number', 'phoneType'] }
        ]
      }
      // {
      //   userInvitations: ['email', 'name', 'slug']
      // }
    ]
  }

  const secondaryLeadResponseShape: CCBBYSLeadSchema = {
    lead: [
      {
        'bbysProviderLead:providerLead': [
          'stage',
          'reasonForFail',
          { providerLeadCapitalPartner: [{ capitalPartner: ['name', 'fundingFacility'] }] },
          {
            'providable:bbysLead': [
              ...bbysLeadFields,
              { 'propertyValuation:corbetsPropertyValuation': ['spreadsheetUrl'] },
              {
                'departingPropertySellerAgent:agent': [
                  'email1',
                  'email2',
                  'firstName',
                  'lastName',
                  { phoneNumbers: ['number', 'phoneType'] }
                ]
              },
              {
                'incomingPropertyBuyerAgent:agent': [
                  'email1',
                  'email2',
                  'firstName',
                  'lastName',
                  { phoneNumbers: ['number', 'phoneType'] }
                ]
              },
              { sourcePartner: ['slug'] },
              {
                loanOfficer: [
                  'companyName',
                  'email',
                  'partnerSlug',
                  'name',
                  { phoneNumber: ['number', 'phoneType'] }
                ]
              },
              {
                bbysLeadStageUpdates: [
                  'oldStage',
                  'newStage',
                  'occurredAt',
                  'createdAt',
                  'updatedAt'
                ]
              },
              {
                'departingPropertySellerColistAgent:agent': [
                  'email1',
                  'email2',
                  'firstName',
                  'lastName',
                  { phoneNumbers: ['number', 'phoneType'] }
                ]
              }
            ]
          },
          { providerLeadExpenses: ['slug', 'category', 'description', 'value'] }
        ]
      }
    ]
  }

  if (!updatedFlow) {
    const searchParams = getHapiRequestParams(leadResponseShape)
    const url = getLeadsUrl({ id, searchParams })

    const secondarySearchParams = getHapiRequestParams(secondaryLeadResponseShape)
    const secondaryUrl = getLeadsUrl({ id, searchParams: secondarySearchParams })

    const leadParams = { lead: [...leadFields] }
    const leadSearchParams = getHapiRequestParams(leadParams)
    const leadUrl = getLeadsUrl({ id, searchParams: leadSearchParams })

    const [primaryResponse, secondaryResponse, leadResponse] = await Promise.all([
      getRequest(url),
      getRequest(secondaryUrl),
      getRequest(leadUrl)
    ])

    const mergedData = merge(primaryResponse.data, secondaryResponse.data)

    const result = await deserializeAsync(merge(leadResponse.data, mergedData as any), {
      transform: getLead
    })

    return result
  }

  const searchParams = getHapiRequestParams(
    merge(leadResponseShape, merge(secondaryLeadResponseShape, { lead: [...leadFields] }))
  )
  const url = getLeadsUrl({ id, searchParams })
  const primaryResponse = await getRequest(url)

  return await deserializeAsync(primaryResponse.data, {
    transform: getLead
  })
}
