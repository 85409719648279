import { forwardRef, PropsWithChildren } from 'react'
import { Icon } from '../Icon'
import { Spinner } from '../Spinner'
import * as S from './styles'
import type { CommonProps } from './types'

export type ButtonProps = PropsWithChildren<CommonProps>

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      variant = 'solid',
      color = 'primary',
      size = 'sm',
      iconLeft,
      iconRight,
      isDisabled,
      isLoading,
      onClick,
      onMouseEnter,
      onMouseLeave,
      hasFullWidth,
      title,
      type,
      isRounded,
      className
    },
    ref
  ) => {
    const renderSpinnerLeft = !iconRight && isLoading && (
      <Spinner data-testid="spinnerLeft" size={size} />
    )
    const renderIconLeft = iconLeft && <Icon data-testid="iconLeft" path={iconLeft} size={size} />
    const renderSpinnerRight = !!iconRight && isLoading && (
      <Spinner data-testid="spinnerRight" size={size} />
    )
    const renderIconRight = iconRight && (
      <Icon data-testid="iconRight" path={iconRight} size={size} />
    )

    return (
      <S.Button
        ref={ref}
        variant={variant}
        color={color}
        size={size}
        disabled={isDisabled || isLoading}
        isDisabled={isDisabled || isLoading}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        hasFullWidth={hasFullWidth}
        title={title}
        type={type}
        isRounded={isRounded}
        className={className}
      >
        {renderSpinnerLeft || renderIconLeft}
        {children}
        {renderSpinnerRight || renderIconRight}
      </S.Button>
    )
  }
)
