import { Icon, Menu, Section } from 'components'
import {
  usePayPerLeadBillingInfoController,
  usePayPerLeadPaymentInformationController
} from 'controllers'
import { Agent, Investor, PayPerLeadBillingInfo } from 'models'
import { BillingCycleButtonForm } from './BillingCycleButtonForm'

type BillingCyclesActionMenuProps = {
  billingCycle: PayPerLeadBillingInfo
  provider: Agent | Investor
}

export const BillingCyclesActionMenu = ({
  provider,
  billingCycle
}: BillingCyclesActionMenuProps) => {
  const renderActions = billingCycle.allowPaymentRetry

  const { retryBillingCycle } = usePayPerLeadBillingInfoController(provider.id, provider.modelName)
  const { payPerLeadPaymentInformationQuery } = usePayPerLeadPaymentInformationController(
    provider.id,
    provider.modelName
  )
  const { data: paymentInformation } = payPerLeadPaymentInformationQuery
  const defaultPaymentMethodLast4 = paymentInformation?.last4

  return renderActions ? (
    <Menu icon={Icon.Ellipse} popupAlignment="left">
      <Section data-testid="referralMenuSection">
        <BillingCycleButtonForm
          billingCycle={billingCycle}
          defaultPaymentMethodLast4={defaultPaymentMethodLast4}
          mutation={retryBillingCycle}
        />
      </Section>
    </Menu>
  ) : null
}
