import { FormField, InlineEditPhoneInput, InlineEditTextInput, Section, Sections } from 'components'
import { useLeadContext } from 'contexts'
import { dataOrDash } from 'utils'

type TProps = {
  onChangeAttribute: (key: any, value: any) => void
  required: boolean
}

export default function LenderDispositionExistingLenderScreenSection({
  onChangeAttribute,
  required
}: TProps) {
  const { lead, updateLeadNoCacheClear } = useLeadContext()

  const updateLeadField = (
    value: Record<string, string | number | Record<string, string | Record<string, string>>>
  ) => {
    updateLeadNoCacheClear.mutateAsync(value)
  }

  const updateCallReview = (key: string, value: string) => {
    onChangeAttribute(key, value)
    updateLeadField({ callReview: { [key]: value } })
  }

  return (
    <Sections title="Existing Lender Info">
      <Section className="client-screen">
        <FormField title="Lender Company Name" required={required} inline>
          <InlineEditTextInput
            value={dataOrDash(lead?.callReview?.existingLenderName)}
            onBlur={(val: string) => updateCallReview('existingLenderName', val)}
          />
        </FormField>
        <FormField title="Loan Officer Name" required={required} inline>
          <InlineEditTextInput
            value={dataOrDash(lead?.callReview?.existingLoanOfficerName)}
            onBlur={(val: string) => updateCallReview('existingLoanOfficerName', val)}
          />
        </FormField>
        <FormField title="Loan Officer Phone" required={required} inline>
          <InlineEditPhoneInput
            value={dataOrDash(lead?.callReview?.existingLoanOfficerPhone)}
            onChange={(value: string) => updateCallReview('existingLoanOfficerPhone', value)}
          />
        </FormField>
        <FormField title="Loan Officer Email" required={required} inline>
          <InlineEditTextInput
            value={dataOrDash(lead?.callReview?.existingLoanOfficerEmail)}
            onChange={(value: string) => updateCallReview('existingLoanOfficerEmail', value)}
          />
        </FormField>
      </Section>
    </Sections>
  )
}
