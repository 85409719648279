// @ts-strict
import { InputHTMLAttributes, useRef } from 'react'
import { cx } from 'utils'
import { Icon, IconButton } from '../../../@foundation/components'
import formStyles from '../Form.module.scss'
import styles from './FileInput.module.scss'

type TProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> & {
  onChange?: (files: File[]) => void
  value?: File[]
}

export const FileInput = ({ value = [], onChange = () => {}, className, ...rest }: TProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const handleClick = () => {
    inputRef?.current?.click()
  }
  const handleRemove = (index: number) => {
    onChange(value.filter((_, i) => i !== index))
  }

  return (
    <div className={cx(styles.wrapper, formStyles.input, className)}>
      <button onClick={handleClick} className={styles.button} type="button">
        Choose Files
      </button>
      {!!value.length
        ? value.map((f, index) => (
            <div className={styles.uploadedFile}>
              <span className="icon">
                <Icon data-testid="iconLeft" path="StickyNote2Outlined" size="lg" />
              </span>
              {f.name}
              <IconButton
                variant="ghost"
                icon="Close"
                title="Remove file"
                onClick={() => handleRemove(index)}
              />
            </div>
          ))
        : 'No files chosen'}
      <input
        className={styles.input}
        type="file"
        onChange={e => {
          onChange([...e.target.files!])
        }}
        ref={inputRef}
        {...rest}
      />
    </div>
  )
}
