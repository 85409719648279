// @ts-strict
import { Dispatch, SetStateAction, useCallback } from 'react'
import { useDrop } from 'react-dnd'
import { Icon } from 'components'
import { ReorderableItem } from 'components'
import { Column } from 'lookups'
import { cx } from 'utils'
import styles from './ReorderableList.module.scss'

type TReorderableList = {
  className?: string
  onChange: Dispatch<SetStateAction<Column[]>>
  options: Column[]
}

export const dragAndDropId = 'list'

export const ReorderableList = ({ onChange, options, className }: TReorderableList) => {
  const findItem = useCallback(
    (value: string) => {
      const item = options.filter(option => option.value === value)[0]
      return {
        item,
        index: options.indexOf(item)
      }
    },
    [options]
  )

  const moveItem = useCallback(
    (id: string, to: number) => {
      const droppedItem = findItem(id)
      const replacedItem = findItem(options[to].value)

      const newList = options.map((option, idx) => {
        if (idx === droppedItem.index) {
          return replacedItem.item
        }

        if (idx === to) {
          return droppedItem.item
        }

        return option
      })

      onChange(newList)
    },
    [findItem, options, onChange]
  )
  const [, drop] = useDrop(() => ({ accept: dragAndDropId }))

  return (
    <>
      <ul className={cx(styles.list, className)} ref={drop}>
        {options.map(option => {
          if (!option.isReorderable) {
            return (
              <li key={option.value} className={cx(styles.item, styles.blocked)}>
                <Icon.Drag />
                {option.name}
              </li>
            )
          }

          return (
            <ReorderableItem
              key={option.value}
              id={option.value}
              moveItem={moveItem}
              findItem={findItem}
              name={option.name}
            />
          )
        })}
      </ul>
    </>
  )
}
