import { PayPerLeadBillingInfo } from 'models'
import { getPplBillingInfoUrl } from 'services/getUrl'
import { postRequest } from 'utils'

export const retryPayPerLeadBillingInfoCycle = async (
  providerId: string | number,
  providerType: string,
  payPerLeadBillingInfo: PayPerLeadBillingInfo,
  onSuccess?: () => void
) => {
  const url = getPplBillingInfoUrl({
    pathParams: {
      provider_type: providerType,
      provider_id: providerId,
      ppl_billing_info_id: payPerLeadBillingInfo.id
    },
    action: ':ppl_billing_info_id/retry-payment'
  })

  await postRequest(url, {})

  onSuccess?.()
}
