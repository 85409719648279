import { getLead, Lead } from 'models'
import {
  AgentMatchingLeadSchema,
  getHapiRequestParams,
  LeadAttributesWithoutId,
  OrderAttributes
} from 'schema'
import { getLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchSellerLead = async (id: string): Promise<Lead> => {
  const leadFields: Array<LeadAttributesWithoutId> = [
    'bbysEligibleArea',
    'blindIntro',
    'callReview',
    'createdAt',
    'dialerQueue',
    'eligibilityDetails',
    'email',
    'emailAlt',
    'fullAddress',
    'furthestStage',
    'humanTimeline',
    'lastCallDate',
    'lastStageUpdate',
    'latitude',
    'longitude',
    'areaId',
    'areaType',
    'name',
    'notesToAgents',
    'originalCreatedAt',
    'partnerName',
    'phoneAlt',
    'price',
    'propertyType',
    'propertyUuid',
    'salesNotes',
    'secondaryUserType',
    'sellerBbysEligible',
    'source',
    'sourcePageType',
    'stage',
    'stateCode',
    'userType',
    'isDeal',
    'qualificationTalkedToClient',
    'qualificationPreviousContract',
    'qualificationIncorrectAddress',
    'qualificationIsAnAgent',
    'qualificationVerifiedPhone',
    'qualificationIncorrectVoicemail',
    'qualificationIncompleteName',
    'qualificationNotAProperHouse',
    'needsListingReview',
    'warmTransferEligible',
    'warmTransferStarted',
    'eligibleWarmTransferAgentQuantity',
    'marketingChannel',
    'marketingSource',
    'sourceForm',
    'alreadyHasAgent',
    'phoneVerified'
  ]
  const orderFields: Array<keyof OrderAttributes> = [
    'createdAt',
    'ownedBusinessUnit',
    'source',
    'stage',
    'updatedAt'
  ]

  const leadResponseShape: AgentMatchingLeadSchema = {
    lead: [
      ...leadFields,
      { client: [] },
      { order: orderFields },
      { phoneNumbers: ['number', 'phoneType'] },
      { pendingActions: ['name', 'createdAt'] },
      { leadChannel: ['id', 'name', 'opsMethod'] },
      { leadViewers: ['id', 'firstName', 'lastName'] },
      {
        houseCanaryStatusCache: ['lastRan', 'status']
      }
    ]
  }

  const searchParams = getHapiRequestParams(leadResponseShape)

  const url = getLeadsUrl({ id, searchParams })
  const response = await getRequest(url)
  return await deserializeAsync(response.data, { transform: getLead })
}
