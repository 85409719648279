// @ts-strict
import { Dispatch, SetStateAction } from 'react'
import { If } from 'components'
import { AgentMatchingLeadAgentScreenSection } from 'content/AgentMatchingLead/AgentMatchingLeadAgentScreenSection'
import { AgentMatchingLeadClientScreenSection } from 'content/AgentMatchingLead/AgentMatchingLeadClientScreenSection'
import { AgentMatchingLeadInvestorScreenSection } from 'content/AgentMatchingLead/AgentMatchingLeadInvestorScreenSection'
import { useLeadController } from 'controllers'
import { BuyerLead, SellerLead } from 'models'
import {
  agentRequiredFields,
  clientRequiredFields,
  investorRequiredFields,
  useAgentRequiredFields
} from './requiredFieldHelper'
import * as S from './styles'

type Props = {
  introType?: string
  investorIntroFieldsActive: boolean
  lead: BuyerLead | SellerLead
  setLoadingNewInfoForLead: Dispatch<SetStateAction<boolean>>
}

export const NormalIntroStageRequiredFields = ({
  lead,
  investorIntroFieldsActive,
  setLoadingNewInfoForLead,
  introType = ''
}: Props) => {
  const { updateLead } = useLeadController(lead.id, lead.userType)
  const { agentScreenRequiredFields, setAgentScreenRequiredFields } = useAgentRequiredFields(lead)

  const updateLeadField = (value: Record<string, string | number | Record<string, string>>) => {
    setLoadingNewInfoForLead(true)
    updateLead.mutate(value)
  }

  const updateCallReview = (key: string, value: string) => {
    if (key === 'previousAgent') {
      setAgentScreenRequiredFields(prevState =>
        value === 'Yes'
          ? { ...prevState, previousAgentName: true, previousAgentBrokerage: true }
          : agentRequiredFields(lead, introType)
      )
    }

    updateLeadField({ callReview: { [key]: value } })
  }

  return (
    <S.NormalIntroWrapper>
      <AgentMatchingLeadClientScreenSection
        lead={lead}
        updateField={updateLeadField}
        updateCallReview={updateCallReview}
        investorIntroFieldsActive={investorIntroFieldsActive}
        requiredFields={clientRequiredFields(lead)}
        isDisposition={true}
      />
      <If
        test={
          !investorIntroFieldsActive ||
          introType === 'Agent Intro' ||
          introType === 'Both' ||
          introType === ''
        }
      >
        <AgentMatchingLeadAgentScreenSection
          hideBrokeragesToExclude
          lead={lead}
          updateCallReview={updateCallReview}
          requiredFields={agentScreenRequiredFields}
        />
      </If>
      <If test={lead.sourcePageType === 'offers' && lead.userType === 'seller'}>
        <AgentMatchingLeadInvestorScreenSection
          introType={introType}
          isDispositionForm={true}
          lead={lead}
          investorIntroFieldsActive={investorIntroFieldsActive}
          requiredFields={investorRequiredFields(lead, investorIntroFieldsActive, introType)}
          updateCallReview={updateCallReview}
        />
      </If>
    </S.NormalIntroWrapper>
  )
}
