import { FocusEvent, useState } from 'react'
import { Option } from 'classes'
import { Select, SelectProps } from 'components'
import { useUpdateEffect } from 'hooks'
import { DEFAULT_STRING } from 'utils'
import { cx } from 'utils'
import { DisplayValue, InlineProps } from '../DisplayValue'
import style from './InlineEditSelect.module.scss'

export interface IInlineEditSelectProps
  extends InlineProps,
    Omit<SelectProps, 'onBlur' | 'options'> {
  className?: string
  onBlur?: (val: SelectProps['value']) => void
  options: readonly Option[]
  prompt?: string
  readOnly?: boolean
}

export const InlineEditSelect = ({
  value,
  options = [],
  className = '',
  onChange,
  onBlur,
  addBlank = true,
  prompt = DEFAULT_STRING,
  isLoading,
  isError,
  format = val => val,
  readOnly,
  ...props
}: IInlineEditSelectProps) => {
  const [currentValue, setCurrentValue] = useState(value ?? '')
  const [isEditMode, setIsEditMode] = useState(false)

  useUpdateEffect(() => {
    setCurrentValue(value)
  }, [value])

  useUpdateEffect(() => {
    const handleOnBlur = () => {
      if (!isEditMode) {
        if (onBlur) {
          onBlur(currentValue)
        }
      }
    }

    handleOnBlur()
  }, [isEditMode])

  const handleOnChange = (changedValue: string) => {
    setIsEditMode(false)
    setCurrentValue(changedValue)

    if (onChange) {
      onChange(changedValue)
    }
  }

  const stopEditMode = (e: FocusEvent<HTMLSelectElement>) => {
    setIsEditMode(false)
  }

  const formatter = (changedValue: string) => {
    const option = options.find(o => o.value === changedValue)
    return format(option ? option.name : prompt)
  }

  if (isEditMode) {
    return (
      <Select
        {...props}
        className={cx(style.select, className)}
        value={currentValue}
        options={options}
        onChange={handleOnChange}
        onBlur={stopEditMode}
        addBlank={addBlank}
        autoFocus
      />
    )
  }

  return (
    <DisplayValue
      isReadOnly={readOnly}
      format={formatter}
      className={className}
      setEditMode={setIsEditMode}
      value={String(currentValue)}
      isLoading={isLoading}
      isError={isError}
    />
  )
}
