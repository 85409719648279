// @ts-strict
import { useState } from 'react'
import { useDeepCompareEffect } from 'react-use'
import { Button, Text } from '@foundation/components'
import { Option } from 'classes'
import {
  ButtonForm,
  DateInput,
  FormField,
  If,
  Select,
  SelectOptionGroup,
  TextArea,
  useButtonForm
} from 'components'
import { useCurrentUserContext } from 'contexts'
import { useLeadController } from 'controllers'
import { useFeatureFlags } from 'hooks'
import {
  agentMatchingCallReviewAssistedIntroEligible,
  agentMatchingCallReviewIntroType,
  aiBotExitFlags
} from 'lookups'
import { isSellerLead } from 'models'
import {
  d,
  dataOrDash,
  toSnakeCase,
  validateBlindIntroEligible,
  validateMatch,
  validatePresence,
  validateStringBoolean
} from 'utils'
import { BLIND_INTRO_KEYS, BlindIntroDispositionFields } from './BlindIntro'
import { NormalIntroStageRequiredFields } from './NormalIntroStageRequiredFields'
import {
  getCurrentDisposition,
  getInitialDispositionStage,
  getSelectableDispositions
} from './dispositionOptionsUtils'
import { DispositionInfo, FormPayload, Props } from './types'
import {
  BLIND_INTRO_DISPOSITION_STAGES,
  canShowFollowUpDate,
  CURRENT_DATE,
  EXCLUDE_STAGES_FOR_BUYERS,
  isBlindIntro,
  isNormalIntro,
  isNormalIntroValid,
  STAGE_WITH_FOLLOW_UP_DATE
} from './utils'

export const AgentMatchingLeadDisposition = ({ lead }: Props) => {
  const isBuyer = lead.userType === 'buyer'
  const formTitle = `${isBuyer ? 'Buyer' : 'Seller'} in disposition`
  const { updateLeadDisposition, updateLead } = useLeadController(lead.id, lead.userType)
  const [loadingNewInfoForLead, setLoadingNewInfoForLead] = useState(false)
  const [introType, setIntroType] = useState(lead?.callReview?.clientInterestedIn || '')

  const currentDisposition = getCurrentDisposition(lead)
  const listOfDispositionsToSelect = getSelectableDispositions(currentDisposition)
  const initialDispositionStage = getInitialDispositionStage(listOfDispositionsToSelect, isBuyer)
  const { isBlindIntroAdmin } = useCurrentUserContext()
  const { salesAppInvestorAutoIntroFields } = useFeatureFlags([
    'sales-app-investor-auto-intro-fields'
  ])

  const dispositionDropdownOptions = (dispositionGroup: DispositionInfo) =>
    dispositionGroup.options.filter((option: Option) => {
      const buyerExcluded = isBuyer && EXCLUDE_STAGES_FOR_BUYERS.includes(option?.value!)
      const nonAdminBlindIntro = !isBlindIntroAdmin && option.value === 'blind_intro'
      return !buyerExcluded && !nonAdminBlindIntro
    })

  const basePayload = {
    disposition: initialDispositionStage,
    occurredAt: CURRENT_DATE,
    followUpDate: ''
  }

  if (isBlindIntro(initialDispositionStage, lead)) {
    BLIND_INTRO_KEYS.forEach(key => {
      basePayload[key] = lead[key] || false
    })
  }

  const {
    formProps,
    fieldsProps: { onChangeAttribute, removeAttribute, payload },
    setters: { setValidations }
  } = useButtonForm<FormPayload>({
    mutation: updateLeadDisposition,
    transformPayload: (): { leadId: string; payload: FormPayload } => ({
      leadId: lead.id,
      payload
    }),
    initialPayload: basePayload,
    formValidations: {
      disposition: [
        ({ label, value }) => {
          if (isNormalIntro(value)) {
            return isNormalIntroValid(lead, introType, salesAppInvestorAutoIntroFields.enabled)
          }

          return validatePresence({ label, value })
        }
      ],
      followUpDate: {
        label: 'Follow Up Date',
        validations: [validatePresence],
        requirements: {
          disposition: {
            label: 'Disposition',
            validations: [validateMatch(STAGE_WITH_FOLLOW_UP_DATE)]
          }
        }
      },
      qualificationPreviousContract: {
        label: 'Listed or coming soon',
        validations: [validateStringBoolean],
        requirements: {
          disposition: {
            label: 'Disposition',
            validations: [
              validateMatch(BLIND_INTRO_DISPOSITION_STAGES),
              validateBlindIntroEligible(lead)
            ]
          }
        }
      },
      qualificationIncorrectAddress: {
        label: 'Unverifiable address',
        validations: [validateStringBoolean],
        requirements: {
          disposition: {
            label: 'Disposition',
            validations: [
              validateMatch(BLIND_INTRO_DISPOSITION_STAGES),
              validateBlindIntroEligible(lead)
            ]
          }
        }
      },
      qualificationNotAProperHouse: {
        label: 'Land, commercial or mobile home',
        validations: [validateStringBoolean],
        requirements: {
          disposition: {
            label: 'Disposition',
            validations: [
              validateMatch(BLIND_INTRO_DISPOSITION_STAGES),
              validateBlindIntroEligible(lead)
            ]
          }
        }
      },
      qualificationVerifiedPhone: {
        label: 'Name on voicemail matches lead',
        validations: [validateStringBoolean],
        requirements: {
          disposition: {
            label: 'Disposition',
            validations: [
              validateMatch(BLIND_INTRO_DISPOSITION_STAGES),
              validateBlindIntroEligible(lead)
            ]
          }
        }
      },
      qualificationIncompleteName: {
        label: 'Incomplete or fake client name',
        validations: [validateStringBoolean],
        requirements: {
          disposition: {
            label: 'Disposition',
            validations: [
              validateMatch(BLIND_INTRO_DISPOSITION_STAGES),
              validateBlindIntroEligible(lead)
            ]
          }
        }
      },
      qualificationIsAnAgent: {
        label: 'Potential real estate agent',
        validations: [validateStringBoolean],
        requirements: {
          disposition: {
            label: 'Disposition',
            validations: [
              validateMatch(BLIND_INTRO_DISPOSITION_STAGES),
              validateBlindIntroEligible(lead)
            ]
          }
        }
      },
      qualificationIncorrectVoicemail: {
        label: 'Allowed Voicemail',
        validations: [validateStringBoolean],
        requirements: {
          disposition: {
            label: 'Disposition',
            validations: [
              validateMatch(BLIND_INTRO_DISPOSITION_STAGES),
              validateBlindIntroEligible(lead)
            ]
          }
        }
      }
    }
  })

  const updateCallReview = (key: string, value: string) => {
    setLoadingNewInfoForLead(true)
    if (key === 'clientInterestedIn') {
      setIntroType(value)
    }
    updateLead.mutate({ callReview: { [key]: value } })
  }

  useDeepCompareEffect(() => {
    if (isNormalIntro(payload.disposition)) {
      setValidations({
        disposition: ({ label, value }) =>
          isNormalIntroValid(lead, introType, salesAppInvestorAutoIntroFields.enabled)
      })
      setLoadingNewInfoForLead(false)
    }
  }, [lead])

  if (!currentDisposition?.nextStages.length) {
    return null
  }

  const currentStageTitle = currentDisposition?.options.length
    ? currentDisposition?.options?.find(option => option.value === lead.stage)?.name
    : ''

  const handleDispositionChange = (stage: string) => {
    if (lead.blindIntro) {
      if (isBlindIntro(stage, lead)) {
        // Add blind intro payload keys to payload with lead values
        BLIND_INTRO_KEYS.forEach(key => {
          if (key in payload) {
            return
          }
          onChangeAttribute(key as keyof FormPayload, lead[key] || false)
        })
      } else {
        // Remove blind intro payload keys from payload
        BLIND_INTRO_KEYS.forEach(key => {
          removeAttribute(key as keyof FormPayload)
        })
      }
    }

    if (!canShowFollowUpDate(stage)) {
      onChangeAttribute('followUpDate', '')
    }

    onChangeAttribute('disposition', stage)
  }

  return (
    <>
      <Button size="xs" onClick={formProps.toggleShowForm}>
        Disposition
      </Button>
      <ButtonForm
        hideTriggerButton
        title={formTitle}
        {...formProps}
        isLoading={loadingNewInfoForLead || formProps.isLoading}
      >
        {currentStageTitle && (
          <p data-testid="current-stage-title">
            Current Stage:{' '}
            <Text as="span" weight="bold">
              {d(currentStageTitle)}
            </Text>
          </p>
        )}
        <FormField title="Update Stage" required>
          <Select
            aria-label="Update Stage"
            value={payload.disposition}
            onChange={val => handleDispositionChange(val)}
            data-testid="stage-drop-down"
          >
            {listOfDispositionsToSelect.map(disposition => {
              const options = dispositionDropdownOptions(disposition)

              return (
                <SelectOptionGroup
                  key={disposition.sectionTitle}
                  label={disposition.sectionTitle}
                  options={options}
                  data-testid={`${toSnakeCase(disposition.sectionTitle)}-options`}
                />
              )
            })}
          </Select>
        </FormField>
        <If
          test={
            salesAppInvestorAutoIntroFields.enabled &&
            isSellerLead(lead) &&
            isNormalIntro(payload.disposition)
          }
        >
          <FormField title="Intro Type" required>
            <Select
              addBlank
              onChange={(value: string) => updateCallReview('clientInterestedIn', value)}
              options={agentMatchingCallReviewIntroType}
              value={dataOrDash(lead?.callReview?.clientInterestedIn)}
            />
          </FormField>
        </If>
        {lead?.callReview?.assistedIntroEligible &&
          payload.disposition === 'interested_via_text' && (
            <FormField title="Assisted Intro Eligible?" required>
              <Select
                onChange={(value: string) => onChangeAttribute('assistedIntroEligible', value)}
                options={agentMatchingCallReviewAssistedIntroEligible}
                value={dataOrDash(
                  payload.assistedIntroEligible || lead?.callReview?.assistedIntroEligible
                )}
              />
            </FormField>
          )}
        {lead?.callReview?.aiBotExitFlag !== undefined && (
          <FormField title="AI Bot Exit Flag">
            <Select
              aria-label="AI Bot Exit Flag"
              data-testid="ai-bot-exit-flag-drop-down"
              onChange={(value: string) => onChangeAttribute('aiBotExitFlag', value)}
              options={[{ name: '', value: '' }, ...aiBotExitFlags]}
              value={payload.aiBotExitFlag || lead?.callReview?.aiBotExitFlag}
            />
          </FormField>
        )}
        <FormField title="Occurred On" required>
          <DateInput
            value={payload.occurredAt}
            onChange={val => onChangeAttribute('occurredAt', val)}
          />
        </FormField>
        {isBlindIntro(payload.disposition, lead) && (
          <BlindIntroDispositionFields payload={payload} onChangeAttribute={onChangeAttribute} />
        )}
        <FormField title="Notes">
          <TextArea
            value={payload.note}
            data-testid="note"
            onChange={val => onChangeAttribute('note', val)}
          />
        </FormField>
        {canShowFollowUpDate(payload.disposition) && (
          <FormField title="Follow Up On" required>
            <DateInput
              value={payload.followUpDate}
              onChange={val => {
                onChangeAttribute('followUpDate', val)
              }}
              includeTime
            />
          </FormField>
        )}
        {payload.disposition === 'normal_intro' && (
          <NormalIntroStageRequiredFields
            lead={lead}
            introType={introType}
            investorIntroFieldsActive={salesAppInvestorAutoIntroFields?.enabled}
            setLoadingNewInfoForLead={setLoadingNewInfoForLead}
          />
        )}
      </ButtonForm>
    </>
  )
}
