import { UseMutationResult } from 'react-query'
import { Box } from '@foundation/components'
import { ButtonForm, ButtonV2, useButtonForm } from 'components'
import { Contents } from 'components/Contents'
import { PayPerLeadBillingInfo } from 'models'
import styles from './BillingCyclesActionMenu.module.scss'

type Props = {
  billingCycle: PayPerLeadBillingInfo
  defaultPaymentMethodLast4?: string
  mutation: UseMutationResult<any, any, any, unknown>
  overrideSubmitButtonText?: string
}

const billingCycleParams = {
  hold_failed: {
    buttonText: 'Retry Hold',
    submitButtonText: 'Retry',
    title: 'Retry Hold charge',
    subtext: 'This will reprocess the hold charge using the payment method ending in: '
  },
  payment_failed: {
    buttonText: 'Retry Payment',
    submitButtonText: 'Retry',
    title: 'Retry Payment',
    subtext: 'This will reprocess the last payment using the payment method ending in: '
  }
}

export const BillingCycleButtonForm = ({
  billingCycle,
  mutation,
  defaultPaymentMethodLast4
}: Props) => {
  const billingCycleStatus = billingCycle.status
  const { buttonText, title, subtext } = billingCycleParams[billingCycleStatus]
  const submitButtonText = billingCycleParams[billingCycleStatus].submitButtonText

  const { formProps } = useButtonForm({
    mutation,
    submitButtonText,
    initialPayload: {
      payPerLeadBillingInfo: billingCycle
    }
  })

  return (
    <ButtonForm
      title={title}
      buttonText={buttonText}
      buttonComponent={buttonProps => <ButtonV2 className={styles.item} {...buttonProps} />}
      {...formProps}
    >
      <Contents>
        <span>
          {subtext}
          <span style={{ fontWeight: 'bold' }}>{defaultPaymentMethodLast4}</span>
        </span>
        <br />
        <br />
        <span style={{ fontWeight: 'bold' }}>Last reported error</span>
        <Box
          borderRadius="md"
          marginTop="$3"
          padding="$4"
          backgroundColor="neutral3"
          whiteSpace="pre-line"
        >
          {billingCycle.lastPaymentMessage}
        </Box>
      </Contents>
    </ButtonForm>
  )
}
