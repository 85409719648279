import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  Pagination,
  SearchConsole,
  SearchInputParams,
  SearchStatusPrompt,
  Table,
  TableResults,
  TableCell as TD,
  TableHeaderColumn as TH,
  TableHeaderRow as THR,
  TableRow as TR
} from 'components'
import { Contents } from 'components/Contents/index'
import { Page } from 'components/Page/index'
import { CreateLoanOfficerButtonForm, EditLoanOfficerButtonForm } from 'content/LoanOfficer'
import { UserImpersonateButton } from 'content/User/UserImpersonateButton'
import { useDocumentTitle, useQuery } from 'hooks'
import { getLoanOfficersUrl } from 'services/getUrl/getUrl'
import { ImpersonateIconButton } from 'templates'
import { deserializeAsync, getRequest, queryStringToObject } from 'utils'

export function LoanOfficersPage() {
  const { search } = useLocation()
  const { sortBy, sortDir, searchTerm } = queryStringToObject(search)
  const sort = `${sortDir === 'asc' ? '' : '-'}${
    sortBy ? sortBy.replace(' ', '_').toLowerCase() : 'id'
  }`
  const [page, setPage] = useState('1')
  const { data, isLoading } = useQuery(['loan-officers', page, search], async () => {
    const res = await getRequest(
      getLoanOfficersUrl({
        searchParams: {
          include: 'transaction_team',
          'page[number]': page,
          sort,
          search: searchTerm
        }
      })
    )
    return {
      loanOfficers: await deserializeAsync(res.data),
      total: res.data?.meta?.total
    }
  })

  const pageTitle = 'Loan Officers'
  useDocumentTitle(pageTitle)

  return (
    <Page>
      <Contents title={pageTitle} actions={<CreateLoanOfficerButtonForm />}>
        <SearchStatusPrompt type="Loan Officer" count={data?.total} />
        <SearchConsole search={<SearchInputParams placeholder="Search by name or email" />} />
        <Table>
          <THR>
            <TH sort={[{ name: 'ID', slug: 'id' }]} style={{ width: '64px' }} />
            <TH sort={[{ name: 'Name', slug: 'name' }]} />
            <TH sort={[{ name: 'Email', slug: 'email' }]} />
            <TH sort={[{ name: 'Company Name', slug: 'Company Name' }]} />
            <TH sort={[{ name: 'NMLS Id', slug: 'nmls_id' }]} style={{ width: '96px' }} />
            <TH name="TX Team" />
            <TH name="Actions" style={{ width: '64px' }} />
          </THR>
          <TableResults type="users" colSpan={4} isLoading={isLoading}>
            {data?.loanOfficers.map(loanOfficer => (
              <TR key={loanOfficer.id}>
                <TD>{loanOfficer.id}</TD>
                <TD>{loanOfficer.name || '-'}</TD>
                <TD>{loanOfficer.email || '-'}</TD>
                <TD>{loanOfficer.companyName || '-'}</TD>
                <TD>{loanOfficer.nmlsId || '-'}</TD>
                <TD>{loanOfficer.transactionTeam?.name || '-'}</TD>
                <TD>
                  <EditLoanOfficerButtonForm loanOfficer={loanOfficer} />
                  <UserImpersonateButton
                    userId={loanOfficer.userId}
                    as={ImpersonateIconButton}
                    customRedirectUrl={process.env.REACT_APP_LOAN_OFFICER_PORTAL_URL}
                  />
                </TD>
              </TR>
            ))}
          </TableResults>
        </Table>
        {!isLoading && (
          <Pagination
            totalPages={Math.ceil(data.total / 25)}
            currentPage={page}
            onChange={val => setPage(val)}
          />
        )}
      </Contents>
    </Page>
  )
}
