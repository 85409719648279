import { AgentLead } from 'models'
import { AgentLeadSchema, getHapiRequestParams } from 'schema'
import { getProvidableAgentLeadUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

const agentLeadDetailsShape: AgentLeadSchema = {
  agentLead: [
    'algoVersion',
    'autoIntroSentAt',
    'introSentAt',
    'pplReferral',
    {
      introTracking: [
        'claimMethod',
        'introDetail',
        'introType',
        'qualificationMethod',
        'qualificationType'
      ]
    }
  ]
}

export const fetchAgentLeadDetails = async (agentLeadId: string) => {
  const url = getProvidableAgentLeadUrl({
    id: agentLeadId,
    searchParams: {
      ...getHapiRequestParams(agentLeadDetailsShape)
    }
  })

  const response = await getRequest(url)
  return await deserializeAsync(response.data, { transform: AgentLead.create })
}
