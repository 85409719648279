import { FormEventHandler, useEffect, useMemo, useState } from 'react'
import { Box, Dialog, Flex, useToastContext } from '@foundation/components'
import { Button, DateInput, FormField, Select } from 'components'
import { LEADS_CACHE_KEY, ORDER_CACHE_KEY, useMutation, useQueryClient, useScreenSize } from 'hooks'
import { bbysStageFailureOptions, bbysStageOptions, yesOrNoOptions } from 'lookups'
import { getBBYSStage, getNextBBYSStage } from 'modules/bbys/helpers'
import { BBYS_DATA_QUERY } from 'modules/bbys/hooks'
import { getBBYSLeadDispositionUrl } from 'services/getUrl'
import { putRequest } from 'utils'
import styles from './Disposition.module.scss'

type DispositionProps = {
  customTrigger?: React.ReactNode
  leadId: string
  leadStage?: string
  loanFundingDate?: string
}

export const Disposition = ({
  customTrigger,
  leadId,
  leadStage,
  loanFundingDate
}: DispositionProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedStage, setSelectedStage] = useState(getNextBBYSStage(leadStage)?.value)
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString())
  const [selectedFailureReason, setSelectedFailureReason] = useState('')
  const [selectedCancellationFee, setSelectedCancellationFee] = useState('false')

  const toast = useToastContext()
  const { isMobile } = useScreenSize()
  const client = useQueryClient()

  const isFailureStage = selectedStage === 'failed'

  const isCanceledReason = useMemo(
    () => selectedFailureReason.startsWith('canceled'),
    [selectedFailureReason]
  )

  const { mutate, isLoading } = useMutation(
    () =>
      putRequest(getBBYSLeadDispositionUrl({ id: leadId, action: 'disposition' }), {
        disposition: selectedStage,
        occurred_at: selectedDate,
        cancellation_fee: isFailureStage && isCanceledReason ? selectedCancellationFee : null,
        notes: isFailureStage ? selectedFailureReason : null,
        reason_for_fail: isFailureStage ? selectedFailureReason : null
      }),
    {
      onSuccess: () => {
        setIsOpen(false)
        client.invalidateQueries({ queryKey: [LEADS_CACHE_KEY, 'bbys', leadId] })
        client.invalidateQueries({ queryKey: [BBYS_DATA_QUERY] })
        client.invalidateQueries({ queryKey: [ORDER_CACHE_KEY] })
      },
      onError: () => {
        toast({
          title: 'Ops! Something went wrong. Try to login again!',
          status: 'error'
        })
      }
    }
  )

  const onSubmit: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()
    mutate()
  }

  const renderTrigger = () => {
    if (customTrigger) {
      return customTrigger
    }
    if (isMobile) {
      return (
        <div className={styles.mobileDispositionBtnContainer}>
          <Button as="primary" iconPosition="start" size="small" className={styles.dispositionBtn}>
            Disposition
          </Button>
        </div>
      )
    }
    return (
      <Button as="primary" iconPosition="start" size="small" className={styles.dispositionBtn}>
        Disposition
      </Button>
    )
  }

  const isDisabled = !selectedStage || !selectedDate || (isFailureStage && !selectedFailureReason)

  useEffect(() => {
    if (leadStage) {
      const nextStage = getNextBBYSStage(leadStage)
      setSelectedStage(nextStage.value)
    }
  }, [leadStage])

  return (
    <Dialog.Root isOpen={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger>{renderTrigger()}</Dialog.Trigger>
      <Dialog.Content>
        <Dialog.Header>Buy Before You Sell Disposition</Dialog.Header>
        <Dialog.Body>
          <p className={styles.currentStage}>
            Current Stage: <span>{getBBYSStage(leadStage)}</span>
          </p>
          <form onSubmit={onSubmit}>
            <FormField title="Update Stage" required>
              <Select
                options={bbysStageOptions}
                value={selectedStage}
                onChange={setSelectedStage}
                placeholder={selectedStage}
                className={styles.select}
              />
            </FormField>

            <FormField title="Occurred On" required>
              <DateInput
                value={selectedDate}
                onChange={setSelectedDate}
                className={styles.dateInput}
              />
            </FormField>

            {isFailureStage && (
              <FormField title="Failed Reason" required>
                <Select
                  options={bbysStageFailureOptions}
                  value={selectedFailureReason}
                  onChange={setSelectedFailureReason}
                  placeholder="Select a Reason"
                  className={styles.select}
                />
              </FormField>
            )}

            {isFailureStage && isCanceledReason && (
              <FormField title="Cancellation Fee" required>
                <Select
                  options={yesOrNoOptions}
                  value={selectedCancellationFee}
                  onChange={setSelectedCancellationFee}
                  placeholder="Select an Option"
                  className={styles.select}
                />
              </FormField>
            )}

            <Box marginTop="$6">
              <Flex gap="$4" justifyContent="flex-end">
                <Button type="button" as="cancel" onClick={() => setIsOpen(false)}>
                  Cancel
                </Button>
                <Button type="submit" as="primary" disabled={isDisabled} submitting={isLoading}>
                  Submit
                </Button>
              </Flex>
            </Box>
          </form>
        </Dialog.Body>
      </Dialog.Content>
    </Dialog.Root>
  )
}
