export const hapiUrls = {
  agentAeAreas: '/user-data-service/sales/agent_ae_areas',
  agents: '/agent-data-service/sales/agents',
  agentsTerritories: '/agent-data-service/territories/agents',
  agentsSearch: '/agent-search-service/search/by-pattern',
  agent: '/agent-data-service/agents',
  agentLeads: '/lead-data-service/agents',
  agentPartnerships: '/partner-program-service/partner-programs',
  agentTeam: '/agent-team-service/agent-teams',
  agentTeamAgent: '/agent-team-service/agents',
  agentTeamMemberships: '/agent-team-service/team-memberships',
  bbysLeads: '/lead-data-service/v2/bbys',
  bbysLeadDisposition: '/lead-disposition-service/bbys/',
  blendUsers: '/provider-api-service/besi/blend/users',
  cashCloses: '/order-data-service/cash-closes',
  cashOfferLeads: '/lead-data-service/cash-offers',
  checkoutSessions: '/subscription-service/checkout-sessions',
  checkoutSessionsMagicLink: '/subscription-service/checkout-sessions/send-magic-link',
  clients: '/user-data-service/sales/clients',
  constants: '/platform-service/constants',
  docusignOauth: '/user-auth-service/oauth/docusign/redirect',
  dialerServiceLeads: '/dialer-service/leads',
  eligibilityCheck: '/property-service/v2/sales/eligibility/check-eligibility',
  envelopes: '/esign-service/docusign/sales/leads',
  documents: '/file-service/documents',
  files: '/file-service/files',
  generatedDocuments: '/lead-data-service/v2/bbys/generated-documents',
  handMatchLeads: '/lead-data-service/sales/agent-ae-queue',
  homeValueEstimate: '/property-service/valuation/find-by-property-uuid',
  investors: '/investor-data-service/investors',
  inspection: '/inspection-service',
  leadProviderLeads: '/lead-data-service/v3/leads',
  leads: '/lead-data-service/v3/leads',
  leads2: '/lead-data-service/leads',
  leads3: '/lead-disposition-service/leads',
  leads4: '/lead-data-service/v2/leads',
  leads5: '/lead-data-service/sales/leads',
  leadDisposition: '/lead-disposition-service/sales/leads',
  leadTeamMemberships: '/lead-data-service/lead-users',
  leadGroupables: '/lead-induction-service/lead-groupables',
  leadGroupableDefinitions: '/lead-induction-service/lead-groupable-definitions',
  lenders: '/lead-data-service/sales/lenders',
  listings: '/mls-listing-service/listings',
  loanOfficers: '/loan-officer-data-service/loan-officers',
  lookups: '/platform-service/sales-app/sales-app-lookups',
  opportunities: '/services-opportunity-service/opportunities',
  orders: '/order-data-service/orders',
  salesOrders: '/order-data-service/sales/orders',
  partnersForBbysLeads: '/lead-data-service/v2/partners/partners-for-bbys-leads',
  photos: '/file-service/files',
  pplPaymentInfos: '/subscription-service/ppl-payment-infos/agents',
  pplInvestorPaymentInfos: '/subscription-service/ppl-payment-infos/investor',
  pplProviderPaymentInfos: '/subscription-service/ppl-payment-infos/:provider_type/:provider_id/',
  pplBillingInfo: '/subscription-service/:provider_type/:provider_id/ppl-billing-info',
  pplReferrals: '/subscription-service/:provider_type/:provider_id/ppl-referrals',
  pplSettings: '/subscription-service/:provider_type/:provider_id/ppl-settings',
  presignedS3Link: '/file-service/bucket/create-signed-url',
  propertyQualification: '/lead-data-service/trade-ins/qualify-property-by-trade-in-lead-id',
  propertyValuation: '/data-science-service/corbets/build-listings-data',
  providableAgentLead: '/lead-data-service/v3/providable-leads/AgentLead',
  providerLeads: '/lead-data-service/provider-leads',
  providerLeadCapitalPartners: '/lead-data-service/provider-leads/provider-lead-capital-partners',
  pureCloudTokenActive: '/dialer-service/dialer/token-active',
  referrals: '/agent-data-service/territories/agents/referrals',
  sendLeadToDialer: '/provider-api-service/homelight/queue-lead-to-dialer',
  users2: '/user-data-service/sales/users',
  usersAgents: '/user-data-service/sales/agents',
  tasks: '/task-service/tasks',
  tasks2: '/task-service/v2/tasks',
  tasksCount: '/task-service/tasks/counts',
  teamMemberships: '/team-service/team-memberships',
  stopLeadNurture: '/lead-data-service/leads/:lead_id/unsubscribed-items',
  subTeamMemberships: '/team-service/sub-team-memberships',
  teams: '/team-service/teams',
  texts: '/sales-comms-service/texting/outgoing',
  textingInbox: '/sales-comms-service/texting/ae-inbox',
  textingCounts: '/sales-comms-service/texting/counts',
  tradeInLeads: '/lead-data-service/trade-ins',
  lenderLeads: '/lead-data-service/lender-leads',
  tradeInLeadDisposition: '/lead-disposition-service/trade-ins',
  transactions: '/transaction-data-service/sales/transactions',
  users: '/user-data-service/users',
  currentUser: '/user-auth-service/users/current',
  userAuth: '/user-auth-service/user-login',
  userManagement: '/user-auth-service/user-management',
  marketplacePrograms: `/agent-data-service/marketplace-programs`,
  dialer: '/sales-comms-service/dialer/token?app_name=lo_crm',
  externalLinks: '/external-link-service/sales/external-links',
  nylas: '/user-auth-service/oauth/nylas',
  agentLeads2: '/sales-comms-service/agent-leads',
  userIntegrations: '/user-data-service/user-integrations',
  hlSimpleSales: '/lead-data-service/hl-simple-sales',
  websocketTickets: '/user-auth-service/websockets/tickets',
  resolvePendingActions: '/lead-queue-service/pending-actions/resolve',
  leadWarmTransfer: '/warm-transfer-service/leads',
  activityFeed: '/activity-feed-service/sales/activity-feed',
  reassignPreferredEscrowOfficer:
    '/agent-data-service/sales/agents/reassign-preferred-escrow-officer',
  submitEscrowOrder: '/escrow-order-service/escrow-leads',
  submitInstantOrder: '/escrow-order-service/escrow-leads/instant_open',
  serviceOffices: '/escrow-order-service/service-offices',
  syncEscrowOrder: '/escrow-order-service/qualia/escrow-orders/sync',
  escrowOfficeLead: '/escrow-order-service/escrow-office-leads',
  userAuthPureCloudRedirect: '/user-auth-service/oauth/pure-cloud/redirect',
  changeAgentTeamPreferredEscrowOfficer:
    '/agent-data-service/sales/agents/change-agent-team-preferred-escrow-officer',
  searchLoanOfficers: '/lead-data-service/lender-leads/loan-officers',
  propertyUuid: '/property-service/public-search/public-find-by-full-address',
  bbysRequestValuation: '/lead-data-service/bbys-leads/request-valuation',
  bbysRequestIRContract: '/lead-data-service/bbys-leads/request-ir-contract',
  investorSubs: '/subscription-service/investor'
}
