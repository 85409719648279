/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/order */

import { Table, useTable } from '@foundation/components/Table'

import { useCreateOrderController, useDocumentTitle } from 'hooks'
import { CreateOrderButtonForm } from 'content/Order'

import { FiltersControl } from '../../components'
import { PAGE_SIZE } from '../../constants'
import { formatNumber } from '../../helpers'
import { BBYS_DATA_QUERY, useBBYSData } from '../../hooks'

import { Container } from './styles'
import { BBYS_PIPELINE_VIEW_COLUMNS } from './columns'
import ColumnsControl from '../../components/columns-control'
import { getFields, getIncludes } from './columns-to-json-api-helper'

export const Leads = () => {
  const defaultColumns = BBYS_PIPELINE_VIEW_COLUMNS

  const table = useTable(defaultColumns)
  const {
    search,
    sortBy,
    page,
    filters,
    onNavigate,
    columns,
    setColumns,
    setIsColumnsControlOpen
  } = table

  const activeColumns = columns.filter(c => c.display || c.frozen)
  const jsonApiTopLevelEntityName = 'bbys_lead_list'

  const { data, isLoading } = useBBYSData({
    search,
    sortBy,
    page,
    filters,
    fields: [],
    includes: getIncludes(
      activeColumns as typeof BBYS_PIPELINE_VIEW_COLUMNS,
      jsonApiTopLevelEntityName
    ),
    other: getFields(activeColumns as typeof BBYS_PIPELINE_VIEW_COLUMNS, jsonApiTopLevelEntityName)
  })
  useDocumentTitle('Buy Before You Sell Leads')

  const { createOrder, handleOrderCreated } = useCreateOrderController({
    clearCacheKey: BBYS_DATA_QUERY
  })

  const totalPages = data?.meta?.total_pages || 1
  const totalLeads = data?.meta?.total_leads || 0

  return (
    <Container>
      <Table.Root {...table}>
        <Table.View>
          <Table.Heading title="Buy Before You Sell" subtitle={`${formatNumber(totalLeads)} leads`}>
            <CreateOrderButtonForm
              mutation={createOrder}
              onSubmit={handleOrderCreated}
              isPrimary
              defaultPayload={{ leadType: 'bbys' }}
            />
          </Table.Heading>

          <Table.Actions
            searchPlaceholder="Search by client, agent, loan officer or property address"
            renderFilters={() => <FiltersControl />}
            renderColumns={() => (
              <ColumnsControl
                columns={columns}
                setColumns={setColumns}
                defaultColumns={defaultColumns}
                setIsColumnsControlOpen={setIsColumnsControlOpen}
              />
            )}
          />

          <Table.Data rows={data?.data || []} columns={activeColumns} isLoading={isLoading} />
          <Table.Details
            itemsPerPage={PAGE_SIZE}
            currentPage={+page}
            totalPages={totalPages}
            totalLeads={totalLeads}
            isLoading={isLoading}
          />
        </Table.View>

        <Table.Pagination
          currentPage={+page}
          totalPages={totalPages}
          onPageChange={nextPage => onNavigate(`${nextPage}`)}
        />
      </Table.Root>
    </Container>
  )
}
