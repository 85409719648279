/* eslint-disable typescript-sort-keys/interface */
// @ts-strict

import { useButtonForm } from 'components'
import { useLeadContext } from 'contexts'
import { useDispositionLenderController } from 'controllers'
import { useLoanCalculator } from 'hooks/useLoanCalculator'
import { Lead, Lender } from 'models'
import { stringifyValue, TFormValidation, validateMatch, validatePresence } from 'utils'

export type TLenderDispositionButtonFormPayload = {
  buyerState?: string
  clientSubmittedForm?: string
  disposition?: string
  followUpDate?: string
  leadType?: string
  lenderId?: string
  maxEquityUnlock?: string
  minEquityUnlock?: string
  note?: string
  skipAutoIntro?: string
  // Mortgage Fields
  needLender?: string
  creditScore?: string
  hasMinimumDownPayment?: string
  consistentIncome?: string
  fhaVaLoan?: string
  // Client Fields
  why?: string
  alreadyHasAgent?: string
  interestedInOtherside?: string
  otherSideInfo_fullAddress?: string
  primaryResidence?: string
  buyerProgress?: string
  preapproved?: string
  humanTimeline?: string
  price?: string
  interestedInLoan?: string
  qualificationMethod?: string
  // BBYS Fields
  remainingMortgageBalance?: string
  ageRestrictedCommunity?: string
  isBBYSEligible?: string
  isEligibleForLoan?: boolean
  // Existing Lender
  existingLenderName?: string
  existingLoanOfficerName?: string
  existingLoanOfficerPhone?: string
  existingLoanOfficerEmail?: string
}

type TProps = {
  lender: Lender
  lead: Potential<Lead>
  validateEUValue: boolean
  validateNotes: boolean
  validateMortgageFields?: boolean
  validateClientFields?: boolean
  isBBYSLeadType?: boolean
  validateLoan?: boolean
  validateExistingLender?: boolean
}

export const useLenderDispositionButtonForm = ({
  lender,
  lead,
  validateEUValue,
  validateNotes,
  validateMortgageFields,
  validateClientFields,
  isBBYSLeadType,
  validateLoan,
  validateExistingLender
}: TProps) => {
  const { lenderDisposition: mutation } = useDispositionLenderController(lead?.id)
  const { refetch: refreshLead } = useLeadContext()

  const { isEligible: isEligibleForLoan, isEligibleWoBBYS } = useLoanCalculator(lead as Lead)

  const validateLoanFunc = () => {
    const errors = []
    if ((isBBYSLeadType && !isEligibleForLoan) || (!isBBYSLeadType && !isEligibleWoBBYS)) {
      errors.push('Not eligible for loan')
    }

    return errors
  }

  const formValidations: TFormValidation = {
    buyerState: { label: 'Buyer State', validations: [validatePresence] },
    disposition: { label: 'Disposition', validations: [validatePresence] },
    leadType: { label: 'Lead Type', validations: [validatePresence] },
    ...(validateEUValue
      ? {
          maxEquityUnlock: {
            label: 'Equity Unlock - Max',
            validations: [validatePresence]
          }
        }
      : {}),
    ...(validateEUValue
      ? {
          minEquityUnlock: {
            label: 'Equity Unlock - Min',
            validations: [validatePresence]
          }
        }
      : {}),
    ...(validateNotes ? { note: { label: 'Note', validations: [validatePresence] } } : {}),
    // Validate mortgage Fields
    ...(validateMortgageFields
      ? {
          creditScore: { validations: [validatePresence] },
          hasMinimumDownPayment: { validations: [validatePresence] },
          consistentIncome: { validations: [validatePresence] },
          fhaVaLoan: { validations: [validatePresence] }
        }
      : {}),
    // Validate Client Fields
    ...(validateClientFields
      ? {
          interestedInOtherside: { validations: [validatePresence] },
          ...(isBBYSLeadType
            ? { otherSideInfo_fullAddress: { validations: [validatePresence] } }
            : {}),
          ...(isBBYSLeadType ? { primaryResidence: { validations: [validatePresence] } } : {}),
          buyerProgress: { validations: [validatePresence] },
          preapproved: { validations: [validatePresence] },
          humanTimeline: { validations: [validatePresence] },
          price: { validations: [validatePresence] },
          interestedInLoan: { validations: [validatePresence] }
        }
      : {}),
    // Validate BBYS eligibility
    ...(isBBYSLeadType
      ? { isBBYSEligible: { label: 'BBYS Eligibility', validations: [validateMatch('eligible')] } }
      : {}),
    // Validate Loan eligibility
    ...(validateLoan
      ? { isEligibleForLoan: { label: 'Loan Eligibility', validations: [validateLoanFunc] } }
      : {}),
    // Validate Existing Lender
    ...(validateExistingLender
      ? {
          existingLenderName: { label: 'Existing Lender Name', validations: [validatePresence] },
          existingLoanOfficerName: {
            label: 'Existing Loan Officer Name',
            validations: [validatePresence]
          },
          existingLoanOfficerPhone: {
            label: 'Existing Loan Officer Phone',
            validations: [validatePresence]
          },
          existingLoanOfficerEmail: {
            label: 'Existing Loan Officer Email',
            validations: [validatePresence]
          }
        }
      : {})
  }

  const initialPayload: TLenderDispositionButtonFormPayload = {
    buyerState: lead?.stateCode,
    clientSubmittedForm: '',
    disposition: '',
    lenderId: lender?.id,
    maxEquityUnlock: lead?.callReview?.maxEquityUnlock,
    minEquityUnlock: lead?.callReview?.minEquityUnlock,
    skipAutoIntro: 'false',
    // Mortgage Fields
    needLender: stringifyValue(lead?.callReview?.needLender),
    creditScore: lead?.callReview?.creditScore,
    hasMinimumDownPayment: stringifyValue(lead?.hasMinimumDownPayment),
    consistentIncome: stringifyValue(lead?.callReview?.consistentIncome),
    fhaVaLoan: stringifyValue(lead?.callReview?.fhaVaLoan),
    // Client Fields
    why: lead?.callReview?.why,
    alreadyHasAgent: stringifyValue(lead?.alreadyHasAgent),
    interestedInOtherside: lead?.callReview?.interestedInOtherside,
    otherSideInfo_fullAddress: lead?.callReview?.othersideInfo?.fullAddress,
    primaryResidence: stringifyValue(lead?.callReview?.primaryResidence),
    buyerProgress: lead?.callReview?.buyerProgress,
    preapproved: lead?.callReview?.preapproved,
    humanTimeline: lead?.humanTimeline,
    price: stringifyValue(lead?.price),
    interestedInLoan: stringifyValue(lead?.callReview?.interestedInLoan),
    qualificationMethod: lead?.callReview?.qualificationMethod,
    // BBYS Fields
    remainingMortgageBalance: lead?.callReview?.remainingMortgageBalance,
    ageRestrictedCommunity: stringifyValue(lead?.callReview?.ageRestrictedCommunity),
    isBBYSEligible: 'not_eligible',
    // Loan
    isEligibleForLoan: isEligibleForLoan,
    // Existing Lender
    existingLenderName: lead?.callReview?.existingLenderName,
    existingLoanOfficerName: lead?.callReview?.existingLoanOfficerName,
    existingLoanOfficerPhone: lead?.callReview?.existingLoanOfficerPhone,
    existingLoanOfficerEmail: lead?.callReview?.existingLoanOfficerEmail
  }

  const transformPayload = ({
    buyerState,
    clientSubmittedForm,
    disposition,
    followUpDate,
    maxEquityUnlock,
    minEquityUnlock,
    note,
    leadType,
    skipAutoIntro,
    // Mortgage Fields
    needLender,
    creditScore,
    hasMinimumDownPayment,
    consistentIncome,
    fhaVaLoan,
    // Client Fields
    why,
    alreadyHasAgent,
    interestedInOtherside,
    otherSideInfo_fullAddress,
    primaryResidence,
    buyerProgress,
    preapproved,
    humanTimeline,
    price,
    interestedInLoan,
    qualificationMethod,
    // BBYS Fields
    remainingMortgageBalance,
    ageRestrictedCommunity,
    isBBYSEligible,
    // Loan
    isEligibleForLoan
  }: TLenderDispositionButtonFormPayload) => ({
    buyerState,
    clientSubmittedForm,
    lenderId: lender.id,
    disposition,
    followUpDate,
    maxEquityUnlock,
    minEquityUnlock,
    note,
    leadType,
    skipAutoIntro,
    // Mortgage Fields
    needLender,
    creditScore,
    hasMinimumDownPayment,
    consistentIncome,
    fhaVaLoan,
    // Client Fields
    why,
    alreadyHasAgent,
    interestedInOtherside,
    otherSideInfo_fullAddress,
    primaryResidence,
    buyerProgress,
    preapproved,
    humanTimeline,
    price,
    interestedInLoan,
    qualificationMethod,
    // BBYS Fields
    remainingMortgageBalance,
    ageRestrictedCommunity,
    isBBYSEligible,
    // Loan
    isEligibleForLoan
  })

  const { formProps, fieldsProps } = useButtonForm<TLenderDispositionButtonFormPayload>({
    transformPayload,
    initialPayload,
    formValidations,
    mutation,
    onOpenChange(isOpen) {
      if (!isOpen) {
        refreshLead()
      }
    }
  })

  return {
    formProps,
    fieldsProps
  }
}
