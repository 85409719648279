import { getAgentsUrl } from 'services/getUrl'
import { getRequest } from 'utils'

export const downloadAgentAgreement = async (agentId: string, s3Path: string) => {
  const url = getAgentsUrl({
    id: agentId,
    action: 'agent-agreements/download',
    searchParams: { s3_path: s3Path }
  })

  const response = await getRequest(url)

  return {
    data: response.data
  }
}
