// @ts-strict
import { useMutation, useQuery } from 'hooks'
import { PayPerLeadBillingInfo } from 'models'
import {
  fetchPayPerLeadBillingInfos,
  retryPayPerLeadBillingInfoCycle,
  updatePayPerLeadBillingInfoCycle,
  updatePayPerLeadBillingInfoReleaseCycle
} from 'services'

export const usePayPerLeadBillingInfoController = (
  providerId: string | number,
  providerType: string
) => {
  const payPerLeadBillingInfosQuery = useQuery(
    ['ppl-billing-infos', providerId],
    () => fetchPayPerLeadBillingInfos(providerId, providerType),
    { refetchOnWindowFocus: false }
  )

  const updateBillingCycle = useMutation(
    (payload: {
      billingFrequency: string
      buyerCap: number
      sellerCap: number
      startNow: boolean
    }) => {
      return updatePayPerLeadBillingInfoCycle(
        providerId,
        providerType,
        payload.buyerCap,
        payload.sellerCap,
        payload.billingFrequency,
        payload.startNow
      )
    }
  )

  const releaseBillingCycle = useMutation(() =>
    updatePayPerLeadBillingInfoReleaseCycle(providerId, providerType)
  )

  const retryBillingCycle = useMutation(
    (payload: { payPerLeadBillingInfo: PayPerLeadBillingInfo }) => {
      return retryPayPerLeadBillingInfoCycle(
        providerId,
        providerType,
        payload.payPerLeadBillingInfo
      )
    },
    {
      onSuccess: () => {
        // Should change to hold_pending or payment_processing
        payPerLeadBillingInfosQuery.refetch()

        // Should change to *_failed or active
        setTimeout(() => {
          payPerLeadBillingInfosQuery.refetch()
        }, 4000)
      }
    }
  )

  return {
    payPerLeadBillingInfosQuery,
    updateBillingCycle,
    releaseBillingCycle,
    retryBillingCycle
  }
}
