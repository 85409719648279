// @ts-strict
import { PplBillingInfoResponse } from 'schema'
import { Model } from '../Model'

export class PayPerLeadBillingInfo extends Model {
  id?: string | number
  attemptedBillAmount?: number
  tentativeBillAmount?: number
  billingCycleStartDate?: string
  billingCycleEndDate?: string
  billingFrequency?: string
  buyerMonthlyCap?: number
  monthlyCap?: number
  status?: string
  allowPaymentRetry?: boolean
  lastPaymentMessage?: string

  constructor(pplBillingInfoResponse: PplBillingInfoResponse) {
    super()
    Object.assign(this, pplBillingInfoResponse)
  }

  static create(
    pplBillingInfoResponse: Potential<PplBillingInfoResponse>
  ): Potential<PplBillingInfoResponse> {
    if (!pplBillingInfoResponse) {
      return
    }

    return new PayPerLeadBillingInfo(pplBillingInfoResponse)
  }

  displayStatus() {
    switch (this.status) {
      case 'paid':
        return 'Paid'
      case 'payment_action_required':
        return 'Payment Action Required'
      case 'payment_failed':
        return 'Payment Failed'
      case 'payment_processing':
        return 'Payment Processing'
      case 'active':
        return 'Active'
      case 'hold_pending':
        return 'Hold Pending'
      case 'hold_failed':
        return 'Hold Failed'
      default:
        return 'Unknown'
    }
  }

  displayAmount() {
    if (this.billingCycleEndDate) {
      return Number(this.attemptedBillAmount) / 100
    }
    return Number(this.tentativeBillAmount) / 100
  }

  displayFrequency() {
    if (this.billingFrequency === 'week') {
      return 'Weekly'
    }

    return 'Monthly'
  }
}
