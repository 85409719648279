// @ts-strict
import { useMutation, useQuery, useQueryClient } from 'hooks'
import {
  downloadAgentAgreement,
  fetchAgentAgreements,
  issueAgentAgreement,
  voidAgentAgreement
} from 'services'

type VoidAgreementPayload = {
  agreementId: string
}

type IssueAgentAgreementPayload = {
  agreementId: string
}

export const useAgentAgreementsController = (agentId: string) => {
  const agentAgreementsQuery = useQuery(
    ['agent-agreements', agentId],
    () => fetchAgentAgreements(agentId),
    { refetchOnWindowFocus: false }
  )

  const queryCache = useQueryClient()

  const agentAgreementDownload = useMutation(
    (s3Path: string) => downloadAgentAgreement(agentId, s3Path),
    {
      onSuccess: ({ data }, _id) => {
        if (data.download_url) {
          window.open(data.download_url, '_blank')
        }
      }
    }
  )

  const agentAgreementVoid = useMutation(
    (payload: VoidAgreementPayload) => voidAgentAgreement(agentId, payload),
    {
      onSuccess: () => {
        queryCache.invalidateQueries()
      }
    }
  )

  const agentAgreementIssue = useMutation(
    (payload: IssueAgentAgreementPayload) => issueAgentAgreement(agentId, payload),
    {
      onSuccess: () => {
        queryCache.invalidateQueries()
      }
    }
  )

  return { agentAgreementsQuery, agentAgreementDownload, agentAgreementVoid, agentAgreementIssue }
}
