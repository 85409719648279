import { TColumnAttributes } from '../../Column'

const columns: Record<string, TColumnAttributes> = {
  actions: {
    name: 'Actions',
    value: 'actions'
  },
  assignedCreated: {
    name: 'Assigned/Created',
    value: 'assignedCreated'
  },
  assigneeAssigner: {
    name: 'Assignee/Assigner',
    value: 'assigneeAssigner'
  },
  attachedTo: {
    name: 'Attached to',
    value: 'attachedTo'
  },
  categoryStage: {
    name: 'Category/Stage',
    value: 'categoryStage'
  },
  dueReady: {
    name: 'Due/Ready',
    value: 'dueReady'
  },
  ir: {
    name: 'IR',
    value: 'ir'
  },
  status: {
    name: 'Status',
    value: 'status'
  },
  title: {
    name: 'Title',
    value: 'title'
  },
  irCoeDate: {
    name: 'IR COE Date',
    value: 'irCoeDate'
  },
  sourcePartner: {
    name: 'Partner Source',
    value: 'sourcePartner'
  }
}

export const agentTasksColumnsSettings = [
  columns.actions,
  columns.categoryStage,
  columns.assignedCreated,
  columns.assigneeAssigner,
  columns.attachedTo,
  columns.dueReady,
  columns.status,
  columns.title
] as const

const commonLeadTasksColumnsSettings = [
  columns.actions,
  columns.assignedCreated,
  columns.assigneeAssigner,
  columns.categoryStage,
  columns.dueReady,
  columns.status,
  columns.title
] as const

export const tradeInTasksColumnsSettings = [...commonLeadTasksColumnsSettings] as const

export const bbysTasksColumnsSettings = [
  ...commonLeadTasksColumnsSettings,
  columns.irCoeDate,
  columns.sourcePartner
] as const

export const cashOfferTasksColumnsSettings = [
  ...commonLeadTasksColumnsSettings,
  columns.ir
] as const

export const pageTasksColumnsSettings = [
  columns.assignedCreated,
  columns.assigneeAssigner,
  columns.attachedTo,
  columns.categoryStage,
  columns.dueReady,
  columns.status,
  columns.title
] as const

export const pageTasksWithIrCoeDateColumnsSettings = [
  ...pageTasksColumnsSettings,
  columns.irCoeDate,
  columns.sourcePartner
] as const
export type TAgentTasksColumnValue = (typeof agentTasksColumnsSettings)[number]['value']
