// @ts-strict
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Text } from '@foundation/components'
import {
  Divider,
  FileInputButton,
  MapboxAutoComplete,
  MapboxAutocompleteResponseItem,
  Section
} from 'components'
import { IS_ZIP_CODE, PLACE_TYPES } from '../constants'

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  padding-top: 16px;
`

type Props = {
  className?: string
  onSelect: (slug: string[] | string, prices?: number[] | number) => void
  placeholder?: string
  providerType: 'Agent' | 'Investor'
  value?: string
}

export const SearchLocation = ({ onSelect, providerType, value }: Props) => {
  const [currentValue, setCurrentValue] = useState(value || '')
  const [searchType, setSearchType] = useState('place')

  useEffect(() => {
    if (value) {
      setCurrentValue(value)
    }
  }, [value])

  useEffect(() => {
    const newSearchType =
      providerType === 'Investor' || IS_ZIP_CODE(currentValue)
        ? PLACE_TYPES.ZIP_CODE
        : PLACE_TYPES.PLACE

    setSearchType(newSearchType)
  }, [currentValue, providerType])

  const handleOnSelect = (selectedValue: MapboxAutocompleteResponseItem) => {
    if (selectedValue) {
      onSelect(selectedValue.place.slug!)
    }

    setCurrentValue('')
  }

  const removeValue = () => {
    setCurrentValue('')
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') {
      return
    }

    e.preventDefault()
    const inputValues = currentValue.split(',').map(val => val.trim())
    const isAllZipCodes = inputValues.every(val => IS_ZIP_CODE(val as string))

    if (isAllZipCodes) {
      onSelect(inputValues)
      setCurrentValue('')
    }
  }

  const handleFileUpload = (files: File[]) => {
    const reader = new FileReader()
    reader.onload = e => {
      const csv: string = e.target?.result as string

      const rows: string[] = csv.split('\n').map(row => row.trim())

      const csvContent: string[][] = rows.map(row => row.split(',').map(item => item.trim()))

      const headerRow: string[] = csvContent[0]

      const locationIndex: number = headerRow.findIndex(item => item.toLowerCase() === 'location')
      const priceIndex: number = headerRow.findIndex(item => item.toLowerCase() === 'price')

      const locations: string[] = csvContent.slice(1).map(row => row[locationIndex])
      const prices: number[] = csvContent.slice(1).map(row => parseFloat(row[priceIndex]) * 100)

      onSelect(locations, prices)
    }

    try {
      reader.readAsText(files[0])
    } catch (error) {
      console.error('Error reading file', error)
    }
  }

  return (
    <SearchWrapper>
      <MapboxAutoComplete
        data-testid="cityState-auto-complete"
        onSelect={handleOnSelect}
        types={searchType}
        value={currentValue}
        onClear={removeValue}
        onKeyDown={onKeyDown}
        onChange={val => setCurrentValue(val)}
        placeholder="Search location"
      />
      <Divider as="vertical" />
      <Section>
        <FileInputButton onUpload={handleFileUpload} title="Upload file" accept=".csv" />
        <Text size="xs" color="neutral9">
          Upload a csv with columns "Location" and "Price"
        </Text>
      </Section>
    </SearchWrapper>
  )
}
