import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import _ from 'lodash'
import { useSearchParams } from 'react-router-dom'
import { Box, Button, Flex, Icon, Popover } from '@foundation/components'
import { Option } from 'classes'
import { Divider } from '../../../components'
import { formatNumber } from '../../utilities/formatNumber'
import { SearchInput } from '../SearchInput'
import styles from './Table.module.scss'
import {
  AppliedFilterItem,
  ClearButton,
  PaginationButtonIcon,
  SortArrowStyles,
  TableActionsStyles,
  TableDetailsStyles,
  TableEmptyContainer,
  TableHeadingStyles,
  TablePaginationButton,
  TablePaginationStyles,
  TableRootStyles,
  TableRowSkeleton,
  TableStyles,
  TableViewStyles,
  TD,
  TH
} from './styles'
// ========================================================================
// useFixedColumns Hook

const useFixedColumns = () => {
  const [leftPositions, setLeftPositions] = useState<number[]>([])
  const tableRef = useRef<HTMLTableElement>(null)

  useEffect(() => {
    const calculateLeftPositions = () => {
      const table = tableRef.current

      if (table) {
        const thElements = table.querySelectorAll('th.fixed-column')
        const elementsWidths = Array.from(thElements).map(th => th.getBoundingClientRect().width)
        const cumulativePositions = elementsWidths.reduce((acc, width) => {
          acc.push(acc.length === 0 ? 0 : acc[acc.length - 1] + width)
          return acc
        }, [] as number[])
        setLeftPositions(cumulativePositions)
      }
    }

    calculateLeftPositions()
    window.addEventListener('resize', calculateLeftPositions)

    return () => {
      window.removeEventListener('resize', calculateLeftPositions)
    }
  }, [])

  return { tableRef, leftPositions }
}

// ========================================================================
// useTable Hook

export type FilterValues = Record<string, string[]>
export type FiltersObj = Record<string, Option<string>[]>

export const useTable = (defaultColumns: TColumn[] = []) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false)
  const [params, setParams] = useSearchParams({
    page: '1'
  })

  const [isColumnsControlOpen, setIsColumnsControlOpen] = useState<boolean>(false)
  const [columns, setColumns] = useState(defaultColumns)

  useEffect(() => {
    const columnsUrlParam = params.get('columns')

    if (!columnsUrlParam) {
      return
    }

    let columns = JSON.parse(columnsUrlParam)

    if (columns && Array.isArray(columns)) {
      columns = _.uniq([
        ...defaultColumns.filter(c => c.frozen).map(c => c.label),
        ...columns
      ]) as string[]

      setColumns(
        columns.reduce((acc: TColumn[], col: string) => {
          const defaultCol = defaultColumns.find(defCol => defCol.label === col)

          if (defaultCol) {
            acc.push({
              ...defaultCol,
              display: true
            })
          }

          return acc
        }, [] as TColumn[])
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultColumns])

  const setColumnsAndSaveToUrl = (updatedColumns: TColumn[]) => {
    setParams(
      prev => {
        prev.set(
          'columns',
          JSON.stringify(updatedColumns.filter(col => col.display).map(column => column.label))
        )

        return prev
      },
      { replace: true }
    )

    setColumns(updatedColumns)
  }

  const appliedParams = useMemo(() => {
    const search = params.get('search')
    const sortBy = params.get('sortBy')
    const page = params.get('page')
    const filtersStr = params.get('filters')
    const filters = JSON.parse(filtersStr || '{}') as FiltersObj

    const filterValues: FilterValues = Object.entries(filters || {}).reduce(
      (obj, [key, values]) => ({ ...obj, [key]: values.map(({ value }) => value) }),
      {}
    )

    return {
      search: search === 'null' ? null : search,
      sortBy: sortBy === 'null' ? null : sortBy,
      filters: filtersStr === 'null' ? null : filters,
      filterValues,
      page
    }
  }, [params])

  const onSearch = (value: string) => {
    setParams(
      prev => {
        if (value) {
          prev.set('search', value)
          prev.set('page', '1')
        } else {
          prev.delete('search')
        }
        return prev
      },
      { replace: true }
    )
  }

  const onFilter = (filtersObj: FiltersObj) => {
    setIsFiltersOpen(false)
    setParams(
      prev => {
        if (!Object.keys(filtersObj).length) {
          prev.delete('filters')
        } else {
          prev.set('filters', JSON.stringify(filtersObj))
        }
        return prev
      },
      { replace: true }
    )
  }

  const removeFilter = (key: string, filter: Option<string>) => {
    setParams(
      prev => {
        const filtersStr = prev.get('filters')
        const filtersObj: FiltersObj = filtersStr ? JSON.parse(filtersStr) : {}
        const filterValues = filtersObj[key].filter(f => f.value !== filter.value)

        if (!filterValues.length) {
          delete filtersObj[key]
        } else {
          filtersObj[key] = filterValues
        }

        prev.set('filters', JSON.stringify(filtersObj))
        return prev
      },
      { replace: true }
    )
  }

  const removeAllFilters = () => {
    setParams(
      prev => {
        prev.delete('filters')
        return prev
      },
      { replace: true }
    )
  }

  const removeSorting = () => {
    setParams(
      prev => {
        prev.delete('sortBy')
        return prev
      },
      { replace: true }
    )
  }

  const onSort = (sortKey: string, dir: 'reverse' | 'up' | 'down' = 'reverse') => {
    setParams(
      prev => {
        if (dir === 'reverse') {
          const prevSort = prev.get('sortBy')
          const prevSortKey = prevSort?.startsWith('-') ? prevSort.split('-')[1] : prevSort

          if (prevSortKey === sortKey) {
            const isDesc = prevSort?.startsWith('-')

            if (isDesc) {
              prev.delete('sortBy')
            } else {
              prev.set('sortBy', `-${sortKey}`)
            }
          } else {
            prev.set('sortBy', sortKey)
          }
        } else if (dir === 'up') {
          prev.set('sortBy', sortKey)
        } else if (dir === 'down') {
          prev.set('sortBy', `-${sortKey}`)
        }

        return prev
      },
      { replace: true }
    )
  }

  const onNavigate = (nextPage: string) => {
    setParams(
      prev => {
        prev.set('page', nextPage)
        return prev
      },
      { replace: true }
    )
  }

  const resetState = () => {
    setParams(
      prev => {
        prev.delete('search')
        prev.delete('sortBy')
        prev.delete('filters')
        prev.set('page', '1')
        return prev
      },
      { replace: true }
    )
  }

  return {
    onSearch,
    onFilter,
    removeFilter,
    removeAllFilters,
    onSort,
    removeSorting,
    onNavigate,
    isFiltersOpen,
    setIsFiltersOpen,
    resetState,
    columns,
    setColumns: setColumnsAndSaveToUrl,
    isColumnsControlOpen,
    setIsColumnsControlOpen,
    ...appliedParams
  }
}

// ========================================================================
// Root

export type TableContextProps = ReturnType<typeof useTable>

const TableContext = createContext<TableContextProps | undefined>(undefined)

export const useTableContext = () => {
  const ctx = useContext(TableContext)
  if (!ctx) {
    throw new Error('Missing Table.Root')
  }
  return ctx
}

type RootProps = PropsWithChildren<TableContextProps>

const Root = ({ children, ...props }: RootProps) => {
  return (
    <TableContext.Provider value={props}>
      <TableRootStyles>{children}</TableRootStyles>
    </TableContext.Provider>
  )
}

// ========================================================================
// View

const View = ({ children }: PropsWithChildren<{}>) => <TableViewStyles>{children}</TableViewStyles>

// ========================================================================
// Heading

type HeadingProps = PropsWithChildren<{
  subtitle?: string
  title: string
}>

const Heading = ({ children, title, subtitle }: HeadingProps) => (
  <TableHeadingStyles>
    <div>
      <h1>{title}</h1>
      {subtitle && <p>{subtitle}</p>}
    </div>
    {children && (
      <Flex alignItems="center" gap="$4">
        {children}
      </Flex>
    )}
  </TableHeadingStyles>
)

// ========================================================================
// Actions

type ActionsProps = {
  renderColumns?: () => JSX.Element
  renderFilters?: () => JSX.Element
  renderSearch?: () => JSX.Element
  searchPlaceholder?: string
}
const Actions = ({
  searchPlaceholder,
  renderSearch,
  renderFilters,
  renderColumns
}: ActionsProps) => {
  const {
    sortBy,
    removeSorting,
    isFiltersOpen,
    setIsFiltersOpen,
    onSearch,
    search,
    filters,
    removeFilter,
    removeAllFilters,
    isColumnsControlOpen,
    setIsColumnsControlOpen
  } = useTableContext()

  const hasFilters = filters && Object.entries(filters).length > 0
  const hasSorting = !!sortBy

  return (
    <TableActionsStyles>
      <Flex justifyContent="space-between" alignItems="center" gap="$4">
        {renderSearch ? (
          renderSearch()
        ) : (
          <SearchInput placeholder={searchPlaceholder} value={search || ''} onChange={onSearch} />
        )}
        <Flex gap="$4">
          <Flex gap="$4">
            {renderFilters && (
              <Popover.Root
                isOpen={isFiltersOpen}
                onOpenChange={isOpen => setIsFiltersOpen(isOpen)}
                placement="bottom-end"
              >
                <Popover.Trigger>
                  <span>
                    <Button iconRight="FilterAltOutlined" variant="outline" color="neutralLight">
                      Filters
                    </Button>
                  </span>
                </Popover.Trigger>
                <Popover.Content>{renderFilters()}</Popover.Content>
              </Popover.Root>
            )}
          </Flex>
          {renderColumns && (
            <Flex gap="$4">
              <Popover.Root
                isOpen={isColumnsControlOpen}
                onOpenChange={isOpen => setIsColumnsControlOpen(isOpen)}
                placement="bottom-end"
              >
                <Popover.Trigger>
                  <Button iconRight="TableChartOutlined" variant="outline" color="neutralLight">
                    Columns
                  </Button>
                </Popover.Trigger>
                <Popover.Content>{renderColumns()}</Popover.Content>
              </Popover.Root>
            </Flex>
          )}
        </Flex>
      </Flex>
      {(hasFilters || hasSorting) && (
        <Box marginTop="$4">
          <Flex gap="$8" alignItems="center">
            {hasFilters && (
              <Flex gap="$2" alignItems="center" flexWrap="wrap">
                {Object.entries(filters).map(([key, filtersArr]) => (
                  <Flex alignItems="center" gap="$2" key={key} flexWrap="wrap">
                    {filtersArr.map(filter => (
                      <AppliedFilterItem
                        key={filter.value}
                        onClick={() => removeFilter(key, filter)}
                      >
                        {key === 'days_since_ir_coe' && 'Days since IR COE: '}
                        {key === 'days_since_eu_funding' && 'Days since EU Funding: '}
                        {filter.name}
                        <Icon path="Close" size="xs" />
                      </AppliedFilterItem>
                    ))}
                  </Flex>
                ))}
                <ClearButton onClick={removeAllFilters}>Clear Filters</ClearButton>
              </Flex>
            )}
            {sortBy && (
              <Flex gap="$2" alignItems="center">
                <AppliedFilterItem>Sorting</AppliedFilterItem>
                <ClearButton onClick={removeSorting}>Clear Sorting</ClearButton>
              </Flex>
            )}
          </Flex>
        </Box>
      )}
    </TableActionsStyles>
  )
}

// ========================================================================
// Data

type SortArrowProps = {
  isActive?: boolean
  isDesc?: boolean
}
const SortArrow = ({ isActive, isDesc }: SortArrowProps) => (
  <SortArrowStyles isActive={isActive} isDesc={isDesc}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00065 3.3335V12.6668M8.00065 12.6668L12.6673 8.00016M8.00065 12.6668L3.33398 8.00016"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SortArrowStyles>
)

const SortPopover = ({ col }: any) => {
  const { onSort, sortBy, setColumns, columns } = useTableContext()
  const [isSortPopoverOpen, setIsSortPopoverOpen] = useState<boolean>(false)

  const isColumnActive = (sortKey: string) => {
    return sortBy === sortKey || sortBy === `-${sortKey}`
  }

  const isReverse =
    // these two columns are sorted by date but displayed as the number of days since
    [
      'bbys.loan_funding_date',
      'bbys.incoming_residenced_expected_close_escrow_date',
      'furthest_stage'
    ].includes(col.sortKey) || col.sortType === 'text'

  const renderSortIcon = (sortKey: string) => {
    const isDesc = sortBy?.startsWith('-')

    if (isColumnActive(sortKey)) {
      return <SortArrow isActive isDesc={isReverse ? !isDesc : isDesc} />
    }
    return <SortArrow />
  }

  let upText, downText

  switch (col.sortType) {
    case 'date':
      downText = 'Newest to Oldest'
      upText = 'Oldest to Newest'
      break
    case 'text':
      downText = 'A-Z'
      upText = 'Z-A'
      break
    case 'stage':
      downText = 'First to Last'
      upText = 'Last to First'
      break
    case 'number':
    default:
      downText = 'Highest to Lowest'
      upText = 'Lowest to Highest'
      break
  }

  const isHighToLowButtonActive = isReverse ? sortBy === col.sortKey : sortBy === `-${col.sortKey}`
  const isLowToHighButtonActive = isReverse ? sortBy === `-${col.sortKey}` : sortBy === col.sortKey

  const handleHighToLowSort = () => {
    if (isReverse) {
      onSort(col.sortKey, 'up')
    } else {
      onSort(col.sortKey, 'down')
    }
    setIsSortPopoverOpen(false)
  }

  const handleLowToHighSort = () => {
    if (isReverse) {
      onSort(col.sortKey, 'down')
    } else {
      onSort(col.sortKey, 'up')
    }
    setIsSortPopoverOpen(false)
  }

  const handleHideColumn = () => {
    setColumns(
      columns.map(c => ({
        ...c,
        display: c.label === col.label ? false : c.display
      }))
    )
    setIsSortPopoverOpen(false)
  }

  return (
    <Popover.Root
      placement="bottom-start"
      isOpen={col.sortKey ? isSortPopoverOpen : false}
      onOpenChange={setIsSortPopoverOpen}
    >
      <Popover.Trigger>
        <div className={styles.sortPopover}>
          {col.label}
          {col.sortKey && renderSortIcon(col.sortKey)}

          <div className={styles.sortPopoverOverlay} />
        </div>
      </Popover.Trigger>
      <Popover.Content>
        <Flex flexDirection="column">
          <Button
            variant={isHighToLowButtonActive ? 'solid' : 'ghost'}
            color="neutralLight"
            onClick={handleHighToLowSort}
          >
            <div className={styles.sortButton}>
              <SortArrow isDesc isActive={isHighToLowButtonActive} />
              {downText}
            </div>
          </Button>
          <Button
            variant={isLowToHighButtonActive ? 'solid' : 'ghost'}
            color="neutralLight"
            onClick={handleLowToHighSort}
          >
            <div className={styles.sortButton}>
              <SortArrow isActive={isLowToHighButtonActive} />
              {upText}
            </div>
          </Button>
          <Divider />
          <Button variant="ghost" color="neutralLight" onClick={handleHideColumn}>
            <div className={styles.sortButton}>
              <Icon path="VisibilityOutlined" />
              Hide column
            </div>
          </Button>
        </Flex>
      </Popover.Content>
    </Popover.Root>
  )
}

export type TColumn<TData extends Record<string, any> = Record<string, any>> = {
  display?: boolean
  formatter?: (row: TData) => React.ReactNode
  frozen?: boolean
  label: string
  minSize?: string
  render: (row: TData) => React.ReactNode
  sortKey?: string
  sortType?: string
}

type DataProps<TRow extends Record<string, any>> = {
  columns: TColumn<TRow>[]
  isLoading?: boolean
  rows: TRow[]
}

const Data = <TRow extends Record<string, any>>(props: DataProps<TRow>) => {
  const { tableRef, leftPositions } = useFixedColumns()
  const { sortBy } = useTableContext()

  const isColumnActive = (sortKey: string) => {
    return sortBy === sortKey || sortBy === `-${sortKey}`
  }

  const renderBody = () => {
    if (props.isLoading) {
      return Array(10)
        .fill(0)
        .map((_, i) => (
          <tr key={i}>
            {props.columns.map((col, j) => (
              <TD
                key={j}
                isActive={isColumnActive(col.sortKey || '')}
                style={{ left: col.frozen ? leftPositions[j] : undefined }}
                className={col.frozen ? 'fixed-column' : ''}
              >
                <TableRowSkeleton />
              </TD>
            ))}
          </tr>
        ))
    }

    return props.rows.map((row, i) => (
      <tr key={i}>
        {props.columns.map((col, j) => (
          <TD
            key={j}
            isActive={isColumnActive(col.sortKey || '')}
            style={{ left: col.frozen ? leftPositions[j] : undefined }}
            isLastFrozenCol={j === leftPositions.length - 1}
            className={col.frozen ? 'fixed-column' : ''}
          >
            {col.render(row) || '---'}
          </TD>
        ))}
      </tr>
    ))
  }

  if (props.rows.length === 0 && !props.isLoading) {
    return (
      <TableEmptyContainer>
        <img src="/images/empty-table.png" alt="empty table" />
        <p>Sorry, no leads found</p>
      </TableEmptyContainer>
    )
  }

  return (
    <TableStyles>
      <table ref={tableRef} cellSpacing="0">
        <thead>
          <tr>
            {props.columns.map((col, i) => (
              <TH
                className={col.frozen ? 'fixed-column' : ''}
                style={{
                  left: col.frozen ? leftPositions[i] : undefined
                }}
                isLastFrozenCol={i === leftPositions.length - 1}
                isSortable={!!col.sortKey}
                isActive={isColumnActive(col.sortKey || '')}
                minSize={col.minSize}
                key={i}
              >
                <Flex gap="5px" alignItems="center">
                  <SortPopover col={col} />
                </Flex>
              </TH>
            ))}
          </tr>
        </thead>
        <tbody>{renderBody()}</tbody>
      </table>
    </TableStyles>
  )
}

// ========================================================================
// Details

type DetailsProps = {
  currentPage: number
  isLoading?: boolean
  itemsPerPage: number
  totalLeads: number
  totalPages: number
}

const Details = ({
  itemsPerPage,
  currentPage,
  totalPages,
  totalLeads,
  isLoading
}: DetailsProps) => {
  const { start, end } = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage + 1
    let end = currentPage * itemsPerPage

    end = end > totalPages ? totalPages : end
    return { start, end }
  }, [itemsPerPage, currentPage, totalPages])

  return (
    <TableDetailsStyles>
      {isLoading ? (
        <TableRowSkeleton width="120px" />
      ) : (
        <p>
          {start} - {end} <span>of {formatNumber(totalLeads)}</span>
        </p>
      )}
    </TableDetailsStyles>
  )
}

// ========================================================================
// Pagination

const PaginationIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3467 12.2656L10.76 16.8899C10.6131 17.0367 10.3756 17.0367 10.2288 16.8899L9.61014 16.2712C9.46329 16.1244 9.46329 15.8869 9.61014 15.74L13.3127 12L9.61014 8.25996C9.46329 8.11311 9.46329 7.87564 9.61014 7.72879L10.2288 7.11014C10.3756 6.96329 10.6131 6.96329 10.76 7.11014L15.3467 11.7344C15.4936 11.8813 15.4936 12.1187 15.3467 12.2656Z"
      fill="currentColor"
    />
  </svg>
)

type PaginationProps = {
  currentPage: number
  onPageChange: (page: number) => void
  totalPages: number
}

const Pagination = ({ currentPage, totalPages, onPageChange }: PaginationProps) => {
  const getPageNumbers = () => {
    const pageNumbers = []
    const visiblePages = 5
    let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2))
    let endPage = Math.min(totalPages, startPage + visiblePages - 1)

    if (totalPages <= visiblePages) {
      startPage = 1
      endPage = totalPages
    } else {
      if (endPage - startPage < visiblePages - 1) {
        startPage = endPage - visiblePages + 1
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i)
    }

    return pageNumbers
  }

  const handlePageChange = (page: number) => {
    if (page !== currentPage && page > 0 && page <= totalPages) {
      onPageChange(page)
    }
  }

  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === totalPages

  return (
    <TablePaginationStyles>
      <PaginationButtonIcon
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={isFirstPage}
        left
      >
        <PaginationIcon />
      </PaginationButtonIcon>

      {getPageNumbers().map(pageNumber => (
        <TablePaginationButton
          key={pageNumber}
          onClick={() => handlePageChange(pageNumber)}
          isActive={pageNumber === currentPage}
          disabled={pageNumber === currentPage}
        >
          {pageNumber}
        </TablePaginationButton>
      ))}
      <span>...</span>
      <TablePaginationButton onClick={() => handlePageChange(totalPages)} disabled={isLastPage}>
        {totalPages}
      </TablePaginationButton>

      <PaginationButtonIcon onClick={() => handlePageChange(currentPage + 1)} disabled={isLastPage}>
        <PaginationIcon />
      </PaginationButtonIcon>
    </TablePaginationStyles>
  )
}

// ========================================================================
// Table

export const Table = {
  Root,
  View,
  Heading,
  Actions,
  Data,
  Details,
  Pagination
}
