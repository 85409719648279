import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient, useSavedSearches } from 'hooks'
import type { PutUserPayload } from 'services'
import {
  deleteImpersonateUser,
  postImpersonateEmployee,
  postImpersonateUser,
  putUser
} from 'services'

export const useUserController = (userId: string) => {
  const queryCache = useQueryClient()
  const navigate = useNavigate()
  const { clear: clearSavedSearches } = useSavedSearches()

  const clearQueries = () => {
    queryCache.invalidateQueries()
    clearSavedSearches()
  }

  const userUpdate = useMutation((payload: PutUserPayload) => putUser(userId, payload), {
    onSuccess: () => {
      queryCache.invalidateQueries()
    }
  })

  const userImpersonate = useMutation<
    { data: { redirect_url?: string } },
    unknown,
    { customRedirectUrl?: string } | undefined
  >(() => postImpersonateUser(userId), {
    onSuccess: ({ data }, variables = {}) => {
      clearQueries()

      const redirectUrl = variables.customRedirectUrl || data.redirect_url
      if (redirectUrl) {
        window.location.href = redirectUrl
      }
    }
  })

  const employeeImpersonate = useMutation(() => postImpersonateEmployee(userId), {
    onSuccess: (res, id) => {
      clearQueries()
      navigate('/')
    }
  })

  const employeeUnimpersonate = useMutation(deleteImpersonateUser, {
    onSuccess: () => {
      clearQueries()
    }
  })

  return { userImpersonate, userUpdate, employeeImpersonate, employeeUnimpersonate }
}
