// @ts-strict
import { Flex, Text } from '@foundation/components'
import { Badge } from 'components'
import { Investor } from 'models'
import { dataOrDash } from 'utils'
import { formatDate } from 'utils'
import styles from './InvestorBilling.module.scss'
import { SetupPayPerLeadSettingsButtonForm } from './PayPerLeadSettings'

type InvestorBillingStatusProps = {
  investor: Investor
}

const InvestorBillingStatus = ({ investor }: InvestorBillingStatusProps) => {
  const hasProviderSettings = investor.pplProviderSettings && investor.pplProviderSettings.length
  const hasBillingInfos = investor.pplBillingInfos && investor.pplBillingInfos.length

  if (hasProviderSettings && hasBillingInfos) {
    return <PplActive investor={investor} />
  }

  if (hasProviderSettings && !hasBillingInfos) {
    return <PplPending investor={investor} />
  }

  if (!hasProviderSettings && !hasBillingInfos) {
    return <RevShareActive investor={investor} />
  }

  return null
}

const ActiveBadge = () => (
  <Badge title="Active" className={styles.activeBadge}>
    Active
  </Badge>
)

const InactiveBadge = () => (
  <Badge title="Inactive" className={styles.inactiveBadge}>
    Inactive
  </Badge>
)

const WaitingBadge = () => (
  <Badge title="Waiting" className={styles.waitingBadge}>
    Waiting on Investor
  </Badge>
)

const RevShareActive = ({ investor }: { investor: Investor }) => {
  return (
    <Flex gap="$6" flexDirection="column">
      <RevShareSection investor={investor} isActive={true} />

      <Flex gap="$3" flexDirection="column">
        <Flex justifyContent="space-between" alignItems="center" gap="$3">
          <Text size="sm" weight="bold">
            Pay-Per-Lead
          </Text>
          <SetupPayPerLeadSettingsButtonForm investor={investor} />
        </Flex>
      </Flex>
    </Flex>
  )
}

const PplPending = ({ investor }: { investor: Investor }) => {
  return (
    <Flex gap="$6" flexDirection="column">
      <RevShareSection investor={investor} isActive={true} />
      <Flex gap="$3" flexDirection="column">
        <Flex justifyContent="normal" gap="$3">
          <Text size="sm" weight="bold">
            Pay-Per-Lead
          </Text>
          <WaitingBadge />
        </Flex>
      </Flex>
    </Flex>
  )
}

const PplActive = ({ investor }: { investor: Investor }) => {
  const firstPplBillingInfo = investor.firstPplBillingInfo()

  return (
    <Flex gap="$6" flexDirection="column">
      <Flex flexDirection="column" justifyContent="normal" gap="$1">
        <Flex gap="$3">
          <Text size="sm" weight="bold">
            Pay-Per-Lead
          </Text>
          <ActiveBadge />
        </Flex>
        {firstPplBillingInfo && (
          <Text size="xs">{`Active since ${formatDate(
            firstPplBillingInfo.billingCycleStartDate
          )}`}</Text>
        )}
      </Flex>
      <RevShareSection investor={investor} isActive={false} />
    </Flex>
  )
}

const RevShareSection = ({ investor, isActive }: { investor: Investor; isActive: boolean }) => (
  <Flex flexDirection="column" justifyContent="normal" gap="$1">
    <Flex gap="$3">
      <Text size="sm" weight="bold">
        Rev Share
      </Text>
      {isActive ? <ActiveBadge /> : <InactiveBadge />}
    </Flex>
    <Text size="xs">{`${dataOrDash(investor.email)}`}</Text>
  </Flex>
)

export default InvestorBillingStatus
