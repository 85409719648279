// @ts-strict

import { Link } from 'react-router-dom'
import { TableActions, TableCell, TableRow } from 'components'
import { DeleteIconButton } from 'components/Button/IconButton'
import { useTeamContext } from 'contexts'
import { useTransactionTeams } from 'lookups'
import { TeamMembership } from 'models'

type TProps = {
  member?: TeamMembership
}

export const TransactionTeamMembersListRow = ({ member }: TProps) => {
  const { findLeadTeamRole } = useTransactionTeams()

  const { removeTeamMember } = useTeamContext()

  if (!member) {
    return null
  }

  const handleRemoveTeamMember = () => {
    removeTeamMember.mutate(member.id)
  }
  const role = findLeadTeamRole(member.role)

  return (
    <TableRow key={member.id} data-member-id={member.id} className="member">
      <TableCell>
        <Link to={`/admin/employees/${member?.user?.id}`}>{member?.user?.name}</Link>
      </TableCell>
      <TableCell>
        {role?.description} {role?.name ? `(${role?.name})` : <em>deprecated role</em>}
      </TableCell>
      <TableActions>
        <DeleteIconButton onClick={handleRemoveTeamMember} />
      </TableActions>
    </TableRow>
  )
}
