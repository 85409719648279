// @ts-strict
import { useMutation, useQuery, useQueryClient } from 'hooks'
import { fetchAgentPayPerLeadReferrals, updateAgentPayPerLeadReferral } from 'services'

export const useAgentPayPerLeadReferralsController = (
  agentId: string | number,
  referralId?: string | number,
  providerType?: string
) => {
  const queryCache = useQueryClient()
  const clearCaches = () => {
    queryCache.invalidateQueries(['current-billing-referrals', agentId])
    queryCache.invalidateQueries(['past-billing-referrals', agentId])
  }

  const currentPayPerLeadReferralsQuery = useQuery(
    ['current-billing-referrals', agentId],
    () => fetchAgentPayPerLeadReferrals(agentId, true, providerType),
    { refetchOnWindowFocus: false }
  )

  const pastPayPerLeadReferralsQuery = useQuery(
    ['past-billing-referrals', agentId],
    () => fetchAgentPayPerLeadReferrals(agentId, false, providerType),
    { refetchOnWindowFocus: false }
  )

  const creditAgentPayPerLeadReferral = useMutation(
    (payload: { notes: string }) =>
      updateAgentPayPerLeadReferral(agentId, 'credit', payload.notes, referralId, providerType),
    {
      onSuccess: clearCaches
    }
  )

  const refundAgentPayPerLeadReferral = useMutation(
    (payload: { notes: string }) =>
      updateAgentPayPerLeadReferral(agentId, 'refund', payload.notes, referralId, providerType),
    {
      onSuccess: clearCaches
    }
  )

  return {
    currentPayPerLeadReferralsQuery,
    pastPayPerLeadReferralsQuery,
    creditAgentPayPerLeadReferral,
    refundAgentPayPerLeadReferral,
    clearCaches
  }
}
