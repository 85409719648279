import { useState } from 'react'
import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { Button, Flex, Popover, useToastContext } from '../../../../@foundation/components'
import { RadioTabs } from '../../../../components'
import { deserializeAsync, putRequest } from '../../../../utils'
import { BASE_URL, GET_TRANSACTION_OPERATORS_LIST } from '../../pages'
import { TransactionOperatorGroup } from '../../pages/types'
import { TeamsAutocomplete } from '../teams-autocomplete/teams-autocomplete'
import { UsersAutocomplete } from '../users-autocomplete/users-autocomplete'
import { PopoverContentContainer } from './styles'

type AddTransactionGroupOperatorProps = {
  groupId: string
}

export const AddOperatorPopover = ({ groupId }: AddTransactionGroupOperatorProps) => {
  const queryClient = useQueryClient()
  const toast = useToastContext()

  const [isOpen, setIsOpen] = useState(false)
  const [selectedOperator, setSelectedOperator] = useState(null)
  const [selectedTypeTab, setOperatorType] = useState<'user' | 'team'>('user')

  const addOperatorRequest = async (id: string, type: string) => {
    return putRequest(
      `${BASE_URL}/lead-data-service/transaction-operator-groups/${groupId}/add-operator?include=users,teams`,
      {
        operator_id: id,
        operator_type: type
      }
    )
  }

  const { mutate, isLoading } = useMutation(
    ({ id, operatorType }: { id: string; operatorType: string }) =>
      addOperatorRequest(id, operatorType),
    {
      onSuccess: async updatedTeam => {
        const deserializedTeam = await deserializeAsync(updatedTeam.data)

        queryClient.setQueryData(
          [GET_TRANSACTION_OPERATORS_LIST],
          (oldData: TransactionOperatorGroup[] | undefined) => {
            if (!oldData) {
              return []
            }

            return oldData.map(team => (team.id === deserializedTeam.id ? deserializedTeam : team))
          }
        )
        setIsOpen(false)
        setSelectedOperator(null)
      },
      onError: (error: AxiosError) => {
        toast({
          title: `Oops! Something went wrong. ${error.response.data.errors}`,
          status: 'error'
        })
      }
    }
  )

  return (
    <Popover.Root placement="bottom-end" isOpen={isOpen} onOpenChange={setIsOpen}>
      <Popover.Trigger>
        <Button>Add operator</Button>
      </Popover.Trigger>
      <Popover.Content>
        <PopoverContentContainer>
          <Flex flexDirection="column" gap="$6">
            <RadioTabs
              tabClassName="tab"
              data-testid="operator-type-field"
              value={selectedTypeTab}
              onChange={(val: 'user' | 'team') => setOperatorType(val)}
              options={[
                {
                  label: 'User',
                  value: 'user'
                },
                {
                  label: 'Team',
                  value: 'team'
                }
              ]}
            />

            {selectedTypeTab === 'user' && (
              <UsersAutocomplete onSelect={user => setSelectedOperator(user?.id)} />
            )}

            {selectedTypeTab === 'team' && (
              <TeamsAutocomplete onSelect={user => setSelectedOperator(user?.id)} />
            )}

            <Button
              onClick={() => mutate({ id: selectedOperator, operatorType: selectedTypeTab })}
              isLoading={isLoading}
            >
              Add new operator
            </Button>
          </Flex>
        </PopoverContentContainer>
      </Popover.Content>
    </Popover.Root>
  )
}
