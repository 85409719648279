// @ts-strict
import { Text } from '@foundation/components'
import {
  ExternalLink,
  FormField,
  InlineEditMoneyInput,
  InlineEditPhoneInput,
  InlineEditSelect,
  InlineEditTextInput,
  Section,
  Sections
} from 'components'
import { useCurrentUserContext } from 'contexts'
import { useLeadPhoneNumberController } from 'controllers'
import { environment } from 'environment'
import { useFeatureFlags } from 'hooks'
import {
  agentMatchingCallReviewAgentCommunicationPreference,
  agentMatchingLeadTimelineOptions,
  aiBotExitFlags,
  dialerQueueOptions,
  yesOrNoOptions
} from 'lookups'
import { BuyerLead, isSellerLead, PhoneNumber, SellerLead } from 'models'
import {
  d,
  dataOrDash,
  DEFAULT_STRING,
  formatDate,
  stringifyValue,
  titleize,
  validateEmailFormat,
  validatePhoneFormat
} from 'utils'
import { getIsValid } from 'utils/validations/validate'
import styles from '../AgentMatchingLead.module.scss'
import { ResolvePendingReviewButtonForm } from '../ResolvePendingReviewButtonForm'
import * as S from '../styles'

type Props = {
  lead: BuyerLead | SellerLead
  updateCallReview: (key: string, value: string) => void
  updateField: (value: Record<string, string | number | Record<string, string>>) => void
}

export const AgentMatchingLeadDetailsSection = ({ lead, updateCallReview, updateField }: Props) => {
  const { salesAppBuyersSellersPendingActions } = useFeatureFlags([
    'sales-app-buyers-sellers-pending-actions'
  ])
  const { createPhoneNumber, updatePhoneNumber } = useLeadPhoneNumberController(
    lead.id,
    lead.userType || ''
  )
  const phoneNumber = lead.phoneMobile
  const phoneAlt = lead.phoneHome
  const { currentUser } = useCurrentUserContext()

  const upsertPhoneNumber = (
    phone: PhoneNumber | undefined,
    value: string,
    phoneType: PhoneNumber['phoneType']
  ) => {
    if (!phone) {
      createPhoneNumber.mutate({ number: value, phoneType })
      return
    }

    updatePhoneNumber.mutate({ id: phone.id, number: value })
  }

  return (
    <Sections title={`${d(lead.userType)}: ${d(lead.stageAndFurthestStage)}`}>
      <Section>
        <FormField title="Source Info" inline>
          {`${dataOrDash(titleize(lead?.marketingChannel))}
            ${dataOrDash(titleize(lead?.marketingSource))}
            ${dataOrDash(titleize(lead?.sourceForm))}`}
        </FormField>
        <FormField title="Client Name" inline>
          <InlineEditTextInput
            className={
              lead?.name && !/[0-9]+|unknown/i.test(lead?.name || '') ? '' : styles.invalidValue
            }
            onBlur={(val: string) => {
              updateField({ name: val })
            }}
            value={dataOrDash(lead?.name)}
          />
        </FormField>
        <FormField title="Partner Name" inline>
          <InlineEditTextInput
            onBlur={(val: string) => {
              updateField({ partnerName: val })
            }}
            value={dataOrDash(lead?.partnerName)}
          />
        </FormField>
        <FormField title="Phone" inline>
          <InlineEditPhoneInput
            className={
              phoneNumber?.formattedNumber &&
              getIsValid(phoneNumber?.formattedNumber, [validatePhoneFormat])
                ? ''
                : styles.invalidValue
            }
            onBlur={(val: string) => {
              upsertPhoneNumber(phoneNumber, val, 'cell')
            }}
            value={phoneNumber?.formattedNumber}
          />
        </FormField>
        <FormField title="Phone Alt" inline>
          <InlineEditPhoneInput
            onBlur={(val: string) => {
              upsertPhoneNumber(phoneAlt, val, 'home')
            }}
            value={phoneAlt?.formattedNumber}
          />
        </FormField>
        <FormField title="Email" inline>
          <InlineEditTextInput
            className={
              lead?.email &&
              lead?.email !== 'unknown@homelight.com' &&
              getIsValid(lead?.email, [validateEmailFormat])
                ? ''
                : styles.invalidValue
            }
            onBlur={(val: string) => {
              updateField({ email: val })
            }}
            value={dataOrDash(lead?.email)}
          />
        </FormField>
        <FormField title="Email Alt" inline>
          <InlineEditTextInput
            onBlur={(val: string) => {
              updateField({ emailAlt: val })
            }}
            value={dataOrDash(lead?.emailAlt)}
          />
        </FormField>
        <FormField title="Secondary User Type" inline>
          {dataOrDash(titleize(lead?.secondaryUserType))}
        </FormField>
        <FormField title="Lead Channel" inline>
          <ExternalLink
            href={
              lead.leadChannel?.id
                ? `${environment.oldSalesAdminURL}lead_channels/${lead.leadChannel?.id}/edit`
                : ''
            }
          >
            {dataOrDash(titleize(lead.leadChannel?.name))}
          </ExternalLink>
        </FormField>
        <FormField title="Phone Verified" inline>
          <InlineEditSelect
            readOnly
            options={yesOrNoOptions}
            value={dataOrDash(stringifyValue(lead.phoneVerified))}
          />
        </FormField>
      </Section>
      <Section>
        <FormField title="Price" inline>
          <InlineEditMoneyInput
            onBlur={(val: string) => {
              updateField({ price: val })
            }}
            value={dataOrDash(lead?.price)}
          />
        </FormField>
        <FormField title="Timeline" inline>
          <InlineEditSelect
            onChange={(val: string) => {
              updateField({ timeline: val })
            }}
            options={agentMatchingLeadTimelineOptions}
            value={
              agentMatchingLeadTimelineOptions?.find(opt => opt.name === lead?.humanTimeline)?.value
            }
          />
        </FormField>
        <FormField title="Agent Comm Pref" inline>
          <InlineEditSelect
            onChange={(val: string) => {
              updateCallReview('agentCommunicationPreference', val)
            }}
            options={agentMatchingCallReviewAgentCommunicationPreference}
            value={dataOrDash(lead?.callReview?.agentCommunicationPreference)}
          />
        </FormField>
        <FormField title="Created At" inline>
          {formatDate(lead.createdAt)}
        </FormField>
        <FormField title="Original Created At" inline>
          {formatDate(lead.originalCreatedAt)}
        </FormField>
        <FormField title="Last Call Date" inline>
          {formatDate(lead.lastCallDate)}
        </FormField>
        <FormField title="Intro Sent at" inline>
          {formatDate(lead.introSentAt)}
        </FormField>
        {isSellerLead(lead) && (
          <FormField title="Needs Listing Review" inline>
            <InlineEditSelect
              onChange={(value: string) => updateField({ needsListingReview: value })}
              options={yesOrNoOptions}
              value={stringifyValue(lead.needsListingReview)}
            />
          </FormField>
        )}
        {salesAppBuyersSellersPendingActions.enabled && (
          <S.FormField title="Pending Actions" inline>
            {lead?.pendingActions?.length ? (
              <>
                <ResolvePendingReviewButtonForm
                  leadId={lead.id}
                  leadType={lead?.userType!}
                  pendingReviews={lead.pendingActions}
                />
              </>
            ) : (
              <Text color="decorative5" hasEllipsis size="xs">
                {DEFAULT_STRING}
              </Text>
            )}
          </S.FormField>
        )}
        {isSellerLead(lead) && (
          <FormField title="Eligibility status" inline>
            {dataOrDash(stringifyValue(lead?.callReview?.eligibleForHlti))}
          </FormField>
        )}
        <FormField title="AI Bot Exit Flag" inline>
          <InlineEditSelect
            onChange={(val: string) => {
              updateCallReview('aiBotExitFlag', val)
            }}
            options={aiBotExitFlags}
            value={dataOrDash(lead?.callReview?.aiBotExitFlag)}
          />
        </FormField>
        <FormField title="Dialer Queue" inline>
          {currentUser?.roles?.lenderAe ? (
            <InlineEditSelect
              onChange={(val: string) => {
                updateField({ dialerQueue: val })
              }}
              options={dialerQueueOptions}
              value={dataOrDash(lead?.dialerQueue)}
            />
          ) : (
            <Text color="decorative5" hasEllipsis size="xs">
              {dataOrDash(titleize(lead?.dialerQueue))}
            </Text>
          )}
        </FormField>
      </Section>
    </Sections>
  )
}
