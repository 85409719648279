// @ts-strict
import { PayPerLeadPaymentInformation } from 'models'
import { getPplProviderPaymentInfosUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchPayPerLeadPaymentInformation = async (
  providerId: string,
  providerType: string
) => {
  const url = getPplProviderPaymentInfosUrl({
    pathParams: {
      provider_id: providerId,
      provider_type: providerType
    },
    action: ''
  })

  const response = await getRequest(url)

  return deserializeAsync(response.data, { transform: PayPerLeadPaymentInformation.create })
}
