// @ts-strict

import { FocusEvent, useEffect, useRef, useState } from 'react'
import { Icon, TextInput, TextInputProps } from 'components'
import { Spinner } from 'components/Graphic'
import { useDebounce } from 'hooks'
import { cx } from 'utils'
import styles from './AutoComplete.module.scss'
import { AutoCompleteList } from './AutoCompleteList'
import { useAutoComplete } from './hooks'

export type AutoCompleteProps = {
  allowDefaultValue?: boolean
  className?: string
  clearOnSelect?: boolean
  'data-testid'?: string
  fetcher?: (search: string) => Promise<any>
  getData?: (val: any) => Promise<any>
  initialData?: any[]
  onBlur?: (e?: FocusEvent<HTMLInputElement>, value?: string) => void
  onClear?: () => void
  onSelect?: (val: any) => any
  renderListAction?: () => React.ReactNode
  value?: string
} & Omit<TextInputProps, 'onSelect' | 'onBlur'>

export const AutoComplete = ({
  allowDefaultValue,
  className,
  clearOnSelect,
  'data-testid': dataTestid,
  fetcher,
  getData,
  initialData = [],
  onBlur,
  onChange,
  onClear,
  onSelect,
  renderListAction,
  value,
  ...props
}: AutoCompleteProps) => {
  const defaultFetcher = fetcher ? () => fetcher(debouncedValue) : () => getData?.(debouncedValue)
  const hash = fetcher ? fetcher.toString() : getData?.toString()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [currentValue, setCurrentValue] = useState(value || '')
  const [debouncedValue, setDebouncedValue] = useState(value || '')
  const [focus, setFocus] = useState<number | null>(null)
  const [searching, setSearching] = useState(false)
  const { results, isLoading } = useAutoComplete(defaultFetcher, debouncedValue, searching, hash)
  const [lastSelectedItem, setLastSelectedItem] = useState<any>(null)
  const [, cancelDebounce] = useDebounce(
    () => {
      setDebouncedValue(currentValue)
      onChange?.(currentValue)
    },
    300,
    [currentValue]
  )

  const selectItem = (item: any) => {
    if (item?.id === 'listAction') {
      return
    }
    if (item) {
      if (clearOnSelect) {
        setCurrentValue('')
      } else {
        setCurrentValue(item.title)
      }
      onSelect?.(item)
    } else if (!allowDefaultValue) {
      setCurrentValue(value || '')
    }
    setFocus(null)
    setSearching(false)
    if (!clearOnSelect) {
      setLastSelectedItem(item)
    }
    cancelDebounce()
  }

  const clearItem = () => {
    onSelect?.(null)
    setFocus(null)
    setSearching(false)
    setCurrentValue('')
    cancelDebounce()
  }

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e, currentValue)
    }

    if (lastSelectedItem) {
      selectItem(lastSelectedItem)
    } else {
      selectItem(null)
    }
  }

  const handleOnChange = (val: string) => {
    setCurrentValue(val)
    setSearching(true)
    setLastSelectedItem(null)
  }

  const moveFocus = (dir: number) => {
    setFocus(focus === null ? 0 : Math.max(0, Math.min(results.length - 1, focus + dir)))
  }

  const acceptFocus = () => {
    if (focus !== null && !!results[focus]) {
      selectItem(results[focus])
    }
  }

  const selectAll = (e: React.MouseEvent<HTMLInputElement>) =>
    e.currentTarget.setSelectionRange(0, e.currentTarget.value.length)

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.keyCode) {
      // up arrow
      case 38:
        e.preventDefault()
        moveFocus(-1)
        break

      // down arrow
      case 40:
        moveFocus(1)
        break

      // enter key
      case 13:
        if (results && results.length > 0 && focus == null) {
          selectItem(results[0])
        }
        acceptFocus()
        e.preventDefault()
        break
      default:
    }
  }

  useEffect(() => {
    setCurrentValue(value || '')
  }, [value])

  return (
    <div ref={wrapperRef} data-testid={dataTestid} className={cx(styles.root, className)}>
      <TextInput
        onKeyDown={onKeyDown}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onClick={selectAll}
        autoComplete="off"
        value={currentValue}
        data-testid="autocomplete"
        className={styles.textField}
        {...props}
      />
      {isLoading ? (
        <Spinner height={16} width={16} data-testid="spinner-icon" />
      ) : (
        <Icon.Close onMouseDown={e => e.preventDefault()} onClick={clearItem} />
      )}
      {searching && results && (
        <AutoCompleteList
          focus={focus}
          items={results}
          onClick={selectItem}
          renderListAction={renderListAction}
        />
      )}
    </div>
  )
}
