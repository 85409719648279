import { getLead, Lead } from 'models'
import { getHapiRequestParams, LeadAttributesWithoutId, LeadSchema, OrderAttributes } from 'schema'
import { getLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

/**
 * This is deprecated. Please, use fetchLeadV2
 */
export const fetchLead = async (id: string): Promise<Lead> => {
  const leadFields: Array<LeadAttributesWithoutId> = [
    'callReview',
    'createdAt',
    'email',
    'emailAlt',
    'fullAddress',
    'furthestStage',
    'humanTimeline',
    'lastCallDate',
    'lastStageUpdate',
    'latitude',
    'longitude',
    'name',
    'notesToAgents',
    'partnerName',
    'phoneAlt',
    'price',
    'propertyType',
    'propertyUuid',
    'salesNotes',
    'source',
    'sourcePageType',
    'stage',
    'userType',
    'isDeal',
    'qualificationTalkedToClient',
    'phoneVerified'
  ]
  const orderFields: Array<keyof OrderAttributes> = [
    'createdAt',
    'ownedBusinessUnit',
    'source',
    'stage',
    'updatedAt'
  ]

  const leadResponseShape: LeadSchema = {
    lead: [...leadFields, { order: orderFields }, { phoneNumbers: ['number', 'phoneType'] }]
  }

  const searchParams = getHapiRequestParams(leadResponseShape)
  const url = getLeadsUrl({ id, searchParams })
  const response = await getRequest(url)
  return await deserializeAsync(response.data, { transform: getLead })
}
