import { AgentPayPerLeadReferral } from 'models'
import { AgentPayPerLeadReferralSchema, getHapiRequestParams } from 'schema'
import { getPplReferralsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchAgentPayPerLeadReferrals = async (
  agentId: string | number,
  current = true,
  providerType?: string
) => {
  const referralsFilter = {
    'filter[_search]': current ? 'current' : 'past'
  }

  const agentPayPerLeadReferralsShape: AgentPayPerLeadReferralSchema = {
    referrals: [
      {
        providerLead: [
          {
            lead: ['name']
          }
        ]
      },
      {
        pplProviderSetting: ['location', 'price', 'userType']
      },
      {
        pplBillingInfo: ['status']
      }
    ]
  }

  const url = getPplReferralsUrl({
    pathParams: {
      provider_type: providerType || 'Agent',
      provider_id: agentId
    },
    searchParams: {
      ...getHapiRequestParams(agentPayPerLeadReferralsShape),
      ...referralsFilter
    }
  })

  const response = await getRequest(url)
  return await deserializeAsync(response.data, { transform: AgentPayPerLeadReferral.create })
}
