// @ts-strict
import { Box } from '@foundation/components'
import { Contents } from 'components'
import { Agent, Investor, PayPerLeadBillingInfo } from 'models'
import { BillingCyclesTable } from './BillingCyclesTable'

type Props = {
  billingCycles: PayPerLeadBillingInfo[]
  provider: Agent | Investor
}

export const BillingCycles = ({ provider, billingCycles }: Props) => {
  return (
    <Contents title="Billing Cycles">
      <Box
        borderStyle="solid"
        borderColor="neutral5"
        borderWidth="thin"
        borderRadius="xl"
        padding="$8"
        width="650px"
      >
        <BillingCyclesTable provider={provider} billingCycles={billingCycles} />
      </Box>
    </Contents>
  )
}
