// @ts-strict
import { InvestorResponse, PplBillingInfoResponse, PplProviderSettingResponse } from 'schema'
import { Model } from '../Model'

type InvestorAttributes = Omit<Investor, 'id' | 'firstPplBillingInfo'>

export class Investor extends Model {
  assignedIsm?: string
  billingEmail?: string
  id!: string
  name?: string
  email?: string
  modelName? = 'Investor'
  offerUrl?: string
  phoneMobile?: string
  phoneOffice?: string
  pplInvestor?: string

  pplBillingInfos?: PplBillingInfoResponse[]
  pplProviderSettings?: PplProviderSettingResponse[]

  constructor(attributes: InvestorAttributes) {
    super()

    Object.assign(this, attributes)
  }

  static create(investorResponse: Potential<InvestorResponse>): Potential<Investor> {
    if (!investorResponse) {
      return
    }

    return new Investor(investorResponse)
  }

  firstPplBillingInfo(): PplBillingInfoResponse | undefined {
    // Create a shallow copy of the array to avoid modifying the original
    const sortedBillingInfos = [...(this.pplBillingInfos || [])]

    sortedBillingInfos.sort((a, b) => {
      const dateA = a.billingCycleStartDate ? new Date(a.billingCycleStartDate).getTime() : Infinity
      const dateB = b.billingCycleStartDate ? new Date(b.billingCycleStartDate).getTime() : Infinity

      return dateA - dateB
    })

    return sortedBillingInfos[0]
  }
}
