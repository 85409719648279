// @ts-strict
import { useMutation } from 'hooks'
import { PayPerLeadProviderSetting } from 'models'
import { postPayPerLeadSettings } from 'services'

type CreatePayPerLeadSettingsPayload = {
  billingFreq: string
  buyerMonthlyCap: number
  monthlyCap: number
  settings: PayPerLeadProviderSetting[]
}

export const usePayPerLeadSettingsController = (
  providerId: string | number,
  providerType: string
) => {
  const createPayPerLeadSettings = useMutation(
    (payload: CreatePayPerLeadSettingsPayload) =>
      postPayPerLeadSettings(providerId, providerType, payload),
    {
      onSuccess: () => {}
    }
  )

  return {
    createPayPerLeadSettings
  }
}
