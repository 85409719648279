// @ts-strict
import { FunctionComponent, InputHTMLAttributes, useRef } from 'react'
import { ActionButton, IButtonProps } from 'components'
import { cx } from 'utils'
import styles from './FileInputButton.module.scss'

type TProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onUpload' | 'value' | 'buttonComponent'
> & {
  buttonComponent?: FunctionComponent<IButtonProps>
  onUpload?: (files: File[]) => void
  value?: File[]
}

export const FileInputButton = ({
  value = [],
  onUpload = () => {},
  title = 'Choose Files',
  buttonComponent: ButtonComponent = ActionButton,
  className,
  ...rest
}: TProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const handleClick = () => {
    inputRef?.current?.click()
  }

  return (
    <div className={cx(styles.wrapper, className)}>
      <ButtonComponent onClick={handleClick} className={styles.button} type="button">
        {title}
      </ButtonComponent>
      <input
        className={styles.input}
        type="file"
        onChange={e => {
          onUpload([...e.target.files!])
        }}
        ref={inputRef}
        {...rest}
      />
    </div>
  )
}
