import { useEffect } from 'react'
import { Button } from '@foundation/components'
import {
  ConfirmationPopup,
  Contents,
  ExternalLink,
  FormField,
  InlineEditDollarsInput,
  InlineEditSelect,
  Button as LegacyButton,
  Section,
  Sections
} from 'components'
import { getBBYSLeadInputProps } from 'content/Lead/LeadDetails/CCBBYS/utils'
import { useLeadContext } from 'contexts'
import { environment } from 'environment'
import { useDocumentTitle, useFeatureFlags } from 'hooks'
import { approvalTypes, estimatedMortgageBalanceSourceOptions, yesOrNoOptions } from 'lookups'
import { isCCBBYSLead } from 'models'
import {
  dataOrDash,
  formatDollars,
  formatNumber,
  formatPercentage,
  formatPercentFromDecimal
} from 'utils'

export const CCBBYSLeadEconomicModelTab = () => {
  const { lead, createEconomicModel, updateBBYSLead } = useLeadContext()
  const { setTitle } = useDocumentTitle()

  const { equityLeadSubmissionV3 } = useFeatureFlags(['equity-lead-submission-v3'])

  useEffect(() => setTitle(`${lead.name} - Lead Economic Model`), [lead, setTitle])

  if (!isCCBBYSLead(lead)) {
    console.error('Lead must be an instance of BBYSLead')
    return null
  }

  const bbysLead = lead.bbysProviderLead.bbysLead
  const expenses = lead.bbysProviderLead.providerLeadExpenses
  const { getProps } = getBBYSLeadInputProps(bbysLead, updateBBYSLead)

  const getExpenseValueBySlug = (slug: string) => {
    const expenseBySlug = expenses.find(expense => {
      return expense.slug === slug
    })
    return expenseBySlug?.value
  }

  const getAgentCommissionPartial = (bbysAttribute: string) => {
    const commissionAmount = Number(getExpenseValueBySlug('target_total_agent_commission'))
    if (isNaN(commissionAmount)) {
      return
    }
    const totalAgentFeeRate =
      Number(lead.bbysLead?.dpBuyersAgentCommissionRate) +
      Number(lead.bbysLead?.dpSellersAgentCommissionRate)
    const percentOfTotal = Number(bbysLead[bbysAttribute]) / totalAgentFeeRate
    const value = Math.round((percentOfTotal * commissionAmount) / 100) * 100
    return value && !isNaN(value) ? value : 0
  }

  const sumLienBalances = () => {
    const balances = [
      Number(lead.bbysLead?.lienBalance1),
      Number(lead.bbysLead?.lienBalance2),
      Number(lead.bbysLead?.lienBalance3),
      Number(lead.bbysLead?.lienBalance4),
      Number(lead.bbysLead?.lienBalance5)
    ].filter(balance => !isNaN(balance) && balance != null)

    return balances.reduce((acc, balance) => acc + balance, 0)
  }

  const action = !!expenses.length ? (
    <LegacyButton>
      <ExternalLink href={`${environment.planningAppURL}/${lead.bbysProviderLead.id}`}>
        Open Estimate Calculator
      </ExternalLink>
    </LegacyButton>
  ) : (
    <ConfirmationPopup
      title="Create Economic Model"
      buttonComponent={props => (
        <Button variant="outline" color="neutralLight" onClick={props.onClick}>
          Create Economic Model
        </Button>
      )}
      message="Click 'Submit' to generate all expenses and calculated fields required to support the Economic Model for this BBYS Lead."
      mutation={createEconomicModel}
      initialPayload={{ id: lead.bbysProviderLead.id }}
    />
  )

  return (
    <Contents title="Economic Model" actions={action}>
      <Sections title="Valuation & Equity Unlock calculations (Departing Residence)">
        <Section>
          <FormField title="HomeLight valuation" inline>
            {formatDollars(lead.bbysLead?.departingPropertyValuation)}
          </FormField>
          <FormField title="Total equity unlock amount" inline>
            {formatDollars(lead.bbysLead?.dpTargetNewHomePurchaseEquity)}
          </FormField>
          <FormField title="Max equity for a down payment" inline>
            {formatDollars(lead.bbysLead?.dpMaxDownpaymentEquity)}
          </FormField>
          <FormField title="Approved combined loan to value ratio" inline>
            {formatPercentFromDecimal(lead.bbysLead?.dpLoanToValueRatio)}
          </FormField>
          <FormField title="Loan payoff value" inline>
            {formatDollars(lead.bbysLead?.loanPayoffValue)}
          </FormField>
          <FormField title="Risk adjustment percentage" inline>
            {formatPercentFromDecimal(lead.bbysLead?.dpGpPercentageOfValuation)}
          </FormField>
          <FormField title="Risk adjusted value" inline>
            {formatDollars(lead.bbysLead?.departingPropertyGuaranteedPrice)}
          </FormField>
          <FormField title="Total selling costs (%)" inline>
            {formatPercentFromDecimal(
              lead.bbysLead?.departingPropertyGuaranteedPrice
                ? Number(lead.bbysLead?.dpTargetTotalCosts) /
                    Number(lead.bbysLead?.departingPropertyGuaranteedPrice)
                : NaN
            )}
          </FormField>
          <FormField title="Total selling costs ($)" inline>
            {formatDollars(lead.bbysLead?.dpTargetTotalCosts)}
          </FormField>
          <FormField title="BBYS fee  (%)" inline>
            {formatPercentFromDecimal(lead.bbysLead?.dpTargetTradeInFeeRate)}
          </FormField>
          <FormField title="BBYS fee ($)" inline>
            {formatDollars(lead.bbysLead?.dpTargetTradeInFee)}
          </FormField>
          <FormField title="Agent fees (%)" inline>
            {formatPercentFromDecimal(
              lead.bbysLead?.dpSellersAgentCommissionRate
                ? Number(lead.bbysLead?.dpSellersAgentCommissionRate) +
                    Number(lead.bbysLead?.dpBuyersAgentCommissionRate)
                : NaN
            )}
          </FormField>
          <FormField title="Inspection cost ($)" inline>
            {formatDollars(getExpenseValueBySlug('target_property_pest_inspections'))}
          </FormField>
        </Section>

        <Section>
          <FormField title="Buyer agent commission (%)" inline>
            {formatPercentFromDecimal(lead.bbysLead?.dpBuyersAgentCommissionRate)}
          </FormField>
          <FormField title="Buyer agent commission ($)" inline>
            {formatDollars(getAgentCommissionPartial('dpBuyersAgentCommissionRate'))}
          </FormField>
          <FormField title="Seller agent commission (%)" inline>
            {formatPercentFromDecimal(lead.bbysLead?.dpSellersAgentCommissionRate)}
          </FormField>
          <FormField title="Seller agent commission ($)" inline>
            {formatDollars(getAgentCommissionPartial('dpSellersAgentCommissionRate'))}
          </FormField>
          <FormField title="Closing costs (%)" inline>
            {formatPercentFromDecimal(lead.bbysLead?.dpTargetClientClosingCostsRate)}
          </FormField>
          <FormField title="Closing costs ($)" inline>
            {formatDollars(lead.bbysLead?.dpTargetClientClosingCosts)}
          </FormField>
          <FormField title="First Position Payoffs" inline>
            {formatDollars(lead.bbysLead?.dpFirstPositionPayoffs)}
          </FormField>
          <FormField title="First position lien balance" inline>
            {formatDollars(lead.bbysLead?.departingPropertyExternalLoanPayoff)}
          </FormField>
          <FormField title="Solar lease payoff" inline>
            {formatDollars(lead.bbysLead?.dpSolarLeasePayoff)}
          </FormField>
          <FormField title="Non 1st Position Balances" inline>
            {formatDollars(lead.bbysLead?.dpNonFirstPositionBalances)}
          </FormField>
          <FormField title="Second mortgage balance" inline>
            {formatDollars(lead.bbysLead?.dpSecondMortgageBalance)}
          </FormField>
          <FormField title="HELOC balance" inline>
            {formatDollars(lead.bbysLead?.dpHelocBalance)}
          </FormField>
          <FormField title="Solar loan balance" inline>
            {formatDollars(lead.bbysLead?.departingPropertySolarLoanBalance)}
          </FormField>
          <FormField title="Other liens" inline>
            {formatDollars(lead.bbysLead?.dpOtherLiensBalance)}
          </FormField>
        </Section>
      </Sections>

      <Sections title="Lead intake approval information (Departing Residence)">
        <Section>
          <FormField title="Approval type on intake" inline>
            {dataOrDash(bbysLead?.approvalTypeIntake)}
          </FormField>
          <FormField title="Final Approval Type" inline>
            <InlineEditSelect {...getProps('finalApprovalType')} options={approvalTypes} addBlank />
          </FormField>
          <FormField title="Property listed" inline>
            <InlineEditSelect {...getProps('propertyListed')} options={yesOrNoOptions} addBlank />
          </FormField>
          <FormField title="Photos Required" inline>
            <InlineEditSelect {...getProps('photosRequired')} options={yesOrNoOptions} />
          </FormField>
          <FormField title="House Canary AVM value" inline>
            {formatDollars(lead.bbysLead?.houseCanaryAvmValueIntake)}
          </FormField>

          <FormField title="Lender estimated home value" inline>
            {formatDollars(lead.bbysLead?.lenderEstimatedHomeValue)}
          </FormField>

          <FormField title="Agent estimated home value" inline>
            <InlineEditDollarsInput {...getProps('agentEstimatedHomeValue')} />
          </FormField>

          <FormField title="Equity unlock range" inline>
            {`${formatDollars(lead.bbysLead?.equityUnlockLowEndRangeIntake)} - ${formatDollars(
              lead.bbysLead?.equityUnlockHighEndRangeIntake
            )}`}
          </FormField>

          <FormField title="Instant equity unlock threshold" inline>
            {formatDollars(lead.bbysLead?.instantEquityUnlockThresholdIntake)}
          </FormField>

          <FormField title="Target equity unlock amount" inline>
            {formatDollars(lead.bbysLead?.targetEquityUnlockAmountIntake)}
          </FormField>

          {!equityLeadSubmissionV3.enabled && (
            <>
              <FormField title="Estimated mortgage balance" inline>
                {formatDollars(lead.bbysLead?.estimatedMortagageBalanceIntake)}
              </FormField>

              <FormField title="Total lien balances" inline>
                {formatDollars(sumLienBalances())}
              </FormField>

              <FormField title="Estimated mortgage balance source" inline>
                <InlineEditSelect
                  options={estimatedMortgageBalanceSourceOptions}
                  {...getProps('estimatedMortgageBalanceSource')}
                />
              </FormField>
            </>
          )}

          <FormField title="Loan payoff value threshold" inline>
            {formatDollars(lead.bbysLead?.loanPayoffValueThresholdIntake)}
          </FormField>
        </Section>
        <Section>
          {!equityLeadSubmissionV3.enabled && (
            <FormField title="Additional liens on property" inline>
              {lead.bbysLead?.additionalLiensOnProperty ? 'Yes' : 'No'}
            </FormField>
          )}

          <FormField title="Lien type 1" inline>
            {dataOrDash(lead.bbysLead?.lienType1)}
          </FormField>
          <FormField title="Lien balance 1" inline>
            {formatDollars(lead.bbysLead?.lienBalance1)}
          </FormField>

          <FormField title="Lien type 2" inline>
            {dataOrDash(lead.bbysLead?.lienType2)}
          </FormField>
          <FormField title="Lien balance 2" inline>
            {formatDollars(lead.bbysLead?.lienBalance2)}
          </FormField>

          <FormField title="Lien type 3" inline>
            {dataOrDash(lead.bbysLead?.lienType3)}
          </FormField>
          <FormField title="Lien balance 3" inline>
            {formatDollars(lead.bbysLead?.lienBalance3)}
          </FormField>

          <FormField title="Lien type 4" inline>
            {dataOrDash(lead.bbysLead?.lienType4)}
          </FormField>
          <FormField title="Lien balance 4" inline>
            {formatDollars(lead.bbysLead?.lienBalance4)}
          </FormField>

          <FormField title="Lien type 5" inline>
            {dataOrDash(lead.bbysLead?.lienType5)}
          </FormField>
          <FormField title="Lien balance 5" inline>
            {formatDollars(lead.bbysLead?.lienBalance5)}
          </FormField>

          {equityLeadSubmissionV3.enabled && (
            <FormField title="Total lien balances" inline>
              {formatDollars(sumLienBalances())}
            </FormField>
          )}

          <FormField title="Instant risk adjustment percentage" inline>
            {lead.bbysLead?.instantRiskAdjustmentPercentageIntake
              ? formatPercentage(Number(lead.bbysLead?.instantRiskAdjustmentPercentageIntake))
              : '---'}
          </FormField>
          <FormField title="Instant risk adjusted value" inline>
            {formatDollars(lead.bbysLead?.instantRiskAdjustedValueIntake)}
          </FormField>
          <FormField title="Combined loan to value ratio at intake" inline>
            {formatNumber(lead.bbysLead?.combinedLoanToValueRatioIntake)}
          </FormField>
          <FormField title="User Value/Property Value Mean" inline>
            {formatNumber(lead.bbysLead?.userValueToPropertyValueMeanIntake)}
          </FormField>
        </Section>
      </Sections>
    </Contents>
  )
}
