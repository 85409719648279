import { Metroplex, TeamMembership, UsState } from 'models'
import {
  CurrentUserResponse,
  MetroplexResponse,
  StateResponse,
  UserIntegrationResponse
} from 'schema'
import { byAttr, camelize, objectToParam, unique } from 'utils'

const getCallerId = user => {
  if (user.phoneTwilio) {
    if (/^\+1/.test(user.phoneTwilio)) {
      return user.phoneTwilio
    }
    return `+1${user.phoneTwilio}`
  }
  return ''
}

const importantRoles = [
  'sales_impersonation_admin',
  'sales_ppl_admin',
  'sales_app_dev',
  'sales_app_admin',
  'cc_app_user',
  'cc_app_admin',
  'agent_ae',
  'agent',
  'agent_action_admin',
  'agent_admin',
  'sales_manager',
  'super_user',
  'client_ae',
  'escrow_manager',
  'lender_ae',
  'agent_preferences_manager',
  'subscriptions_manager',
  'sales_elite_status_admin',
  'hand_matching_admin',
  'blind_intro_manual',
  'lead_creation_admin'
]
type Roles = {
  agent?: boolean
  agentActionAdmin?: boolean
  agentAdmin?: boolean
  agentAe?: boolean
  agentPreferencesManager?: boolean
  blindIntroManual?: boolean
  ccAppAdmin?: boolean
  ccAppUser?: boolean
  clientAe?: boolean
  escrowManager?: boolean
  handMatchingAdmin?: boolean
  leadCreationAdmin?: boolean
  lenderAe?: boolean
  salesAppAdmin?: boolean
  salesAppDev?: boolean
  salesEliteStatusAdmin?: boolean
  salesImpersonationAdmin?: boolean
  salesManager?: boolean
  salesPplAdmin?: boolean
  subscriptionsManager?: boolean
  superUser?: boolean
}

const getRoles = (user): Roles => {
  return importantRoles.reduce((acc, role) => {
    return { ...acc, [camelize(role)]: !!user.roles.find(byAttr('name', role)) }
  }, {} as Roles)
}

const filterForMetroplex = (area): area is MetroplexResponse => 'slug' in area
const filterForState = (area): area is StateResponse => 'code' in area

type TCurrentUserConstructor = Omit<
  CurrentUser,
  | 'hasATransactionTeam'
  | 'hasAgentAeAreas'
  | 'hasTransactionTeam'
  | 'hasNylasEnrollment'
  | 'isNylasEnrollmentAllowed'
  | 'agentsDefaultFilters'
  | 'agentsDefaultQueryString'
>

export class CurrentUser {
  id: string
  available?: boolean
  callerId?: string
  email?: string
  firstName?: string
  name?: string
  roles: Roles = {}
  lastName?: string
  phoneTwilio?: string
  docusignLoggedIn?: boolean
  impersonated?: boolean
  assignedStates?: UsState[]
  assignedMetroplexes?: Metroplex[]
  teamMemberships?: TeamMembership[]
  userIntegrations?: UserIntegrationResponse[]

  constructor(currentUserAttributes: TCurrentUserConstructor) {
    Object.assign(this, currentUserAttributes)
  }

  static create(currentUserResponse: Potential<CurrentUserResponse>): Potential<CurrentUser> {
    if (!currentUserResponse) {
      return
    }

    const callerId = getCallerId(currentUserResponse)
    const name = `${currentUserResponse.firstName} ${currentUserResponse.lastName}`
    const roles = getRoles(currentUserResponse)
    const assignedAeAreas = currentUserResponse.agentAeAreas?.map(a => a.assignedArea) || []
    const assignedMetroplexes = assignedAeAreas.filter(filterForMetroplex).map(Metroplex.create)
    const states = assignedAeAreas.filter(filterForState).map(UsState.create)
    const assignedStates = unique([
      ...states,
      ...assignedMetroplexes.map(mpx => mpx.state).filter(Boolean)
    ])

    const teamMemberships = currentUserResponse.teamMemberships?.map(TeamMembership.create) || []

    return new CurrentUser({
      ...currentUserResponse,
      assignedMetroplexes,
      assignedStates,
      callerId,
      name,
      roles,
      teamMemberships
    })
  }

  get hasAgentAeAreas() {
    return this.assignedMetroplexes.length || this.assignedStates.length
  }

  get hasTransactionTeam() {
    return this.teamMemberships.length
  }

  get hasNylasEnrollment() {
    return !!this.userIntegrations?.find(({ name }) => name === 'nylas_account_id')
  }

  get isNylasEnrollmentAllowed() {
    const nylasAllowedTeamMembershipRoles = [
      'client_advisor',
      'client_advisor_assistant',
      'sales_specialist',
      'agent_account_manager',
      'listing_specialist'
    ]
    return (
      !this.hasNylasEnrollment &&
      !!this.teamMemberships?.find(({ role }) => nylasAllowedTeamMembershipRoles.includes(role))
    )
  }

  get agentsDefaultFilters() {
    return {
      inContract: 'true',
      ...(this.hasAgentAeAreas || this.hasTransactionTeam ? { assigneeId: this.id } : {})
    }
  }

  get agentsDefaultQueryString() {
    return `?${objectToParam(this.agentsDefaultFilters)}`
  }
}
