import { AsideActionBar, AsideActionButton, AsideActionIcon } from 'components'
import { AddAgentActivityNoteButtonForm, AgentMessageButtonForm } from 'content/Agent'
import { useCurrentUserContext } from 'contexts/CurrentUser'
import { useDialerContext } from 'contexts/Dialer'
import { Agent } from 'models'

type Props = { agent: Agent; onOpenChangeModal?: (isOpen: boolean) => void }

export const AgentActionBar = ({ agent, onOpenChangeModal }: Props) => {
  const { call, isOnCall } = useDialerContext()
  const { currentUser } = useCurrentUserContext()

  const phoneOffice = agent.getPhoneOffice()
  const phoneMobile = agent.getPhoneMobile()
  const isOfficeButtonDisabled = !phoneOffice || isOnCall
  const isCellButtonDisabled = !phoneMobile || isOnCall

  const handleClickCallAgentOffice = () => {
    if (!currentUser || !phoneOffice?.number) {
      return
    }

    call(agent.getDialer(currentUser, phoneOffice?.number, 'office'))
  }

  const handleClickCallAgentMobile = () => {
    if (!currentUser || !phoneMobile?.number) {
      return
    }

    call(agent.getDialer(currentUser, phoneMobile?.number, 'mobile'))
  }

  const handleMailTo = () => {
    window.open(`mailto:${[agent.email1, agent.email2].filter(Boolean).join('?cc=')}`)
  }

  return (
    <AsideActionBar>
      {currentUser?.phoneTwilio && (
        <>
          <AsideActionButton
            title="Make a call"
            onClick={handleClickCallAgentOffice}
            disabled={isOfficeButtonDisabled}
          >
            <AsideActionIcon icon="Call" />
            Office
          </AsideActionButton>
          <AsideActionButton
            title="Make a call"
            onClick={handleClickCallAgentMobile}
            disabled={isCellButtonDisabled}
          >
            <AsideActionIcon icon="Cellphone" />
            Cell
          </AsideActionButton>
          <AgentMessageButtonForm
            agent={agent}
            onOpenChangeModal={onOpenChangeModal}
            buttonText={
              <span>
                <AsideActionIcon icon="Message" />
                Text
              </span>
            }
            buttonComponent={AsideActionButton}
          />
        </>
      )}
      <AsideActionButton title="Send email" onClick={handleMailTo}>
        <AsideActionIcon icon="Envelope" />
        Email
      </AsideActionButton>
      <AddAgentActivityNoteButtonForm
        agent={agent}
        buttonComponent={AsideActionButton}
        onOpenChangeModal={onOpenChangeModal}
        buttonText={
          <span>
            <AsideActionIcon icon="Note" />
            Note
          </span>
        }
      />
    </AsideActionBar>
  )
}
