// @ts-strict
import { ReactNode, useEffect, useRef } from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import {
  Bubble,
  Console,
  ConsoleAside,
  ConsoleDetails,
  ConsoleLoading,
  Dev,
  Sticky,
  TabNav,
  Tabs
} from 'components'
import { QualiaInfo } from 'content/EscrowLead'
import { ContactsLeadNavigation } from 'content/Lead'
import { Disposition } from 'content/Lead/LeadDetails/CCBBYS/Disposition'
import { RequestIRContract } from 'content/Lead/LeadDetails/CCBBYS/RequestIRContract'
import { RequestValuation } from 'content/Lead/LeadDetails/CCBBYS/RequestValuation'
import { OrderLeadNavigation } from 'content/Order'
import { UserInfo } from 'content/User'
import {
  LeadProvider,
  QueryStringParamsProvider,
  useLeadContext,
  useSavedSearchesContext
} from 'contexts'
import { useAttachableTaskCountsController, useOrderController } from 'controllers'
import { useClientController } from 'controllers/Client'
import { useFeatureFlags, useFlexUi, useSettings, useTrait } from 'hooks'
import { TLeadUserTypeServerSlug } from 'lookups'
import {
  isCCBBYSLead,
  isCCCashOfferLead,
  isCCHLSimpleSaleLead,
  isCCTradeInLead,
  isEscrowLead,
  isMortgageLead,
  Lead,
  Order
} from 'models'
import { Client } from 'models/Client'
import styles from './LeadPage.module.scss'
import { LeadSummary } from './LeadSummary'
import { LeadTabActions } from './LeadTabActions'

type LeadPageContentsProps = {
  children: ReactNode
  client?: Client
  lead: Lead
  order?: Order
}

export const LeadPageContents = ({ client, order, lead, children }: LeadPageContentsProps) => {
  const { setTrait } = useTrait()
  const { pastDueTasksCount, readyTasksCount } = useAttachableTaskCountsController({
    attachable: lead
  })
  const { getLastSearchUrl } = useSavedSearchesContext()
  const { salesAppInspections } = useFeatureFlags(['sales-app-inspections'])
  const { isFlexUi } = useFlexUi()

  const showDocumentsTab =
    isCCTradeInLead(lead) ||
    isCCCashOfferLead(lead) ||
    isEscrowLead(lead) ||
    isCCHLSimpleSaleLead(lead) ||
    isCCBBYSLead(lead)
  const showTeamTab =
    isCCTradeInLead(lead) ||
    isCCCashOfferLead(lead) ||
    isMortgageLead(lead) ||
    isEscrowLead(lead) ||
    isCCHLSimpleSaleLead(lead) ||
    isCCBBYSLead(lead)
  const cashOfferLeadTaskFilter =
    isCCCashOfferLead(lead) && lead.activeCashOfferProviderLead?.isActive
      ? `&incomingResidence=${lead?.activeCashOfferLead?.id}`
      : ''
  const leadTasksUrl = getLastSearchUrl(
    `${lead.getPathname()}/tasks`,
    `?completionStatus=[ready]${cashOfferLeadTaskFilter}`
  )
  useEffect(() => {
    if (isCCTradeInLead(lead)) {
      setTrait(
        'transaction_team',
        lead.tradeInLead?.departingPropertySellerAgent?.transactionTeam?.id || ''
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead])

  return (
    <Console className={styles.pageWrapper}>
      <ConsoleAside className={styles.consoleAside}>
        <Sticky className={styles.aside}>
          <LeadSummary lead={lead} />
          {isCCBBYSLead(lead) && <ContactsLeadNavigation lead={lead} />}
          {order && <OrderLeadNavigation order={order} selectedLead={lead} />}
          {!isFlexUi && !isCCBBYSLead(lead) && client && <UserInfo user={client.user} />}
          {isEscrowLead(lead) && <QualiaInfo lead={lead} />}
        </Sticky>
      </ConsoleAside>
      <ConsoleDetails className={styles.details}>
        <Tabs className={styles.tabs} sticky data-testid="lead-page-tabs">
          <div className={styles.tabsWrapper}>
            <TabNav to={lead.getPathname()}>Details</TabNav>
            {(isCCTradeInLead(lead) || isCCBBYSLead(lead)) && (
              <TabNav to={`${lead.getPathname()}/economic-model`}>Economic Model</TabNav>
            )}
            {(isCCTradeInLead(lead) || isCCBBYSLead(lead)) && salesAppInspections.enabled && (
              <TabNav to={`${lead.getPathname()}/inspections`}>Inspections</TabNav>
            )}

            {!isFlexUi && (
              <TabNav to={leadTasksUrl}>
                Tasks
                <Bubble count={readyTasksCount} className={styles.bubble} />
                <Bubble count={pastDueTasksCount} className={styles.bubble} danger />
              </TabNav>
            )}
            {showDocumentsTab && (
              <TabNav to={`${lead.getPathname()}/documents`} parent>
                Documents
              </TabNav>
            )}
            <TabNav to={`${lead.getPathname()}/activity`}>Activity</TabNav>
            {showTeamTab && <TabNav to={`${lead.getPathname()}/team`}>Team</TabNav>}
            <Dev>
              <TabNav to={`${lead.getPathname()}/inspect`}>Inspect</TabNav>
            </Dev>
          </div>
          <LeadTabActions lead={lead} />
        </Tabs>
        <div className={styles.leadActions}>
          {isCCBBYSLead(lead) && <RequestIRContract lead={lead} />}
          {isCCBBYSLead(lead) && <RequestValuation lead={lead} />}
          {isCCBBYSLead(lead) && (
            <Disposition
              leadId={lead.id}
              loanFundingDate={lead?.bbysLead?.loanFundingDate}
              leadStage={lead.stage}
            />
          )}
        </div>
        {children}
      </ConsoleDetails>
    </Console>
  )
}

type LeadPageContextProps = {
  children?: ReactNode
}

const LeadPageContext = ({ children }: LeadPageContextProps) => {
  const { pathname } = useLocation()
  const { lead, isLoading } = useLeadContext()
  const { orderQuery } = useOrderController(lead?.order?.id)
  const { clientQuery } = useClientController(lead?.client?.id)
  const { addLeadToHistory } = useSettings()
  const addLeadToHistoryRef = useRef(addLeadToHistory)

  const leadPathname = lead?.getPathname()
  addLeadToHistoryRef.current = addLeadToHistory

  useEffect(() => {
    if (leadPathname && !pathname.includes(leadPathname)) {
      window.location.replace(leadPathname + window.location.search)
    }
  }, [leadPathname, pathname])

  useEffect(() => {
    if (lead) {
      addLeadToHistoryRef.current(lead.getLink())
    }
  }, [lead])

  if (isLoading) {
    return <ConsoleLoading type="Lead" />
  }

  if (!lead) {
    return null
  }

  return (
    <LeadPageContents lead={lead} order={orderQuery.data} client={clientQuery.data}>
      {children}
    </LeadPageContents>
  )
}

export const LeadPage = ({ userType }: { userType?: TLeadUserTypeServerSlug }) => {
  const { leadId = '' } = useParams()
  return (
    <LeadProvider leadId={leadId} userType={userType}>
      <QueryStringParamsProvider>
        <LeadPageContext>
          <Outlet />
        </LeadPageContext>
      </QueryStringParamsProvider>
    </LeadProvider>
  )
}
