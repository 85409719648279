// @ts-strict
import { useState } from 'react'
import { useToastContext } from '@foundation/components'
import { PrimaryButton } from 'components'
import { LocationSettingsForm } from 'content/Provider/PplProviderSettings'
import { useInvestorPayPerLeadSettingsController } from 'controllers'
import { environment } from 'environment'
import { InvestorPayPerLeadSetting } from 'models'
import type { Investor } from 'models'
import { LinkInvestorDetailsForm, MonthlyCapForm } from '../MonthlyCap'
import { SettingsModal } from './SettingsModal'

type Props = {
  investor: Investor
}

export const SetupPayPerLeadSettingsButtonForm = ({ investor }: Props) => {
  const {
    createInvestorPayPerLeadSettings,
    createSetupCheckoutSession,
    createSetupCheckoutSessionMagicLink,
    clearCaches
  } = useInvestorPayPerLeadSettingsController(investor.id)
  const toast = useToastContext()

  const [visible, setVisible] = useState(false)
  const [currentScreen, setCurrentScreen] = useState('location-settings')
  const [locationSettings, setLocationSettings] = useState<InvestorPayPerLeadSetting[]>([])
  const [checkoutUrl, setCheckoutUrl] = useState<string>('')

  const handleOnSubmitSettings = (
    monthlyCap: number,
    billingFreq: string,
    paymentMethod: string
  ) => {
    const monthlyCapInCents = monthlyCap * 100
    const pricedSettings = locationSettings.filter(
      locationSettings => locationSettings.price && locationSettings.price > 0
    )

    createInvestorPayPerLeadSettings.mutate(
      {
        settings: pricedSettings!,
        monthlyCap: monthlyCapInCents,
        billingFreq: billingFreq
      },
      {
        onSuccess: () => {
          // FIXME: this is a temporary solution, please do not replicate in other components
          setTimeout(() => {
            createCheckoutSession(paymentMethod)
          }, 1000)
        }
      }
    )
  }

  const createCheckoutSession = (paymentMethod: string) => {
    createSetupCheckoutSession.mutate(
      {
        subscriberId: investor.id,
        subscriberType: 'Investor',
        redirectUrl: window.location.href
      },
      {
        onSuccess: response => {
          handleCheckoutSessionCreated(response, paymentMethod)
        },
        onError: () => {
          toast({
            title: 'Unable to complete your request. Please add your payment information manually',
            status: 'error'
          })

          clearCaches()
          setVisible(false)
        }
      }
    )
  }

  const handleCheckoutSessionCreated = (response: any, paymentMethod: string) => {
    if (paymentMethod === 'card') {
      const newWindow = window.open(response.data.url, '_self', 'noopener,noreferrer')
      if (newWindow) {
        newWindow.opener = null
      }
      setVisible(false)
      setCurrentScreen('location-settings')
      clearCaches()
    }

    if (paymentMethod === 'link') {
      setCheckoutUrl(response.data.url)
      setCurrentScreen('link-investor-details')
    }
  }

  const handleOnSubmitLinkInvestorDetails = () => {
    createSetupCheckoutSessionMagicLink.mutate({
      subscriberId: investor.id,
      subscriberType: 'Investor',
      priceId: environment.stripeProsPriceId,
      redirectUrl: window.location.href
    })

    setVisible(false)
    setCurrentScreen('location-settings')
    clearCaches()
  }

  if (!visible) {
    return <PrimaryButton onClick={() => setVisible(true)}>Add Pay-Per-Lead</PrimaryButton>
  }

  return (
    <SettingsModal
      title="Add Pay-Per-Lead ZIP"
      onCancel={() => {
        setVisible(false)
        setCurrentScreen('location-settings')
      }}
    >
      {currentScreen === 'location-settings' && (
        <LocationSettingsForm
          providerType="Investor"
          settings={locationSettings}
          onSubmit={(settings: InvestorPayPerLeadSetting[]) => {
            setLocationSettings(settings)
            setCurrentScreen('monthly-cap')
          }}
          onCancel={() => setVisible(false)}
          submitText="Next"
        />
      )}
      {currentScreen === 'monthly-cap' && (
        <MonthlyCapForm
          cancelText="Back"
          submitText="Next"
          onSubmit={(cap: number, freq: string, method: string) => {
            handleOnSubmitSettings(cap, freq, method)
          }}
          onCancel={() => {
            setCurrentScreen('location-settings')
          }}
        />
      )}
      {currentScreen === 'link-investor-details' && (
        <LinkInvestorDetailsForm
          investor={investor}
          url={checkoutUrl}
          onSubmit={() => {
            handleOnSubmitLinkInvestorDetails()
          }}
        />
      )}
    </SettingsModal>
  )
}
