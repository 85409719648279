// @ts-strict
import { Button } from '@foundation/components'
import { ButtonForm, useButtonForm } from 'components'
import { useFileUploadsController } from 'controllers'
import { useFeatureFlags } from 'hooks'
import { Lead } from 'models'
import { TFormValidation, validatePresence } from 'utils/validations'
import { LeadFileUploadsButtonFields } from './LeadFileUploadsButtonFields'

type TLeadFileUploadsButtonFormProps = {
  lead?: Lead
  multiple?: boolean
}

export type TLeadFileUploadsPayload = {
  category?: string
  files: File[]
  name: Potential<string>
  shareExternally: boolean
  subAttachableId: Potential<string>
  subAttachableType: Potential<string>
}

export const LeadFileUploadsButtonForm = ({ lead, multiple }: TLeadFileUploadsButtonFormProps) => {
  const { uploadFile } = useFileUploadsController({ leadId: lead?.id || '' })
  const { filesPermissioning } = useFeatureFlags(['files-permissioning'])

  const initialPayload: TLeadFileUploadsPayload = {
    files: [],
    name: '',
    subAttachableId: undefined,
    subAttachableType: undefined,
    category: filesPermissioning.enabled ? 'other' : undefined,
    shareExternally: false
  }

  const formValidations: TFormValidation = {
    ...(!multiple ? { name: { label: 'Name', validations: [validatePresence] } } : {}),
    files: { label: 'Document', validations: [validatePresence] }
  }

  const { formProps, fieldsProps } = useButtonForm<TLeadFileUploadsPayload>({
    initialPayload,
    formValidations,
    mutation: uploadFile
  })

  const title = multiple ? 'Upload Files' : 'Upload File'

  return (
    <>
      <Button variant="outline" color="neutralLight" onClick={formProps.toggleShowForm}>
        {title}
      </Button>
      <ButtonForm buttonText={title} title={title} hideTriggerButton {...formProps}>
        <LeadFileUploadsButtonFields lead={lead} multiple={multiple} {...fieldsProps} />
      </ButtonForm>
    </>
  )
}
