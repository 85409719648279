import { ReactNode } from 'react'
import { Button, Icon, Spinner } from 'components'
import { cx } from 'utils'
import { DisplayReadOnlyValue } from '../DisplayReadOnlyValue'
import style from './DisplayValue.module.scss'

export type InlineProps = {
  format?: (val: string) => string | ReactNode
  isError?: boolean
  isLoading?: boolean
}

interface DisplayValueProps extends InlineProps {
  className?: string
  isReadOnly?: boolean
  setEditMode: (val: boolean) => void
  value: string
}

export const DisplayValue = ({
  value,
  setEditMode,
  format,
  isLoading,
  isError,
  isReadOnly,
  className
}: DisplayValueProps) => {
  const dangerStyles = {
    button: isError && style.buttonDanger
  }

  if (isReadOnly) {
    return <DisplayReadOnlyValue format={format} value={value} />
  }

  return (
    <Button
      className={cx(style.button, dangerStyles.button, className)}
      onClick={() => setEditMode(true)}
      disabled={isLoading}
    >
      {format ? format(value) : value}
      {!isLoading ? <Icon.Pencil className={style.pencil} /> : null}
      {isLoading ? <Spinner className={style.spinner} /> : null}
    </Button>
  )
}
