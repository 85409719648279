import { getPplSettingsUrl } from 'services/getUrl'
import { postRequest, snakeCaseKeys } from 'utils'

export const postPayPerLeadSettings = async (
  providerId: string | number,
  providerType: string,
  payload: any
) => {
  const url = getPplSettingsUrl({
    pathParams: {
      provider_type: providerType,
      provider_id: providerId
    },
    action: ''
  })

  await postRequest(url, snakeCaseKeys({ ...payload }))
}
