import { PayPerLeadBillingInfo } from 'models'
import { getPplBillingInfoUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchPayPerLeadBillingInfos = async (
  providerId: string | number,
  providerType?: string
) => {
  const url = getPplBillingInfoUrl({
    pathParams: {
      provider_type: providerType,
      provider_id: providerId
    }
  })

  const response = await getRequest(url)
  return await deserializeAsync(response.data, { transform: PayPerLeadBillingInfo.create })
}
