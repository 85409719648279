// @ts-strict
import { memo } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Icon } from 'components'
import { dragAndDropId } from './ReorderableList'
import styles from './ReorderableList.module.scss'

type TReorderableItem = {
  findItem: (id: string) => { index: number }
  id: string
  moveItem: (id: string, to: number) => void
  name: string
}

type TItem = {
  id: string
  originalIndex: number
}

export const ReorderableItem = memo(({ id, moveItem, name, findItem }: TReorderableItem) => {
  const originalIndex = findItem(id).index
  const [, drop] = useDrop(
    () => ({
      accept: dragAndDropId,
      hover({ id: draggedId }: TItem) {
        if (draggedId !== id) {
          const { index: overIndex } = findItem(id)
          moveItem(draggedId, overIndex)
        }
      }
    }),
    [findItem, moveItem]
  )

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: dragAndDropId,
      item: { id, originalIndex },
      collect: monitor => ({
        isDragging: monitor.isDragging()
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item
        const didDrop = monitor.didDrop()
        if (!didDrop) {
          moveItem(droppedId, originalIndex)
        }
      }
    }),
    [id, originalIndex, moveItem]
  )

  const opacity = isDragging ? 0 : 1

  return (
    <li className={styles.item} ref={node => drag(drop(node))} style={{ opacity }}>
      <Icon.Drag className={styles.icon} />
      {name}
    </li>
  )
})
