import { Page } from 'components'
import { TasksList } from 'content/Tasks'
import {
  ListProvider,
  QueryStringParamsProvider,
  TasksProvider,
  useCurrentUserContext,
  useParamsContext
} from 'contexts'
import { useDocumentTitle, useFeatureFlags } from 'hooks'
import {
  pageTaskListColumns,
  pageTaskListWithIrCoeDateColumns,
  useTaskSearchFilters
} from 'lookups'

export const TasksPage = () => {
  useDocumentTitle('Tasks')
  const { salesOpsAllLeadTasks } = useFeatureFlags(['sales-ops-all-lead-tasks'])
  const { currentUser } = useCurrentUserContext()
  const { params } = useParamsContext()
  const filters = useTaskSearchFilters()
  const { userType = [] } = params
  const bbysAttachables = userType.includes('bbys')

  return (
    <QueryStringParamsProvider
      initialParams={{ assigneeId: currentUser?.id, completionStatus: ['ready'] }}
    >
      <TasksProvider params={params} allLeadTasksEnabled={salesOpsAllLeadTasks.enabled}>
        <Page>
          <ListProvider
            filters={filters}
            columns={bbysAttachables ? pageTaskListWithIrCoeDateColumns : pageTaskListColumns}
          >
            <TasksList />
          </ListProvider>
        </Page>
      </TasksProvider>
    </QueryStringParamsProvider>
  )
}
