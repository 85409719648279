import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react'
import { Button, DROPDOWN_ELEMENT_ID, Flex, Icon, Text } from '@foundation/components'
import { Theme } from '@foundation/themes'
import * as SelectStyle from '../Select/styles'
import { selectTextSize } from '../Select/styles'
import { Option } from './Option'
import * as S from './styles'
import type { MultiSelectProps } from './types'
import { useMultiSelect } from './useMultiSelect'

export const MultiSelect = ({
  'data-testid': dataTestId = 'multiSelect',
  defaultOpen = false,
  errorMessage,
  isDisabled = false,
  isRequired = false,
  items,
  label,
  onChange,
  onClose,
  onOpen,
  placeholder = 'Select',
  values,
  size = 'xs',
  root,
  triggerClassName
}: MultiSelectProps) => {
  const {
    context,
    positionX,
    positionY,
    reference,
    floating,
    strategy,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
    setIsPointer,
    isOpen,
    listRef,
    activeIndex,
    options,
    displayType,
    generateButtonText,
    getSection,
    getSelectAllSectionButtonText,
    getEnabledSectionItemsValues
  } = useMultiSelect({ defaultOpen, items, onClose, onOpen, isDisabled: !!isDisabled, values })

  const hasValueSelected = !!values.length
  const placeholderColor: Extract<keyof Theme['colors'], 'primary10' | 'neutral8' | 'neutral4'> =
    isDisabled ? 'neutral4' : hasValueSelected ? 'primary10' : 'neutral8'

  const buttonText = hasValueSelected ? generateButtonText() : placeholder

  const handleSelect = (_index: number, selectedValue: string) => {
    if (values.includes(selectedValue)) {
      onChange(values.filter(val => val !== selectedValue))
    } else {
      onChange([...values, selectedValue])
    }
  }

  const handleSelectAllSection = (sectionValue: string) => {
    const isAllSelected = getEnabledSectionItemsValues(sectionValue).every(itemValue => {
      return values.includes(itemValue)
    })

    if (isAllSelected) {
      const uncheckedSectionValues = values.filter(
        selectedValue => !getEnabledSectionItemsValues(sectionValue).includes(selectedValue)
      )
      onChange(uncheckedSectionValues)
    } else {
      onChange([...new Set([...values, ...getEnabledSectionItemsValues(sectionValue)])])
    }
  }

  return (
    <div data-testid={dataTestId}>
      <SelectStyle.Label
        onClick={e => {
          e.preventDefault()
        }}
      >
        {label && (
          <SelectStyle.LabelText>
            <Text color={isDisabled ? 'neutral4' : 'primary10'} size={selectTextSize[size]}>
              {isRequired ? label : `${label} (optional)`}
            </Text>
          </SelectStyle.LabelText>
        )}
        <SelectStyle.Trigger
          ref={reference}
          isDisabled={isDisabled}
          hasError={!!errorMessage}
          isOpen={isOpen}
          $size={size}
          aria-label={buttonText}
          aria-autocomplete="none"
          className={triggerClassName}
          {...getReferenceProps()}
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Text color={placeholderColor} size={selectTextSize[size]}>
              {buttonText || placeholder}
            </Text>
            {isOpen ? <Icon path="UpArrow" /> : <Icon path="DownArrow" />}
          </Flex>
        </SelectStyle.Trigger>
      </SelectStyle.Label>
      {!!errorMessage && (
        <SelectStyle.ErrorMessage hasError={!!errorMessage}>
          <Text color="danger5" size={selectTextSize[size]}>
            {errorMessage}
          </Text>
        </SelectStyle.ErrorMessage>
      )}
      <FloatingPortal id="multiselect-portal" root={root}>
        {isOpen && (
          <FloatingFocusManager context={context} modal={false} initialFocus={-1}>
            <SelectStyle.Dropdown
              id={DROPDOWN_ELEMENT_ID}
              ref={floating}
              {...getFloatingProps({
                onMouseMove() {
                  setIsPointer(true)
                },
                onKeyDown() {
                  setIsPointer(false)
                }
              })}
              position={strategy}
              top={positionY}
              left={positionX}
            >
              {displayType === 'section'
                ? options.map((section, i) => {
                    if (!Array.isArray(section)) {
                      return null
                    }
                    const [sectionValue, sectionOptions] = section
                    return (
                      <SelectStyle.Section
                        data-testid={`${sectionValue}Section`}
                        key={`${sectionValue}-${i}`}
                      >
                        <S.SectionHeader>
                          <Text color="neutral8" size="xxs">
                            {getSection(sectionValue).label}
                          </Text>
                          <Button
                            size="xs"
                            variant="link"
                            onClick={() => handleSelectAllSection(sectionValue)}
                          >
                            {getSelectAllSectionButtonText(sectionValue)}
                          </Button>
                        </S.SectionHeader>
                        <>
                          {sectionOptions.map(item => (
                            <Option
                              key={`${item.value}-${item.label}-${item.index}`}
                              listRef={listRef}
                              activeIndex={activeIndex}
                              context={context}
                              item={{ ...item, index: item.index! }}
                              handleSelect={handleSelect}
                              getItemProps={getItemProps}
                              isSelected={values.includes(item.value)}
                              size={size}
                            />
                          ))}
                        </>
                      </SelectStyle.Section>
                    )
                  })
                : options.map((item, i) => {
                    if (Array.isArray(item)) {
                      return null
                    }
                    return (
                      <Option
                        key={`${item.value}-${item.label}-${item.index}`}
                        activeIndex={activeIndex}
                        context={context}
                        item={{ ...item, index: i }}
                        handleSelect={handleSelect}
                        listRef={listRef}
                        getItemProps={getItemProps}
                        isSelected={values.includes(item.value)}
                        size={size}
                      />
                    )
                  })}
            </SelectStyle.Dropdown>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </div>
  )
}
