import { Ellipsis, ExternalLink, FormField } from 'components'
import { environment } from 'environment'
import { Agent } from 'models'
import { formatDate, formatPhone, showIf, titleize } from 'utils'
import { AgentVacationSettingsButtonForm } from '../../AgentVacationSettingsButtonForm'
import styles from '../AgentSummary.module.scss'

type Props = { agent: Agent }

export const AgentContact = ({ agent }: Props) => {
  let agentWebsite = agent.website
  if (agentWebsite && agentWebsite.substr(0, 4) !== 'http') {
    agentWebsite = `http://${agentWebsite}`
  }

  return (
    <div className={styles.contact}>
      <FormField className={styles.formField} title="Vacation Status" inline>
        {agent.referralPreferences?.onVacationUntil
          ? `Returning ${formatDate(agent.referralPreferences.onVacationUntil)}`
          : 'Available'}
        <AgentVacationSettingsButtonForm agent={agent} />
      </FormField>
      {showIf(
        agent.hideProfile || agent.hideFromSearch,
        <FormField title="Hidden Agent" inline>
          {[agent.hideProfile ? 'Profile' : null, agent.hideFromSearch ? 'Search' : null]
            .filter(Boolean)
            .join(' & ')}
        </FormField>
      )}
      <FormField title="Email" inline>
        <Ellipsis>{agent.email1}</Ellipsis>
      </FormField>
      <FormField title="Alt Email" inline>
        <Ellipsis>{agent.email2}</Ellipsis>
      </FormField>
      {agent.phoneNumbers.map(pn => (
        <FormField title={`${titleize(pn.phoneType)} Phone`} inline key={pn.id}>
          {formatPhone(pn.number)}
        </FormField>
      ))}
      <FormField title="Links" inline>
        {agentWebsite && <ExternalLink href={agentWebsite}>Website</ExternalLink>}
        <ExternalLink href={`${environment.agentProfileURL}${agent.slug}`}>Profile</ExternalLink>
      </FormField>
      <FormField inline>
        <ExternalLink href={`${environment.agentAdminURL}${agent.id}`}>Admin</ExternalLink>
        <ExternalLink href={`${environment.agentPrefsURL}${agent.id}/preferences/`}>
          Prefs
        </ExternalLink>
        <ExternalLink href={environment.transactionUploaderURL}>Transaction Uploader</ExternalLink>
      </FormField>
    </div>
  )
}
