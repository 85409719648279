import { Lender } from 'models'
import { getHapiRequestParams, LendersSchema } from 'schema'
import { getLendersUrl } from 'services/getUrl'
import { deserializeAsync, getRequest, toSnakeCase } from 'utils'

const lendersShape: LendersSchema = {
  lenders: [
    'createdAt',
    'dispositionFlowType',
    'email',
    'id',
    'name',
    'slug',
    'states',
    'urls',
    'updatedAt',
    {
      providerLead: [
        'createdAt',
        'furthestStage',
        'id',
        'introSentAt',
        'rank',
        'reasonForFail',
        'stage'
      ]
    }
  ]
}

const buildFilters = (params: any, filters: any) => {
  Object.entries(filters).forEach(([key, value]) => {
    params[`filter[${toSnakeCase(key)}]`] = value
  })
  return params
}

export const fetchLenders = async (
  leadId?: string,
  filters?: { states?: string; status?: string }
) => {
  let searchParams = getHapiRequestParams(lendersShape)
  searchParams = buildFilters(searchParams, filters)

  const url = getLendersUrl({
    searchParams: { ...searchParams, lead_id: leadId }
  })
  const response = await getRequest(url)
  return await deserializeAsync(response.data, { transform: Lender.create })
}
